import React, {
    useState,
    useEffect,
    useRef,
    useMemo,
    useCallback,
    lazy,
    Suspense,
  } from "react";
  import ClearIcon from "@mui/icons-material/Clear";
  import {
    Popover
  } from "@mui/material";
  import MenuItem from "@mui/material/MenuItem";
  import { useParams } from "react-router-dom";
  import Modal from "react-modal";
  import "./CryptoPage.css"; // Assuming you have a CSS file for styling
  import TableCell, { tableCellClasses } from "@mui/material/TableCell";
  import TableContainer from "@mui/material/TableContainer";
  import TableHead from "@mui/material/TableHead";
  import TableRow from "@mui/material/TableRow";
  import Table from "@mui/material/Table";
  import { styled } from "@mui/material/styles";
  import TableBody from "@mui/material/TableBody";
  import Skeleton from "@mui/material/Skeleton";
  import Stack from "@mui/material/Stack";
  import Box from "@mui/material/Box";
  import json2mq from "json2mq";
  import useMediaQuery from "@mui/material/useMediaQuery";
  import EmailPopUp from "./EmailPopUp.js";
  import Typography from "@mui/material/Typography";
  import { useNavigate } from "react-router-dom";
  import BottomNavigationAction from "@mui/material/BottomNavigationAction";
  import { BottomNavigation, Button } from "@mui/material";
  import SidebarMenuList from "../includes/sidebarMenuList.jsx";
  
  import IconButton from "@mui/material/IconButton";
  import { Grid } from "@mui/material";
  import Drawer from "@mui/material/Drawer";
  import { useLocation, Link } from "react-router-dom";
  import { Helmet } from "react-helmet";
  import LoginPopUp from "./loginpopup";
  import { Tabs, Tab } from "@mui/material";
  import { getCryporCurrencyListHome } from "../apihelper/homepage";
  
  import Dialog from "@mui/material/Dialog";
  import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
  import { Card, TextField } from "@mui/material";
  
  import { DialogTitle, DialogContent, DialogActions } from "@mui/material"; // Import Material-UI components
  
  import { addToPortfolio, copyToPortfolio } from "../apihelper/portfolio";
  
  import CloseIcon from "@mui/icons-material/Close";
  
  import CreatableSelect from "react-select/creatable";
  import {
    getTotalunit,
    getFetchdropdown,
  } from "../apihelper/cryptocurrencylist";
  import Alert from "@mui/material/Alert";
  import Snackbar from "@mui/material/Snackbar";
  
  const drawerWidth = 292;
  const CryptoPagePredictions = (props) => {
    const { window1 } = props;
    const { crypto } = useParams();
    const [dropdownVisible, setDropdownVisible] = useState(null);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [sortType, setSortType] = useState("fd_asc");
    const [sortDropdownVisible, setSortDropdownVisible] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [loader, setLoader] = useState(false);
    const [slug, setSlug] = useState(false);
    const [symbolLogo, setSymbolLogo] = useState(false);
    const [currentPrice, setCurrentPrice] = useState(false);
    const [rowsToShow, setRowsToShow] = useState(4);
    const [selectedSort, setSelectedSort] = useState("");
    const sortRef = useRef();
    const navigate = useNavigate();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const location = useLocation();
    const loc = location.pathname;
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    const [isLoginModalOpen, setLoginModalOpen] = useState(false);
    const [sortOrder, setSortOrder] = useState({
      // Track ascending/descending for each column
      forecaster: "asc",
      target: "asc",
      accuracy: "asc",
      percent: "asc",
      targetDate: "asc",
    });
  
    // Render row based on the action color
    const renderAction = (action) => {
      switch (action) {
        case "Buy":
        case "Bullish":
        case "bullish":
        case "Partially Bullish":
        case "Outperformer":
        case "Strong Buy":
          return <span style={{ color: "green", fontWeight: "" }}> {capitalizeFirstLetter(action)}</span>;
  
        case "Sell":
        case "Bearish":
        case "Partially Bearish":
        case "Underperformer":
        case "Strong Sell":
          return <span style={{ color: "red", fontWeight: "" }}> {capitalizeFirstLetter(action)}</span>;
        case "Neutral":
        case "Moderate Performer":
          return <span style={{ color: "orange", fontWeight: "" }}>Neutral</span>;
        default:
          return null;
      }
    };
  
    function capitalizeFirstLetter(text) {
      return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    }
  
    const mediaQueryVar = useMediaQuery(
      json2mq({
        minWidth: 900,
      }),
    );
  
    function formatDate(dateString) {
      if (!dateString) return ""; // Handle cases where dateString is undefined or null
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, "0"); // Add leading zero to day
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Add leading zero to month (0-based index)
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    }
    
    const handleLoginClick = () => {
      // Mask the URL by adding /login at the end without navigating
      window.history.pushState({}, "", "/login");
      localStorage.setItem("isPopupManuallyOpened", "true");
      // Open the login modal
      setLoginModalOpen(true);
    };
    const handleModalClose = () => {
      // Close the modal and reset the URL to the welcome page
      setLoginModalOpen(false);
      navigate(location.pathname, { replace: true });
    };
  
    const mediaQueryVar1 = useMediaQuery(
      json2mq({
        minWidth: 400,
      }),
    );
  
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        border: 0,
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        border: 0,
      },
    }));
  
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      // hide last border
  
      "& td, & th": {
        border: 0,
      },
    }));
  
    const handleDrawerToggle = () => {
      setMobileOpen(!mobileOpen);
    };
  
    const drawer = (
      <div className="d-flex flex-column h-100" style={{ overflowY: "hidden" }}>
        <SidebarMenuList />
      </div>
    );
  
    const [predictionLoader, setPredictionLoader] = useState(false);
    
    const [paid_member, setpaid_member] = useState("no");
  
    const container =
      window1 !== undefined ? () => window1().document.body : undefined;
  
      useEffect(() => {
        const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
        const userId = userData?.user_id; // Use optional chaining to safely access user_id
        console.log("Hello userid:" + (userId || "Guest")); // Log "Guest" if userId is not available
      
        const fetchData = async () => {
          console.log(crypto + " hello");
          try {
            const response = await fetch(
              "https://crowdwisdom.live/wp-json/api/v1/showForecasterFutureDataInvesting",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  searchVal: crypto, // Adjust if necessary
                  sortType: sortType,
                  userId: userId || null, // Send null if userId is not available
                }),
              }
          );
  
          const result = await response.json();
          console.log(result);
  
          if (result.code === "200") {
            if (result.response.length === 0) {
              setPredictionLoader(true);
            }
            setLoader(true);
            setSlug(result.symbol);
            setCurrentPrice(result.current_price);
            setSymbolLogo(result.symbol_logo);
            setData(result.response);
            setpaid_member(result.paid_member);
  
  
            console.log({ slug });
          } else {
            setError(result.message);
          }
        } catch (err) {
          setError("An error occurred while fetching data.");
        } finally {
          setLoading(false);
        }
      };
  
      fetchData();
    }, [crypto, sortType]);
    const mediaQueryVar2 = useMediaQuery(
      json2mq({
        maxWidth: 420,
      }),
    );
  
    // Function to extract indentation level and style accordingly
    const getIndentationStyle = (htmlString) => {
      const div = document.createElement("div");
      div.innerHTML = htmlString;
  
      const items = div.querySelectorAll("li");
      items.forEach((item) => {
        const match = item.className.match(/ql-indent-(\d+)/);
        if (match) {
          const level = parseInt(match[1], 10);
          // Set padding based on indent level for content, reducing by 10px
          item.style.paddingLeft = `${20 * level - 20}px`; // Adjusted padding
          // Adjust margin for bullet alignment
          item.style.marginLeft = `${40 * level}px`; // Bullet alignment
        }
      });
  
      return div.innerHTML; // Return modified HTML
    };
  
  
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (sortRef.current && !sortRef.current.contains(event.target)) {
          setSortDropdownVisible(false);
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);
  
    const toggleDropdown = (index) => {
      setDropdownVisible(dropdownVisible === index ? null : index);
    };
  
    const handleSearchChange = (event) => {
      setSearchTerm(event.target.value);
    };
  
    const handleSortChange = (sortType) => {
      setSortType(sortType);
      setSortDropdownVisible(false);
      setSelectedSort(sortType);
    };
  
    const openModal = async (data) => {
      if (data.accuracy.toLowerCase() !== "pending") {
        try {
          const response = await fetch(
            "https://crowdwisdom.live/wp-json/api/v1/showForecasterPastData",
            {
              // Replace with your actual API endpoint
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                searchVal: crypto,
                forecasterName: data.forecaster_name,
              }),
            },
          );
  
          const result = await response.json();
          console.log(result);
  
          if (result.code === "200") {
            setModalData(result.response);
            setModalIsOpen(true);
          } else {
            setError(result.message);
          }
        } catch (err) {
          setError("An error occurred while fetching data.");
        }
      }
    };
  
    const closeModal = () => {
      setModalIsOpen(false);
      setModalData(null);
    };
  
    const filteredData = data.filter((forecaster) =>
      forecaster.forecaster_name.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  
    const handleLoadMore = () => {
      setRowsToShow(rowsToShow + 4);
    };
  
    const [isTableView, setIsTableView] = useState(true);
    const [selectedButton, setSelectedButton] = useState("CoinPrices");
  
    const handleButtonClick = (buttonName) => {
      // If the same button is clicked again, don't toggle the view
      if (selectedButton === buttonName) {
        return;
      }
  
      // Toggle the view based on the clicked button
      setSelectedButton(buttonName);
      setIsTableView(buttonName === "CoinPrices" ? false : true); // Show table view only if 'CoinPrices' is selected
    };
  
    let url = `/predict-now`;
    const parseDate = (dateStr) => {
      const [month, day, year] = dateStr.split(" ");
      const monthMap = {
        Jan: "01",
        Feb: "02",
        Mar: "03",
        Apr: "04",
        May: "05",
        Jun: "06",
        Jul: "07",
        Aug: "08",
        Sep: "09",
        Oct: "10",
        Nov: "11",
        Dec: "12",
      };
      const formattedDate = `${year}-${monthMap[month]}-${day.padStart(2, "0")}`;
      return new Date(formattedDate);
    };
  
    const parseForecastPrice = (price) => {
      return parseFloat(price.replace(/[^0-9.-]+/g, ""));
    };
  
    const parseAccuracy = (accuracy) => {
      if (typeof accuracy !== "string") return NaN; // Return NaN if input is not a string
  
      // Extract the number from the accuracy string
      const percentage = accuracy.split("%")[0].trim(); // Get the part before '%'
      const parsedValue = parseFloat(percentage.split(" ")[0]); // Convert to float
  
      return isNaN(parsedValue) ? 0 : parsedValue; // Return 0 for NaN for safety
    };
  
    const compareValues = (val1, val2, order = "asc") => {
      // If either value is NaN, treat it as less than any valid number
      if (isNaN(val1) && isNaN(val2)) return 0; // Both are NaN
      if (isNaN(val1)) return 1; // Treat NaN as the largest value
      if (isNaN(val2)) return -1; // Treat NaN as the largest value
  
      // Perform comparison based on order
      if (order === "asc") return val1 - val2;
      return val2 - val1;
    };
  
    const handleSort = (column) => {
      const newSortOrder = sortOrder[column] === "asc" ? "desc" : "asc"; // Toggle sort order
      setSortOrder({ ...sortOrder, [column]: newSortOrder });
  
      // Set the sort type based on the column
      switch (column) {
        case "forecaster":
          setSortType(newSortOrder === "asc" ? "fc_asc" : "fc_desc");
          break;
        case "target":
          setSortType(newSortOrder === "asc" ? "tg_asc" : "tg_desc");
          break;
        case "accuracy":
          setSortType(newSortOrder === "asc" ? "ac_asc" : "ac_desc");
          break;
        case "percent":
          setSortType(newSortOrder === "asc" ? "pr_asc" : "pr_desc");
          break;
        case "targetDate":
          setSortType(newSortOrder === "asc" ? "fd_asc" : "fd_desc");
          break;
        default:
          break;
      }
    };
  
    const sortedData = [...filteredData].sort((a, b) => {
      switch (sortType) {
        case "fc_asc":
          return a.forecaster_name.localeCompare(b.forecaster_name);
        case "fc_desc":
          return b.forecaster_name.localeCompare(a.forecaster_name);
        case "tg_asc":
          return (
            parseForecastPrice(a.forecast_price) -
            parseForecastPrice(b.forecast_price)
          );
        case "tg_desc":
          return (
            parseForecastPrice(b.forecast_price) -
            parseForecastPrice(a.forecast_price)
          );
        case "ac_asc": {
          const accuracyA = parseAccuracy(a.accuracy);
          const accuracyB = parseAccuracy(b.accuracy);
          return compareValues(accuracyA, accuracyB, "asc");
        }
        case "ac_desc": {
          const accuracyA = parseAccuracy(a.accuracy);
          const accuracyB = parseAccuracy(b.accuracy);
          return compareValues(accuracyA, accuracyB, "desc");
        }
        case "pr_asc":
          return parseFloat(a.target_percent) - parseFloat(b.target_percent);
        case "pr_desc":
          return parseFloat(b.target_percent) - parseFloat(a.target_percent);
        case "fd_asc":
          return parseDate(a.target_date) - parseDate(b.target_date);
        case "fd_desc":
          return parseDate(b.target_date) - parseDate(a.target_date);
        default:
          return 0;
      }
    });
  
    const [isPredictionDataLoaded, setIsPredictionDataLoaded] = useState(false);
    useEffect(() => {
      if (sortedData && sortedData.length > 0) {
        setIsPredictionDataLoaded(true); // Set to true when data is available
      }
    }, [sortedData]);
  
  
    const [data_replacements, setData_replacements] = useState([]);
    const [loading_replacements, setLoading_replacements] = useState(true);
    const [predictionLoader_replacements, setPredictionLoader_replacements] =
      useState(false);
    const [
      isPredictionDataLoaded_replacements,
      setIsPredictionDataLoaded_replacements,
    ] = useState(false);
    useEffect(() => {
      
      const fetchCryptoData = async () => {
        try {
          const response = await fetch(
            "https://crowdwisdom.live/wp-json/api/v1/showCryptoReplacements",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                crypto: crypto,
                sortType: sortType,
              }),
            },
          );
  
          const result = await response.json();
          console.log("replacements:", result);
          if (result.code === "200") {
            setData_replacements(result.response);
            if (result.response.length === 0) {
              setPredictionLoader_replacements(true);
            }
          } else {
            setError(result.message);
          }
        } catch (err) {
          setError("An error occurred while fetching data.");
        } finally {
          setLoading_replacements(false);
        }
      };
  
      fetchCryptoData();
    }, [sortType, crypto]);
  
    const [showModal, setShowModal] = useState(false); // Change the state name to showModal
  
    const filteredData1 = data_replacements;
  
    const sortedData1 = [...filteredData1].sort((a, b) => {
      switch (sortType) {
        case "ac_asc":
          return compareValues(a.accuracy, b.accuracy, "asc");
        case "ac_desc":
          return compareValues(a.accuracy, b.accuracy, "desc");
        default:
          return 0;
      }
    });
  
    const [ignoredRows, setIgnoredRows] = useState([]);
  
    const [open, setOpen] = useState(false);
    const fetchDropdownOptions = async () => {
      const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
  
      getFetchdropdown({
        user_id: userData.user_id,
  
        Token: userData.token,
      }).then((res) => {
        if (res.code === "200") {
          const newOptions = res.data.map((item) => ({
            value: item.id,
            label: item.name,
          }));
          setOptions(newOptions);
          setuser_role(res.user_role);
          //console.log(res.user_role);
        } else {
          console.log("Data fething error");
        }
      });
    };
  
    const handleIgnoreClick = (index) => {
      const updatedIgnoredRows = [...ignoredRows, index];
      setIgnoredRows(updatedIgnoredRows);
  
      // Check if all currently displayed rows are ignored
      const allIgnored = sortedData1
        .slice(0, rowsToShow)
        .every((_, idx) => updatedIgnoredRows.includes(idx));
  
      // If all rows are ignored, call handleLoadMore
      if (allIgnored) {
        handleLoadMore();
      }
    };
  
    const allRowsIgnored = sortedData1
      .slice(0, rowsToShow)
      .every((_, index) => ignoredRows.includes(index));
  
    useEffect(() => {
      if (sortedData1 && sortedData1.length > 0) {
        setIsPredictionDataLoaded_replacements(true); // Set to true when data is available
      }
    }, [sortedData1]);
  
    const [unit, setUnit] = useState("");
    const [selectedOption, setSelectedOption] = useState([]);
    const [price, setPrice] = useState("");
    const [totalUnits, setTotalUnits] = useState(0);
    const [selectedDate, handleDateChange] = useState("");
    const [Usablewallet, setUsablewallet] = useState("");
    const [portfolioTypeId, setPortfolioTypeId] = useState("");
    const handleChange = async (selectedOption, slug) => {
      if (selectedOption && selectedOption._isNew_) {
        setIsDialogOpen(true);
        console.log("Creating new option:", selectedOption.value);
        setSelectedOption(null);
        setdataslug(slug);
      } else if (!selectedOption) {
        // Handle the case when the selection is cleared
        setSelectedOption(null);
        setShowAlert(false);
        // Perform any other necessary actions
        return;
      } else {
        const selectedValue = selectedOption.value;
        if (selectedValue.includes("-")) {
          setErrorMessage("Hyphens are not allowed in the option."); // Set error message
          setShowAlert(true); // Show the alert
          return; // Abort further processing
        }
        setShowAlert(false);
        setSelectedOption(selectedOption);
        const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
        getTotalunit({
          user_id: userData.user_id,
          slug: slug, // Assuming value contains the slug
          portfolio_type_id: selectedOption.value, // Adjust as needed
          Token: userData.token,
        }).then((res) => {
          if (res.code === "200") {
            setTotalUnits(res.total_uint);
            setUsablewallet(res.wallet_amount);
          } else {
            console.log("Data fething error");
          }
          // console.log(slugdata)
        });
      }
    };
    useEffect(() => {
      if (open || showModal) {
        fetchDropdownOptions();
      }
    }, [open, showModal]);
    const handleCreate = async (inputValue) => {
      setTotalUnits(0);
      setUsablewallet("");
  
      if (options.find((option) => option.value === inputValue)) {
        return; // Exit early if the option already exists
      }
      const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
      //console.log(inputValue);
      const newOption = { value: inputValue, label: inputValue };
      const newOptions = [...options, newOption]; // Create a new array with the existing options plus the new one
      setOptions(newOptions); // Update the options state
      const createdOption = newOptions.find(
        (option) => option.value == inputValue,
      );
      // console.log(createdOption);
      if (createdOption) {
        handleChange(createdOption);
      }
    };
    const [showAlert, setShowAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const formatToNonZero = (number) => {
  
      console.log("FORMAT", number);
      number = parseFloat(number);
  
      if (number >= 1) {
        return number.toFixed(2);
      } else if (number >= 0.1) {
        return number.toFixed(1);
      } else if (number >= 0.01) {
        return number.toFixed(4);
      } else if (number >= 0.001) {
        return number.toFixed(5);
      } else if (number >= 0.0001) {
        return number.toFixed(6);
      } else if (number >= 0.00001) {
        return number.toFixed(7);
      } else if (number >= 0.000001) {
        return number.toFixed(8);
      } else if (number >= 0.0000001) {
        return number.toFixed(9);
      } else if (number >= 0.00000001) {
        return number.toFixed(10);
      } else if (number >= 0.000000001) {
        return number.toFixed(11);
      }
      return number.toFixed(1);
    };
    const [buySell, setbuySell] = React.useState("");
    const handleClick = (buySell) => {
      setSnackbaropen(true);
      setOpen(true);
      setSeverity("info");
      setIsFormSubmitted(false);
      // setSnackbarcolor("success");
      // setSnackbarmessage("Data saved successfully");
      setMsgforUpdatePortfolio("loading...");
      //console.log(price, selectedDate, buySell, unit);
      const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
      const portfolioId =
        selectedOption && !isNaN(parseInt(selectedOption.value))
          ? parseInt(selectedOption.value)
          : 0;
      const existingPortfolio = portfolioId > 0 ? "yes" : "no"; // Determine existing_portfolio based on portfolioId
  
      addToPortfolio({
        portfolio_type_id: portfolioId,
        portfolio_name: selectedOption ? selectedOption.label : "", // Ensure selectedOption is defined
  
        existing_portfolio: existingPortfolio,
        user_id: userData.user_id,
        slug: slugpopupdata.slugname,
        unit: unit,
        acquisition_price: price,
        date_time: selectedDate,
        type: buySell,
        Token: userData.token,
      }).then((res) => {
        if (res.code === "200") {
          // setOpen(false);
          setSeverity("info");
          //console.log(res.message);
          setMsgforUpdatePortfolio(res.message);
          setPortfolioTypeId(res.portfolio_type_id);
          setIsFormSubmitted(true);
          // navigate(
          //   `/my-portfolio/${res.portfolio_type_id}/${encodeURIComponent(
          //     selectedOption.label
          //   )}`
          // );
        } else {
          setSeverity("error");
          // setOpen(true)
          setMsgforUpdatePortfolio(res.message);
        }
      });
    };
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [newOptionValue, setNewOptionValue] = useState("");
    const handleDialogSubmit = () => {
      // Handle submitting the dialog (e.g., creating the new option)
      console.log("Creating new option:", newOptionValue);
  
      // Update options state with the new option
      const newOption = { value: newOptionValue, label: newOptionValue };
      setOptions([...options, newOption]);
      setSelectedOption(newOption);
      // Close the dialog
      setIsDialogOpen(false);
  
      // setSelectedOption(selectedOption);
      const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
      getTotalunit({
        user_id: userData.user_id,
        slug: dataslug, // Assuming value contains the slug
        portfolio_type_id: newOption.value, // Adjust as needed
        Token: userData.token,
      }).then((res) => {
        if (res.code === "200") {
          setTotalUnits(res.total_uint);
          setNewOptionValue("");
          setUsablewallet(res.wallet_amount);
        } else {
          console.log("Data fething error");
        }
        // console.log(slugdata)
      });
    };
    const [snackbaropen, setSnackbaropen] = React.useState(false);
    const closesnackbar = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
  
      setSnackbaropen(false);
    };
    const [severity, setSeverity] = useState("info");
    const [msgforUpdatePortfolio, setMsgforUpdatePortfolio] =
      useState("loading...");
    const handleClose = () => setOpen(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [dataslug, setdataslug] = useState("");
    const [options, setOptions] = useState([]);
    const handleChange2 = (e) => {
      const value = e.target.value;
      // Check if the input contains a hyphen
      if (value.includes("-")) {
        setShowAlert(true); // Show the alert if there's a hyphen
      } else {
        setShowAlert(false); // Hide the alert if there's no hyphen
        setNewOptionValue(value); // Update the state with the new value
      }
    };
    const [slugdata, setSlugdata] = useState([]);
    const [user_role, setuser_role] = useState("");
    const [slugpopupdata, setSlugpopupdata] = useState({
      slugname: "",
      slug: "",
      units: 0,
    });
  
    const handleAddToPortfolio = (idx) => {
      console.log("IDX value is : ", idx);
  
      getCryporCurrencyListHome({
        slug: idx,
      }).then((res) => {
        if (res.code === "200") {
          console.log("data got successfully", res.data);
  
          // Update slugdata
          setSlugdata(res.data);
          setuser_role(res.user_role);
  
          // Use res.data directly here
          const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
          if (userData) {
            console.log("User DAta is there");
            setSlugpopupdata({
              slugname: res.data[0].slug,
              slug: res.data[0].name,
              units: res.data[0].total_unit,
              pricetoday: res.data[0].price_today,
              logo: res.data[0].logo,
            });
            console.log("Slug Popup Data : ", res.data);
            setOpen(true);
          } else {
            window.history.pushState({}, "", "/login");
            setLoginModalOpen(true);
          }
        } else {
          console.log("Data fetching error");
        }
      });
    };
  
    const [selectedFilter, setSelectedFilter] = useState(null);
    const handleClickROIHL = () => {
      setSortType('roi_desc');
    };
    const handleClickROILH = () => {
      setSortType('roi_asc');
    };
    const handleClearAll = () => {
      setSortType('roi_asc');
    };
    const [anchorEl, setAnchorEl] = useState(null);
    const openfilter = Boolean(anchorEl);
  
    const handlePopoverOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleCloseeoption = () => {
      setAnchorEl(null);
    };
  
    return (
      <div className="crypto-page" style={{padding:mediaQueryVar? 0:"10px",margin: 0, backgroundColor: mediaQueryVar? "#F2F2F2" : "#fff" }}>
        <Helmet>
          <title>
            {crypto.charAt(0).toUpperCase() + crypto.slice(1)} Price Predictions
          </title>
          <meta
            name="description"
            content={`Accurate ${crypto.charAt(0).toUpperCase() + crypto.slice(1)} Influencer Price Predictions & In-depth ${crypto.charAt(0).toUpperCase() + crypto.slice(1)} Price Predictions.`}
          />
        </Helmet>
  
        <Modal
          isOpen={open}
          onRequestClose={handleClose}
          overlayClassName={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent black overlay
              backdropFilter: "blur(5px)", // Optional: Add a blur effect to the overlay
            },
          }}
          className={{
            content: {
              position: "relative",
              backgroundColor: "white", // Set background for inner popup
              padding: "20px", // Add padding for content
              borderRadius: "8px", // Rounded corners
              maxWidth: mediaQueryVar ? "100%" : "100%", // Set a max width for popup
              margin: "0", // Center the popup horizontally
              boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.2)", // Optional: Popup shadow
            },
          }}
        >
          <div className="CardOpener" style={{ overflow: "hidden" }}>
            {isFormSubmitted ? (
              <div
                style={{
                  position: "fixed", // Fix the popup to the top of the viewport
                  top: 0, // Align it to the top
                  left: 0,
                  width: "100%",
                  height: "100vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "rgba(0, 0, 0, 0.5)", // Optional: darken the background for emphasis
                  zIndex: 1000, // High z-index to overlay other elements
                }}
              >
                <Card className="card__container">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CheckCircleOutlineIcon sx={{ fontSize: 50, mr: 1 }} />
                    </div>
                    <Typography
                      severity="success"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      Portfolio added successfully!
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        marginTop: "10px",
                        width: "100%",
                      }}
                    >
                      <Button
                        onClick={() => {
                          setIsFormSubmitted(false);
                          setOpen(false);
                          setUnit("");
                          setSelectedOption("");
                          setPrice("");
                          setTotalUnits(0);
                          handleDateChange("");
                          setUsablewallet("");
                        }}
                        color="primary"
                        style={{
                          backgroundColor: "rgba(67, 97, 238, 0.15)",
                          borderRadius: "6px",
                          border: "none",
                          textTransform: "none",
                          borderStyle: "solid",
                          borderColor: "rgba(67, 97, 238, 0.15)",
                          borderWidth: "1px",
                          color: "#4361ee",
                          whiteSpace: "nowrap",
                          float: "inline-end",
                          width: mediaQueryVar === true ? "150px" : "100px",
                          height: mediaQueryVar === false ? "30px" : "",
                          fontSize: mediaQueryVar === false ? "10px" : "11px",
                        }}
                      >
                        Add More Cryptos
                      </Button>
                      <Button
                        onClick={() =>
                          navigate(
                            `/my-portfolio/${portfolioTypeId}/${encodeURIComponent(
                              selectedOption.label.replace(/\s+/g, "-"),
                            )}`,
                          )
                        }
                        variant="outlined"
                        style={{
                          backgroundColor: "rgba(67, 97, 238, 0.15)",
                          borderRadius: "6px",
                          border: "none",
                          textTransform: "none",
                          borderStyle: "solid",
                          borderColor: "rgba(67, 97, 238, 0.15)",
                          borderWidth: "1px",
                          color: "#4361ee",
                          whiteSpace: "nowrap",
                          float: "inline-end",
                          width: mediaQueryVar === true ? "100px" : "100px",
                          height: mediaQueryVar === false ? "30px" : "",
                          fontSize: mediaQueryVar === false ? "10px" : "11px",
                        }}
                        color="primary"
                      >
                        View Portfolio
                      </Button>
                    </div>
                  </div>
                </Card>
              </div>
            ) : (
              <Dialog
                PaperProps={{
                  sx: {
                    position: mediaQueryVar ? "static" : "fixed",
                    bottom: mediaQueryVar ? "" : 0,
                    left: mediaQueryVar ? "" : 0,
                    right: mediaQueryVar ? "" : 0,
                    marginLeft: mediaQueryVar ? "" : "0px",
                    marginRight: mediaQueryVar ? "" : "0px",
                    marginTop: "1%",
                    maxWidth: "100%",
                    minHeight: mediaQueryVar ? "60%" : "", // Adjust max height if needed
                    borderRadius: mediaQueryVar ? "24px" : "24px 24px 0 0",
                    marginBottom: mediaQueryVar ? "" : "0px",
                    padding: mediaQueryVar ? "10px" : "1px",
                    alignItems: "center",
                    overflow: "scroll",
                    "&::-webkit-scrollbar": {
                      display: "none",
                    },
                    "-ms-overflow-style": "none",
                    "scrollbar-width": "none", // Ensure no scrollbar
                  },
                }}
                open={open}
                onClose={handleClose}
              >
                <div
                  style={{
                    padding: "20px",
                    width: mediaQueryVar ? "400px" : "100%",
                    margin: "0",
                    marginTop: "-5px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "20px",
                      borderBottom: "1px #E3E3E3 solid",
                    }}
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: "20.767px",
                        paddingLeft: "100px",
                      }}
                      gutterBottom
                    >
                      Add To Portfolio
                    </Typography>
  
                    <div className="primaryButton">
                      <img
                        className="frameIcon"
                        alt=""
                        onClick={handleClose}
                        src={window.constants.asset_path + "/images/frame17.svg"}
                        style={{
                          background: "#F3F3F3",
                          width: "25px",
                          height: "25px",
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: "5px",
                      marginLeft: mediaQueryVar === false ? "10px" : "",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "13.845px",
                        fontWeight: "500",
                        marginBottom: "1px",
                        color: "black",
                        marginTop: "-8px",
                      }}
                    >
                      Select Portfolio
                    </Typography>
                    <div style={{ marginTop: "5px" }}>
                      <CreatableSelect
                        isClearable
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            borderRadius: "12px",
                            border: "1px solid #1877F2",
                            boxShadow: "none",
                            minHeight: "40px",
                            "&:hover": {
                              borderRadius: "12px",
                              border: "2px solid #1877F2",
                            },
                          }),
                          menu: (provided) => ({
                            ...provided,
                            borderRadius: "8px",
                          }),
                          placeholder: (provided) => ({
                            ...provided,
                            color: "",
                          }),
                          dropdownIndicator: (provided) => ({
                            ...provided,
                            color: "#1877F2",
                            border: "1px solid #1877F2",
                            padding: "5px",
                            margin: "8px",
                            borderRadius: "3.786px",
                            "&:hover": {
                              color: "#1877F2",
                            },
                          }),
                          indicatorSeparator: (provided) => ({
                            display: "none",
                          }),
                        }}
                        placeholder=""
                        onChange={(options) =>
                          handleChange(options, slugpopupdata.slugname)
                        }
                        onCreateOption={handleCreate}
                        options={[
                          {
                            value: "",
                            label: "Create new portfolio",
                            _isNew_: true,
                          }, // Dynamic "create new option" item
                          ...options, // Existing options
                        ]}
                        value={selectedOption}
                      />
                    </div>
                    {showAlert && (
                      <Alert severity="error" sx={{ marginBottom: "10px" }}>
                        {errorMessage}
                      </Alert>
                    )}
                  </div>
                  <Typography
                    style={{
                      fontSize: "13.845px",
                      fontWeight: "500",
                      color: "#000",
                      marginTop: "10px",
                      marginBottom: "5px",
                    }}
                  >
                    Usable Wallet Value: {Usablewallet}
                  </Typography>
                  <div
                    style={{
                      padding: mediaQueryVar ? "8px" : "5px",
                      background: "#F3F3F3",
                      borderRadius: "8px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBottom: "5px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flex: 0,
                        minWidth: 0,
                      }}
                    >
                      <img
                        style={{
                          marginRight: "10px",
                          border: "2px solid #000",
                          borderRadius: "19px",
                          height: "27.69px",
                          width: "27.69px",
                        }}
                        alt=""
                        src={slugpopupdata.logo}
                      />
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Typography
                          sx={{
                            fontWeight: "500",
                            marginBottom: "0px",
                            fontSize: "14px",
                            color: "#7F7F80",
                          }}
                        >
                          {slugpopupdata.slugname}
                        </Typography>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginLeft: "20%",
                        flex: 1,
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "500",
                          marginBottom: "4px",
                          fontSize: "13px",
                          color: "#7F7F80",
                        }}
                      >
                        {" "}
                        Price
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "12.114px",
                          fontWeight: "500",
                          color: "black",
                          margin: "0",
                          marginLeft: "5%",
                        }}
                      >
                        {slugpopupdata.pricetoday}
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginLeft: "20px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "500",
                          marginBottom: "4px",
                          fontSize: "13px",
                          color: "#7F7F80",
                        }}
                      >
                        {" "}
                        Units
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "12.114px",
                          fontWeight: "500",
                          color: "black",
                          margin: "0",
                        }}
                      >
                        {totalUnits}
                      </Typography>
                    </div>
                  </div>
                  <div className="card__container__row3">
                    <div style={{ marginLeft: "-5px" }}>
                      <p style={{ fontSize: "14px", fontWeight: "500" }}>
                        Enter number of Units
                      </p>
                      <input
                        style={{
                          backgroundColor: "white",
                          color: "black",
                          padding: "20px",
                          border: "1px solid #1877F2",
                          borderRadius: "10.384px",
                          width: mediaQueryVar ? "350px" : "100%",
                          height: "45px",
                          margin: 0,
                        }}
                        min="1"
                        max="10"
                        type="number"
                        placeholder=""
                        value={unit}
                        onChange={(e) => setUnit(e.target.value)}
                      ></input>
                    </div>
                    <div>
                      $
                      {formatToNonZero(
    unit *
      parseFloat(
        (slugpopupdata.pricetoday || "")
          .replace(/^\$/, "") // Remove the dollar sign
          .replace(/,/g, ""), // Remove commas
      ),
  )}
  
                    </div>
                    {user_role === "superuser" && (
                      <>
                        <div className="card__container__row3__r2">
                          <div style={{ marginLeft: "-5px" }}>
                            <p style={{ fontSize: "14px", fontWeight: "500" }}>
                              Buy/Sell Price($)
                            </p>
                            <input
                              style={{
                                backgroundColor: "white",
                                color: "black",
                                padding: "20px",
                                border: "1px solid #1877F2",
                                borderRadius: "10.384px",
                                width: "345px",
                                height: "45px",
                              }}
                              className="card__container__row3__r2__input"
                              min="1"
                              max="10"
                              type="number"
                              placeholder="Enter Price"
                              value={price}
                              onChange={(e) => setPrice(e.target.value)}
                            ></input>
                          </div>
                        </div>
                        <div style={{ marginBottom: "10px", marginLeft: "-5px" }}>
                          <p style={{ fontSize: "14px", fontWeight: "500" }}>
                            Date
                          </p>
                          <TextField
                            type="date"
                            value={selectedDate}
                            onChange={(e) => handleDateChange(e.target.value)}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                borderRadius: "10.384px",
                                border: "1px solid #1877F2",
                                boxShadow: "none",
                                width: "190%",
                                height: "45px",
                              },
                            }}
                          />
                        </div>
                      </>
                    )}
                    <div
                      className="card__container__row3__r4"
                      style={{ marginTop: "10px" }}
                    >
                      <Button
                        className={
                          buySell === "buy"
                            ? "buttonToggleButActive"
                            : "buttonToggleBuy"
                        }
                        value="Buy"
                        style={{
                          lineHeight: "20px",
                          width: mediaQueryVar ? "159px" : "130px",
                          borderRadius: "27px",
                          border: "1px solid #1877F2",
                          padding: mediaQueryVar ? "15px 85px" : "13px 75px",
                          marginLeft: "-6px",
                          backgroundColor: buySell === "buy" ? "" : "#1877F2",
                          color: buySell === "buy" ? "" : "white",
                          textTransform: "none",
                          fontSize: "18px",
                          fontWeight: "700",
                        }}
                        onClick={() => handleClick("buy")}
                      >
                        Buy
                      </Button>
                      <Button
                        className={
                          buySell === "sell"
                            ? "buttonToggleSellActive"
                            : "buttonToggleSell"
                        }
                        value="sell"
                        style={{
                          lineHeight: "20px",
                          width: mediaQueryVar ? "159px" : "130px",
                          borderRadius: "27px",
                          border: "1px solid #1877F2",
                          marginLeft: "10px",
                          padding: mediaQueryVar ? "15px 85px" : "13px 75px",
                          backgroundColor: buySell === "sell" ? "" : "#1877F2",
                          color: buySell === "sell" ? "" : "white",
                          textTransform: "none",
                          fontSize: "18px",
                          fontWeight: "700",
                        }}
                        onClick={() => handleClick("sell")}
                      >
                        Sell
                      </Button>
                    </div>
                  </div>
                </div>
              </Dialog>
            )}
          </div>
        </Modal>
  
        <Dialog
          open={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>
            Create New Portfolio
            <IconButton
              aria-label="close"
              onClick={() => {
                setIsDialogOpen(false);
                setNewOptionValue("");
              }}
              sx={{ position: "absolute", right: 13, top: 8 }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <div>
              <TextField
                autoFocus
                label="Enter Name"
                value={newOptionValue}
                onChange={handleChange2}
                fullWidth
                margin="dense"
              />
              {showAlert && (
                <Alert severity="error" sx={{ marginTop: "8px" }}>
                  Hyphens are not allowed in the name.
                </Alert>
              )}
            </div>
          </DialogContent>
          <DialogActions sx={{ marginRight: "14px" }}>
            <Button
              onClick={() => {
                setIsDialogOpen(false);
                setNewOptionValue("");
              }}
              sx={{ fontWeight: "bold" }}
              variant="outlined"
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={handleDialogSubmit}
              variant="contained"
              color="primary"
              sx={{ fontWeight: "bold" }}
              disabled={!newOptionValue.trim()}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
  
        <Snackbar
          open={snackbaropen}
          autoHideDuration={5000}
          onClose={closesnackbar}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert severity={severity}>{msgforUpdatePortfolio}</Alert>
        </Snackbar>
  
        <Grid item xs={12} align="left">
        {mediaQueryVar ? null : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between", // Changed from space-between to flex-end
              marginTop: "0px",
              marginLeft: "5px",
              fontSize:"20px"
            }}
          >
            <EmailPopUp />
            
    <Typography style={{fontSize:"20px", fontWeight:"700"}}> Price Predictions </Typography>
    <IconButton
      color="inherit"
      aria-label="open drawer"
      edge="end"
      onClick={handleDrawerToggle}
      sx={{ display: { sm: "none" } }}
    >
      <img
        src="/images/menubar.svg"
        alt="Menu Icon"
        style={{
          width: "24px",
          height: "24px",
          marginRight: "10px",
          marginTop: "10px",
        }}
      />
    </IconButton>
           
          
          </div> 
        )}
        </Grid>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: "400",
              backgroundColor: "white",
              color: "#727376",
            },
          }}
        >
          {drawer}
        </Drawer>
        {mediaQueryVar ? null : (
          <Box
            sx={{
              backgroundColor: "#1877F2", // Background color for the container
              padding: "3.105px", // Padding inside the container
              borderRadius: "38.042px", // Border radius for the container
              display: "flex", // Ensure that the box is flexible
              alignItems: "center", // Center the content vertically
              display: "inline-block",
              minWidth: isPredictionDataLoaded
                ? mediaQueryVar2
                  ? "340px"
                  : "352px"
                : mediaQueryVar2
                  ? "300px"
                  : "307px",
  
              height: "37px",
              border: "none",
              marginLeft: "0px",
              marginTop: "2%",
              marginBottom: "14%",
            }}
          >
            <Tabs
              value={selectedButton}
              onChange={(event, newValue) => handleButtonClick(newValue)}
              aria-label="basic tabs example"
              sx={{
                flexGrow: 1, // Make the tabs take up remaining space
                "& .MuiTabs-indicator": {
                  display: "none",
                },
                display: "flex", // Ensure that the tabs are displayed in a row
                overflow: "hidden", // Prevent the tabs from spilling out of the border radius
              }}
            >
              <Tab
                label={
                  isPredictionDataLoaded
                    ? `${slug} Price Predictions`
                    : "Price Predictions"
                }
                value="CoinPrices"
                sx={{
                  whiteSpace: "nowrap",
                  borderRadius: "20.962px",
                  minHeight: "31px",
                  height: "31px",
                  marginLeft: "1.5px",
                  marginTop: "0.2px",
                  border: "1px solid black",
                  fontWeight: selectedButton === "CoinPrices" ? "700" : "500",
                  marginRight: "0px",
                  fontSize: mediaQueryVar2 ? "13px" : "15px",
                  paddingRight: "-8px",
                  textTransform: "none",
                  backgroundColor:
                    selectedButton === "CoinPrices" ? "#FFFFFF" : "transparent", // White background for selected tab
                  color:
                    selectedButton === "CoinPrices"
                      ? "#000000 !important"
                      : "#FFFFFF", // Black text for selected tab
                  border: "none", // Remove border for tabs
                }}
              />
              <Tab
                label="Predict and Win"
                value="PortfolioGallery"
                component={Link}
                to={url}
                sx={{
                  whiteSpace: "nowrap",
                  borderRadius: "20.962px",
                  minHeight: "31.5px",
                  height: "31.5px",
                  fontWeight:
                    selectedButton === "PortfolioGallery" ? "700" : "500",
                  marginLeft: "0.5px",
                  paddingLeft: "-15px",
                  marginTop: "0.5px",
                  border: "1px solid black",
                  marginBottom: "-5px",
                  fontSize: mediaQueryVar2 ? "13px" : "15px",
                  marginRight: "-10px",
                  backgroundColor:
                    selectedButton === "PortfolioGallery"
                      ? "#FFFFFF"
                      : "transparent", // White background for selected tab
                  color:
                    selectedButton === "PortfolioGallery"
                      ? "#000000 !important"
                      : "#FFFFFF", // Black text for selected tab
                  textTransform: "none",
                  border: "none",
                }}
              />
            </Tabs>
           
          </Box>
          
        )}
  
        {mediaQueryVar ? (
          <div className="layout">
            <div className>
              <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
              >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                  container={container}
                  variant="temporary"
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                  ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                  }}
                  sx={{
                    display: { xs: "block", sm: "none" },
                    "& .MuiDrawer-paper": {
                      boxSizing: "border-box",
                      width: drawerWidth,
                      backgroundColor: "white",
                      color: "#727376",
                    },
                  }}
                >
                  {drawer}
                </Drawer>
                <Drawer
                  variant="permanent"
                  sx={{
                    display: { xs: "none", sm: "block" },
                    "& .MuiDrawer-paper": {
                      boxSizing: "border-box",
                      width: drawerWidth,
                      backgroundColor: "white",
                      color: "#727376",
                    },
                  }}
                  open
                >
                  {drawer}
                </Drawer>
              </Box>
            </div>
  
            <div
              className="content"
              style={{
                margin: "0%",
                padding: "10px",
              }}
            >
              <div style={{
                display:"flex",
                justifyContent:"flex-start",
                alignItems:"center"
              }}>
              <Box
                sx={{
                  backgroundColor: "#1877F2", // Background color for the container
                  paddingTop: { xs: "10px", sm: "4px" }, // Padding inside the container
                  paddingLeft: { xs: "0px", sm: "0px" },
                  paddingRight: { xs: "0px", sm: "0px" },
                  paddingBottom: { xs: "0px", sm: "4px" },
                  borderRadius: "50px", // Border radius for the container
                  display: "flex", // Make sure the box is flexible
                  justifyContent: "flex-start", // Center the content
                  alignItems: "center", // Center the content
                  display: "inline-block",
                  minWidth: isPredictionDataLoaded ? "420px" : "350px",
                  height: { xs: "44px", sm: "48px" },
                  border: "none",
                  marginLeft: "0.3%",
                  marginTop: "10px",
                  marginBottom:"3%"
                }}
              >
                <Tabs
                  value={selectedButton}
                  onChange={(event, newValue) => handleButtonClick(newValue)}
                  aria-label="basic tabs example"
                  sx={{
                    alignSelf: mediaQueryVar === true ? "flex-start" : "",
                    marginLeft: mediaQueryVar === true ? "2px" : "2px",
                    borderRadius: "50px", // Adjust border radius for more rounded corners
                    border: "none",
                    "& .MuiTabs-indicator": {
                      display: "none",
                    },
                    display: "flex", // Make sure the tabs are displayed in a row
                    overflow: "hidden", // Prevent the tabs from spilling out of the border radius
                  }}
                >
                  <Tab
                    label={
                      isPredictionDataLoaded
                        ? `${slug} Price Predictions`
                        : "Price Predictions"
                    }
                    value="CoinPrices"
                    sx={{
                      whiteSpace: "nowrap",
                      bottom: "0px",
                      marginTop: "0px",
                      marginBottom: "0px",
                      marginLeft: "3px",
                      marginRight: "3px",
                      paddingBottom: "0px",
                      paddingTop: "0px",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      minHeight: "20px",
                      lineHeight: { xs: "36px", sm: "36px" },
                      borderRadius:
                        selectedButton === "CoinPrices" ? "50px" : "50px", // Rounded corners for selected tab
                      textTransform: "none",
                      fontSize: "17px",
                      fontStyle: "normal",
                      fontWeight: selectedButton === "CoinPrices" ? "700" : "500",
  
                      backgroundColor:
                        selectedButton === "CoinPrices"
                          ? "#FFFFFF"
                          : "transparent", // White background for selected tab
                      color:
                        selectedButton === "CoinPrices"
                          ? "#000000 !important"
                          : "#FFFFFF", // Black text for selected tab
                      border: "none", // Remove border for tabs
                      background:
                        selectedButton === "CoinPrices"
                          ? "#FFFFFF"
                          : "transparent", // Blue background for unselected tab
                    }}
                  />
                  <Tab
                    label="Predict and Win"
                    value="PortfolioGallery"
                    component={Link}
                    to={url}
                    sx={{
                      whiteSpace: "nowrap",
                      bottom: "0px",
                      marginTop: "1.1px",
                      marginBottom: "0px",
                      marginLeft: "3px",
                      marginRight: "3px",
                      paddingBottom: "2px",
                      paddingTop: "0px",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      minHeight: "20px",
                      lineHeight: { xs: "28px", sm: "36px" },
                      borderRadius:
                        selectedButton === "PortfolioGallery" ? "20px" : "20px", // Rounded corners for selected tab
                      textTransform: "none",
                      fontSize: { xs: "13px", sm: "17px" },
                      fontStyle: "normal",
                      fontWeight:
                        selectedButton === "PortfolioGallery" ? "700" : "500",
  
                      backgroundColor:
                        selectedButton === "PortfolioGallery"
                          ? "#FFFFFF"
                          : "transparent", // White background for selected tab
                      color:
                        selectedButton === "PortfolioGallery"
                          ? "#000000 !important"
                          : "#FFFFFF", // Black text for selected tab
                      border: "none", // Remove border for tabs
                      background:
                        selectedButton === "PortfolioGallery"
                          ? "#FFFFFF"
                          : "transparent", // Blue background for unselected tab
                    }}
                  />
                </Tabs>
                
              </Box>
              </div> 
  
  
  
            
  
              {isPredictionDataLoaded === false && predictionLoader ? (
                <div
                  style={{
                    border: "1px solid #B6B6B8",
                    margin: "0px 2%",
                    marginTop: "-35px",
                    textAlign: "center",
                    color: "black",
                    fontSize: "18px",
                    backgroundColor: "white",
                    padding: "2%",
                    borderRadius: "8px",
                    marginBottom: "30%",
                  }}
                >
                  No Predictions available
                </div>
              ) : (
                <div style={{
                  borderRadius: "10px",
                  backgroundColor: "#fff",
                  padding:"15px 15px",
                  fontFamily:"Satoshi, sans-serif",
                  margin: "15px 5px",
                  
                }}>
                  
                <div style={{
                  display:"flex",
                  justifyContent:"space-between",
                  alignItems:"center"
                }}>  
                
                <span style={{fontSize:"20px", fontWeight:"700"}}>Price Forecasts</span>
                <div style={{ width:"25%",display: "flex", alignItems: "center", border: "1px solid #1877F2", borderRadius: "46px", padding: "7px 10px", background: "#F7F7F7", marginBottom:"12px" }}>
                <img
      src={window.constants.asset_path + "/images/search.svg"}
      alt="Search Icon"
      style={{
        marginLeft: "5px",
        marginRight:"10px",
        cursor: "pointer",
        
      }}
    />
    <input
      type="text"
      placeholder="Search Forecaster"
      style={{
        flex: 1, // Makes the input field take up the available space
        
        background: "transparent",
        color: "black",
        border: "none", // Remove default input border
        outline: "none", // Remove focus outline
      }}
      onChange={handleSearchChange}
    />
    
  </div>
  
                  </div>
  
                  {/* Prediction Table */}
                  <div>
                    <table className="predictions-table" style={{border:"2px solid #DADEDF" , width:"100%", marginLeft:0}} >
                      <thead>
                        <tr>
                          <th
                            onClick={() => handleSort("forecaster")}
                            style={{
                              cursor: "pointer",
                              textAlign: "left",
                              width: "15%",
                              fontWeight: "500",
                              color: "#727376",
                              fontSize: "16px",
                            }}
                          >
                            Forecaster{" "}
                            {sortOrder.forecaster === "asc" ? "↑" : "↓"}
                          </th>
                          <th
                            onClick={() => handleSort("target")}
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              fontWeight: "500",
                              color: "#727376",
                              fontSize: "16px",
                            }}
                          >
                            Target {sortOrder.target === "asc" ? "↑" : "↓"}
                          </th>
                          <th
                            onClick={() => handleSort("accuracy")}
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              fontWeight: "500",
                              color: "#727376",
                              fontSize: "16px",
                            }}
                          >
                            Past Accuracy{" "}
                            {sortOrder.accuracy === "asc" ? "↑" : "↓"}
                          </th>
                          <th
                            onClick={() => handleSort("percent")}
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              fontWeight: "500",
                              color: "#727376",
                              fontSize: "16px",
                            }}
                          >
                            %Target Achieved{" "}
                            {sortOrder.percent === "asc" ? "↑" : "↓"}
                          </th>
                          <th
                            onClick={() => handleSort("targetDate")}
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              fontWeight: "500",
                              color: "#727376",
                              fontSize: "16px",
                            }}
                          >
                            Forecast For{" "}
                            {sortOrder.targetDate === "asc" ? "↑" : "↓"}
                          </th>
                          <th></th>
                        </tr>
                      </thead>
            <tbody>
    {isPredictionDataLoaded === false ? (
      // Render skeletons if data is loading
      [...Array(10)].map((_, index) => (
        <tr key={index}>
          {[...Array(6)].map((_, cellIndex) => (
            <td key={cellIndex}>
              <Skeleton variant="rounded" height={40} />
            </td>
          ))}
        </tr>
      ))
    ) : sortedData.length > 0 ? (
      // Render data if available
      sortedData.slice(0, rowsToShow).map((data, index) => (
        <tr key={index}>
          <td
            style={{
              width: "23%",
              background:
                "var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
              borderBottom: "6px solid #fff",
              padding: "5px 7px",
              color: "#000",
              fontSize: "16px",
              fontWeight: "500",
              marginLeft: "5px",
            }}
          >
            {data.forecaster_name}
          </td>
          <td
            style={{
              textAlign: "center",
              color: "black",
              fontWeight: "500",
              fontSize: "14px",
              paddingLeft: "10px",
            }}
          >
            {data.forecast_price}
          </td>
          <td
            style={{ textAlign: "center" }}
            onClick={() => openModal(data)}
          >
            <span
              className={
                data.accuracy.toLowerCase() !== "pending"
                  ? "clickable"
                  : "pending"
              }
            >
              {data.accuracy}
            </span>
          </td>
          <td
            style={{
              textAlign: "center",
              color: "black",
              fontWeight: "500",
              fontSize: "14px",
              paddingLeft: "10px",
            }}
          >
            {data.target_percent}
          </td>
          <td
            style={{
              textAlign: "center",
              color: "black",
              fontWeight: "500",
              fontSize: "14px",
              paddingLeft: "10px",
            }}
          >
            {data.target_date}
          </td>
          <td>
            <div className="dropdown">
              <button
                style={{ width: "20px" }}
                onClick={() => toggleDropdown(index)}
                className="dropdown-button"
              >
                <img
                  src={
                    window.constants.asset_path +
                    "/images/more.png"
                  }
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "5px",
                  }}
                  alt="More"
                />
              </button>
              {dropdownVisible === index && (
                <div className="dropdown-content">
                  <a
                    href={data.source_link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={
                        window.constants.asset_path +
                        "/images/external-link-symbol.png"
                      }
                      style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "5px",
                      }}
                      alt="Copy"
                    />
                    Source link
                  </a>
                </div>
              )}
            </div>
          </td>
        </tr>
      ))
    ) : (
      // Show "No Predictions available" if no data matches the search
      <tr>
        <td
          colSpan={6}
          style={{
            textAlign: "center",
            padding: "20px",
            fontSize: "18px",
            fontWeight: "500",
            color: "#727376",
          }}
        >
          No Predictions available
        </td>
      </tr>
    )}
  </tbody>
                      
                    </table>
                    {rowsToShow < sortedData.length && (
                            
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    marginTop:"10px"
                                  }}
                                >
                                  <button
                                    onClick={handleLoadMore}
                                    className="load-more"
                                    style={{
                                      maxWidth: "360px",
                                      width: "80vw",
                                      backgroundColor: "rgba(67, 97, 238, 0.15)",
                                      borderRadius: "6px",
                                      border: "none",
                                      textTransform: "none",
                                      borderStyle: "solid",
                                      borderColor: "rgba(67, 97, 238, 0.15)",
                                      borderWidth: "1px",
                                      color: "#4361ee",
                                      height: "40px",
                                      cursor: "pointer",
                                      paddingTop:"-5px"
                                    }}
                                  >
                                    Explore More
                                  </button>
                                </div>
                              
                          )}
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : isPredictionDataLoaded === false && predictionLoader ? (
          <div
            style={{
              border: "1px solid #B6B6B8",
              margin: "0px 2%",
              textAlign: "center",
              color: "black",
              fontSize: "18px",
              backgroundColor: "white",
              padding: "2%",
              borderRadius: "8px",
              marginBottom: "50%",
            }}
          >
            No Predictions available
          </div>
        ) : (
          <div>
           
                <div style={{
                  display:"flex",
                  justifyContent:"space-between",
                  alignItems:"center",
                  marginTop:"-20px"
                }}>  
                
                <span style={{fontSize:"18px", fontWeight:"700"}}>Price Forecasts</span>
                <div style={{ width:"49%",display: "flex", alignItems: "center", border: "1px solid #1877F2", borderRadius: "46px", padding: "7px 10px", background: "#F7F7F7", marginBottom:"0px",marginLeft:"15px" }}>
                <img
      src={window.constants.asset_path + "/images/search.svg"}
      alt="Search Icon"
      style={{
        marginLeft: "1%",
        marginRight:"2%",
        cursor: "pointer",
        
      }}
    />
    <input
      type="text"
      placeholder="Search Forecaster"
      style={{
        flex: 1, // Makes the input field take up the available space
        
        background: "transparent",
        color: "black",
        border: "none", // Remove default input border
        outline: "none", // Remove focus outline
      }}
      onChange={handleSearchChange}
    />
    
  </div>
  
  <div>
          <Box
              sx={{
                borderRadius: "10px",
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "0px",
                color:"#1877F2",
                marginLeft:"-10px"
              }}
            >
              <Button
                onClick={handlePopoverOpen}
              >
                
                <div style={{
                padding:"6px 2px"
                }}>
                <img width="23px"
                src={window.constants.asset_path + '/images/filter2.svg'}/></div>
              </Button>
  
              <Popover
                open={openfilter}
                anchorEl={anchorEl}
                onClose={handleCloseeoption}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                style={{
                 
                }}
              >
               <MenuItem
                  selected={selectedFilter === "fc_asc"}
                  onClick={() => handleSort("forecaster")}
                >
                 Forecaster {sortOrder.forecaster === "asc" ? "↑" : "↓"}
                </MenuItem>
                
                <MenuItem
                  selected={selectedFilter === "tg_asc"}
                  onClick={() => handleSort("target")}
                >
                  Target {sortOrder.target === "asc" ? "↑" : "↓"}
                </MenuItem>
                <MenuItem
                  selected={selectedFilter === "ac_asc"}
                  onClick={() => handleSort("accuracy")}
                >
                  Accuracy {sortOrder.accuracy === "asc" ? "↑" : "↓"}
                </MenuItem>
                
  
                <MenuItem
                  selected={selectedFilter === "pr_asc"}
                  onClick={() => handleSort("percent")}
                >
                  Achieved  {sortOrder.percent === "asc" ? "↑" : "↓"}
                </MenuItem>
                <MenuItem
                  selected={selectedFilter === "fd_asc"}
                  onClick={() => handleSort("targetDate")}
                >
                  Target Date {sortOrder.targetDate === "asc" ? "↑" : "↓"}
                </MenuItem>
      
      
                
              
  
                <Box sx={{ display: "flex", padding: "8px" }}>
                  <Button
                    size="small"
                    sx={{ textTransform: "capitalize", marginLeft: "10px" }}
                    onClick={handleClearAll}
                    startIcon={<ClearIcon />}
                  >
                    Clear All
                  </Button>
                </Box>
              </Popover>
  
              
            </Box>
  
    </div>
  
                  </div>
  
            {/* Prediction Table */}
            <table className="predictions-table-mobile" style={{ width: "100%", borderCollapse: "separate", borderSpacing: "0 10px", marginBottom:"80px" }}>
    {isPredictionDataLoaded === false ? (
      <tbody>
        {[...Array(3)].map((_, index) => (
          <tr key={index} style={{ backgroundColor: "#fff", borderRadius: "10px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}>
            {[...Array(5)].map((_, cellIndex) => (
              <td key={cellIndex} style={{ padding: "15px" }}>
                <Skeleton variant="rounded" height={40} />
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    ) : sortedData.length === 0 ? ( // Check if no data is found
      <tbody>
        <tr>
          <td colSpan={5} style={{ textAlign: "center", padding: "20px", fontSize: "16px", fontWeight: "500", color: "#76787A" }}>
            No Prediction Available
          </td>
        </tr>
      </tbody>
    ) : (
      <tbody>
        {sortedData.slice(0, rowsToShow).map((data, index) => (
          <tr
            key={index}
            style={{
              display: "flex",
              flexDirection: "column",
              borderRadius: "10px",
              marginBottom: "20px",
              padding: "0px",
            }}
          >
            <td
              style={{
                background:
                  "linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%)",
                borderRadius: "6px 6px 0px 0px",
                padding: "10px",
                fontWeight: "500",
                fontSize: "16px",
                color: "#000",
              }}
            >
              {data.forecaster_name}
            </td>
  
            <td style={{ padding: "4px 10px", fontSize: "14px" }}>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                <div>
                  <span style={{ color: "#76787A", fontSize: "13px", marginRight: "7px" }}>Target :</span> {data.forecast_price}
                </div>
                <div>
                  <span style={{ color: "#76787A", fontSize: "13px", marginRight: "7px",  }}>Target Achieved :</span> {data.target_percent}
                </div>
              </div>
            </td>
  
            <td style={{ padding: "4px 10px", fontSize: "14px" }}>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                <div>
                  <span style={{ color: "#76787A", fontSize: "13px", marginRight: "7px" }}>Accuracy :</span> {data.accuracy}
                </div>
                <div>
                  <span style={{ color: "#76787A", fontSize: "13px", marginRight: "7px" }}>Target Date :</span> {formatDate(data.target_date)}
                </div>
              </div>
            </td>
          </tr>
        ))}
        {rowsToShow < sortedData.length && (
          <tr style={{ background: "#fff" }}>
            <td colSpan={5} style={{ textAlign: "center" }}>
              <button
                onClick={handleLoadMore}
                style={{
                  backgroundColor: "rgba(67, 97, 238, 0.15)",
                  borderRadius: "8px",
                  padding: "10px 20px",
                  color: "#4361ee",
                  fontSize: "16px",
                  border: "none",
                  cursor: "pointer",
                  marginBottom: "50px",
                }}
              >
                Explore More
              </button>
            </td>
          </tr>
        )}
      </tbody>
    )}
  </table>
  
  
          </div>
        )} 
        {mediaQueryVar === false && (
          <div
            style={{
              position: "fixed",
              bottom: "0",
              backgroundColor: "white",
              width: "105%",
              zIndex: "100",
              marginBottom: "0px",
              marginLeft: "-25px",
            }}
          >
            <BottomNavigation showLabels>
              <BottomNavigationAction
                label="Gallery"
                onClick={() => navigate("/portfolio-gallery")}
                icon={
                  <img
                    src={
                      loc === "/portfolio-gallery"
                        ? "/images/dashboardS.svg"
                        : "/images/dashboardU.svg"
                    }
                    width={"20px"}
                    height={"20px"}
                  />
                }
              />
              <BottomNavigationAction
                label="Crypto Screener"
                onClick={() => navigate("/crypto-screener")}
                icon={
                  <img
                    src={
                      loc === "/crypto-screener"
                        ? "/images/portfolioS.svg"
                        : "/images/portfolioU.svg"
                    }
                    width={"20px"}
                    height={"20px"}
                  />
                }
              />
             <BottomNavigationAction
              label="$1/Month Subscription"
              onClick={() => {
                if (userData) {
                  navigate("/subscriptions/screener360");
                } else {
                  const originalUrl = '/subscriptions/screener360';
                  sessionStorage.setItem("originalUrl", originalUrl);
                  console.log("original url: " + originalUrl);
                  handleLoginClick();
                }
              }}
              icon={
                <img
                  src={
                    
                       loc === "/subscriptions/screener360"
                        ? "/images/dollarS.svg"
                        : "/images/dollarU.svg"
                     
                  }
                  width={24}
                  height={24}
                  alt="Icon"
                  style={{ marginBottom: "-3px" }}
                />
              }
            />
            </BottomNavigation>
          </div>
        )}
        {isLoginModalOpen && (
          <LoginPopUp
            isLoginModalOpen={isLoginModalOpen}
            setLoginModalOpen={setLoginModalOpen} // Pass the state setter down
            onClose={handleModalClose}
          />
        )}
        <Modal
          style={{
            overlay: {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 1000,
              marginLeft: mediaQueryVar === true ? "20%" : "",
            },
            content: {
              align: "center",
              width: "80%",
              marginBottom: "20%",
              height: "auto",
              borderRadius: "8px",
              position: "center",
            },
          }}
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Accuracy Details"
        >
          <img
            className="frameIcon"
            alt=""
            onClick={closeModal}
            src="/images/frame17.svg"
            style={{
              background: "#F3F3F3",
              width: "25px",
              height: "25px",
              marginTop: "1.5%",
              marginBottom: "1%",
            }}
          />
          <h4>Past Accuracy</h4>
          {modalData ? (
            <table className="modal-table">
              <thead>
                <tr>
                  <th>Published On</th>
                  <th>Forecast Date</th>
                  <th style={{ textAlign: "center" }}>Forecast Price</th>
                  <th style={{ textAlign: "center" }}>Accuracy</th>
                </tr>
              </thead>
              <tbody>
                {modalData.map((detail, index) => (
                  <tr key={index}>
                    <td>{detail.publish_date}</td>
                    <td>{detail.target_date} 11:59 PM</td>
                    <td style={{ textAlign: "center" }}>
                      {detail.forecast_price}
                    </td>
                    <td style={{ textAlign: "center" }}>{detail.accuracy}%</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No data available</p>
          )}
        </Modal>
      </div>
    );
  };
  
  export default CryptoPagePredictions;
  