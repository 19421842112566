import MenuList from "./menulist";
import MenuListHome from "./menuListHome";

import React, { useEffect, useState } from "react";

export default function SidebarMenuList() {
  const [userLocalData, setUserLocalData] = useState(undefined);
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    setUserLocalData(userData);
  },[]);

  return (
    <div>
      {userLocalData && <MenuList />}
      {!userLocalData && <MenuListHome />}
    </div>
  );
}
