import React, { useState, useEffect, useRef , useMemo} from 'react';
import { useParams } from 'react-router-dom';
import Modal from 'react-modal';
import '../CryptoPage.css'; // Assuming you have a CSS file for styling
import { fontSize } from '@mui/system';
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import { completeGoogleLogin } from "../../apihelper/login";

import Dialog from "@mui/material/Dialog";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Button, Grid, TextField, Card, Checkbox } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import Tooltip from '@mui/material/Tooltip';
import Table from "@mui/material/Table";
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import { Helmet } from 'react-helmet';
import { updateWatchlist } from "../../apihelper/profile";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import json2mq from "json2mq";
import { DialogTitle, DialogContent, DialogActions } from "@mui/material"; // Import Material-UI components

import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from '@mui/material/Typography';
import { useNavigate } from "react-router-dom";
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { ButtonGroup,BottomNavigation,Paper,Chip} from "@mui/material";
import SidebarMenuList from "../../includes/sidebarMenuList";
import { addToPortfolio, copyToPortfolio } from "../../apihelper/portfolio";
import {
  getCryporCurrencyListHome,
  getPortfolioForGallery,
  getSymbolbyPortfolioid,
} from "../../apihelper/homepage";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { useStepContext } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import CreatableSelect from "react-select/creatable";
import {
  getTotalunit,
  getFetchdropdown,
} from "../../apihelper/cryptocurrencylist";
import Alert from "@mui/material/Alert";
import Drawer from '@mui/material/Drawer';
import { ContactSupportOutlined, Recommend, Visibility } from '@mui/icons-material';
import {Link, useLocation } from "react-router-dom";
import { Tabs, Tab } from '@mui/material';
import Snackbar from "@mui/material/Snackbar";
import LoginPopUp from '../loginpopup';
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';
import { keyframes } from '@mui/system';
import Footer from '../footer';

// Define the keyframes for the left-right motion animation
const leftRightMotion = `
  @keyframes leftRightMotion {
    0% {
      transform: translateX(-30px); /* Start 30px left of center */
    }
    50% {
      transform: translateX(30px);  /* Move 30px right of center */
    }
    100% {
      transform: translateX(-30px); /* Return to 30px left of center */
    }
  }
`;


const drawerWidth = 292;

const useStyles = styled((theme) => ({
  tableContainer: {
    maxWidth: 900,
    margin: "0 auto",
  },
  table: {
    display: "none", // Hide the table on all screens
    [theme.breakpoints.down("sm")]: {
      display: "table", // Show the table on small screens only (mobile view)
    },
  },
}));

const Recommendations = (props) => {
  const { window1 } = props;
  const { crypto } = useParams();
  const [dropdownVisible, setDropdownVisible] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortType, setSortType] = useState('fd_asc');
  const [sortDropdownVisible, setSortDropdownVisible] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [loader, setLoader] = useState(false);
  const [Usablewallet, setUsablewallet] = useState("");
  const [totalUnits, setTotalUnits] = useState(0);
  const [options, setOptions] = useState([]);
  const [predictionLoader, setPredictionLoader] = useState(false);
  const [slug, setSlug] = useState(false);
  const [sevenDays, setSevenDays] = useState(false);
  const [thirtyDays, setThirtyDays] = useState(false);
  const [yearDays, setYearDays] = useState(false);
  const [newOptionValue, setNewOptionValue] = useState("");

  const [rowsToShow, setRowsToShow] = useState(7);
  const [selectedSort, setSelectedSort] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dataslug, setdataslug] = useState("");
  const sortRef = useRef();
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [portfolioData, setPortfolioData] = useState([]);
  const location = useLocation(); 
  const loc = location.pathname;
  const [watchlistdata, setWatchlistdata] = useState([]);
  const [unit, setUnit] = useState("");
  const [buySell, setbuySell] = React.useState("");
  const [price, setPrice] = useState("");
  const [selectedDate, handleDateChange] = useState("");
  const [portfolioTypeId, setPortfolioTypeId] = useState("");
  const [showModal, setShowModal] = useState(false); // Change the state name to showModal

  const formatToNonZero = (number) => {
    number = parseFloat(number);
    
    if (number >= 1) {
      return number.toFixed(2);
    } else if (number >= 0.1) {
      return number.toFixed(1);
    } else if (number >= 0.01) {
      return number.toFixed(4);
    } else if (number >= 0.001) {
      return number.toFixed(5);
    } else if (number >= 0.0001) {
      return number.toFixed(6);
    } else if (number >= 0.00001) {
      return number.toFixed(7);
    } else if (number >= 0.000001) {
      return number.toFixed(8);
    } else if (number >= 0.0000001) {
      return number.toFixed(9);
    } else if (number >= 0.00000001) {
      return number.toFixed(10);
    } else if (number >= 0.000000001) {
      return number.toFixed(11);
    }
    return number.toFixed(1);
  };
  const [user_role, setuser_role] = useState("");
  const handleChange2 = (e) => {
    const value = e.target.value;
    // Check if the input contains a hyphen
    if (value.includes('-')) {
      setShowAlert(true); // Show the alert if there's a hyphen
    } else {
      setShowAlert(false); // Hide the alert if there's no hyphen
      setNewOptionValue(value); // Update the state with the new value
    }
  };

 

  const handleDialogSubmit = () => {
    // Handle submitting the dialog (e.g., creating the new option)
    console.log("Creating new option:", newOptionValue);

    // Update options state with the new option
    const newOption = { value: newOptionValue, label: newOptionValue };
    setOptions([...options, newOption]);
    setSelectedOption(newOption);
    // Close the dialog
    setIsDialogOpen(false);

    // setSelectedOption(selectedOption);
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    getTotalunit({
      user_id: userData.user_id,
      slug: dataslug, // Assuming value contains the slug
      portfolio_type_id: newOption.value, // Adjust as needed
      Token: userData.token,
    }).then((res) => {
      if (res.code === "200") {
        setTotalUnits(res.total_uint);
        setNewOptionValue("");
        setUsablewallet(res.wallet_amount);
      } else {
        console.log("Data fething error");
      }
      // console.log(slugdata)
    });
  };
  const handleGoBack = () => {
    navigate(-1); // Go back to the previous page
  };

  const mediaQueryVar = useMediaQuery(
    json2mq({
      minWidth: 900,
    })
  );

  const mediaQueryVar1 = useMediaQuery(
    json2mq({
      minWidth: 400,
    })
  );
  const mediaQueryVar2 = useMediaQuery(
    json2mq({
      maxWidth: 420,
    })
  );

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      border: 0,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      border: 0,
    },
  }));

  const isSmallScreen = useMediaQuery(
    json2mq({
      maxWidth: 380,
    })
  );


  const handleChange = async (selectedOption, slug) => {
    if (selectedOption && selectedOption._isNew_) {
      setIsDialogOpen(true);
      console.log("Creating new option:", selectedOption.value);
      setSelectedOption(null);
      setdataslug(slug);
    } else if (!selectedOption) {
      // Handle the case when the selection is cleared
      setSelectedOption(null);
      setShowAlert(false);
      // Perform any other necessary actions
      return;
    } else {

      const selectedValue = selectedOption.value;
      if (selectedValue.includes('-')) {
        setErrorMessage('Hyphens are not allowed in the option.'); // Set error message
        setShowAlert(true); // Show the alert
        return; // Abort further processing
      }
      setShowAlert(false);
      setSelectedOption(selectedOption);
      const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
      getTotalunit({
        user_id: userData.user_id,
        slug: slug, // Assuming value contains the slug
        portfolio_type_id: selectedOption.value, // Adjust as needed
        Token: userData.token,
      }).then((res) => {
        if (res.code === "200") {
          setTotalUnits(res.total_uint);
          setUsablewallet(res.wallet_amount);
        } else {
          console.log("Data fething error");
        }
        // console.log(slugdata)
      });
    }
  };

  const handleCreate = async (inputValue) => {
    setTotalUnits(0);
    setUsablewallet("");

    if (options.find((option) => option.value === inputValue)) {
      return; // Exit early if the option already exists
    }
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    //console.log(inputValue);
    const newOption = { value: inputValue, label: inputValue };
    const newOptions = [...options, newOption]; // Create a new array with the existing options plus the new one
    setOptions(newOptions); // Update the options state
    const createdOption = newOptions.find(
      (option) => option.value == inputValue
    );
   // console.log(createdOption);
    if (createdOption) {
      handleChange(createdOption);
    }
  };



  const [isLoginModalOpen, setLoginModalOpen] = useState(false);

  const handleLoginClick = () => {
    // Mask the URL by adding /login at the end without navigating
    window.history.pushState({}, "", "/login");
    localStorage.setItem("isPopupManuallyOpened", "true");

    // Open the login modal
    setLoginModalOpen(true);
  };

  const handleModalClose = () => {
    // Close the modal and reset the URL to the current page
    setLoginModalOpen(false);
    navigate(location.pathname, { replace: true });
};

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
   
    "& td, & th": {
      border: 0,

    },
  }));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
};

const drawer = (
    <div className='d-flex flex-column h-100' style={{overflowY:'hidden'}}>
<SidebarMenuList/>
    </div>
);
const [slugpopupdata, setSlugpopupdata] = useState({
  slugname: "",
  slug: "",
  units: 0,
});
const [slugdata, setSlugdata] = useState([]);
const [open, setOpen] = useState(false);
const handleClose = () => setOpen(false);
const [snackbaropen, setSnackbaropen] = React.useState(false);
const [selectedOption, setSelectedOption] = useState([]);
const [msgforUpdatePortfolio, setMsgforUpdatePortfolio] =
useState("loading...");
const closesnackbar = (event, reason) => {
  if (reason === "clickaway") {
    return;
  }

  setSnackbaropen(false);
};
const [severity, setSeverity] = useState("info");
const [slugName, setSlugName] = useState("");





const [isFormSubmitted, setIsFormSubmitted] = useState(false);
const handleClick = (buySell) => {
  setSnackbaropen(true);
  setOpen(true);
  setSeverity("info");
  setIsFormSubmitted(false);
  // setSnackbarcolor("success");
  // setSnackbarmessage("Data saved successfully");
  setMsgforUpdatePortfolio("loading...");
  //console.log(price, selectedDate, buySell, unit);
  const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
  const portfolioId =
    selectedOption && !isNaN(parseInt(selectedOption.value))
      ? parseInt(selectedOption.value)
      : 0;
  const existingPortfolio = portfolioId > 0 ? "yes" : "no"; // Determine existing_portfolio based on portfolioId

  addToPortfolio({
    portfolio_type_id: portfolioId,
    portfolio_name: selectedOption ? selectedOption.label : "", // Ensure selectedOption is defined

    existing_portfolio: existingPortfolio,
    user_id: userData.user_id,
    slug: slugpopupdata.slugname,
    unit: unit,
    acquisition_price: price,
    date_time: selectedDate,
    type: buySell,
    Token: userData.token,
  }).then((res) => {
    if (res.code === "200") {
      // setOpen(false);
      setSeverity("info");
      //console.log(res.message);
      setMsgforUpdatePortfolio(res.message);
      setPortfolioTypeId(res.portfolio_type_id);
      setIsFormSubmitted(true);
      // navigate(
      //   `/my-portfolio/${res.portfolio_type_id}/${encodeURIComponent(
      //     selectedOption.label
      //   )}`
      // );
    } else {
      setSeverity("error");
      // setOpen(true)
      setMsgforUpdatePortfolio(res.message);
    }
  });
};



const container = window1 !== undefined ? () => window1().document.body : undefined;

const {portfolioId, portfolioName } = useParams();
const mPortfolioName = portfolioName.replace(/-/g, ' ');
const [isTableView, setIsTableView] = useState(true);
const [selectedButton, setSelectedButton] = useState("CoinPrices");
const [starredPortfolios, setStarredPortfolios] = useState({});
const [performanceType, setPerformanceType] = useState(""); // To store 'Underperformer' or 'Moderate Performer'

useEffect(() => {
  const fetchCryptoData = async () => {
    try {
      const response = await fetch('https://crowdwisdom.live/wp-json/api/v1/showCryptoRecommendationsFutureData1', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          portfolioName: mPortfolioName,
          sortType: sortType,
        }),
      });
      
      const result = await response.json();
      console.log("recommendations:",result.response);
      if (result.code === '200') {
        setData(result.response);
        if (result.response.length > 0) {
          // Extract performance type from the first item's crypto_symbol
          const firstSymbol = result.response[0].crypto_symbol; // e.g., '1-HTC-Underperformer-BTC'
          const performanceCategory = firstSymbol.split("-")[2]; // Get 'Underperformer' or 'Moderate Performer'
          setPerformanceType(performanceCategory); // Store in state
        }
        if (result.response=[]) {
          setPredictionLoader(true);
        }
        
      } else {
        setError(result.message);
      }
    } catch (err) {
      setError('An error occurred while fetching data.');
    } finally {
      setLoading(false);
    }
  };

  const fetchPortfolioData = async () => {
    try {
      const response = await fetch('https://crowdwisdom.live/wp-json/api/v1/showPortfolioRecommendationsFutureData', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          portfolioName: mPortfolioName,
          sortType: sortType,
        }),
      });


      const result = await response.json();
      console.log(result);
  
      if (result.code === '200') {
        console.log(result.data.recommended_portfolios.length);
        setPortfolioData(result.data.recommended_portfolios || []);
        // Check if portfolioData is set before setting loader
        if (result.data.recommended_portfolios.length===0) {
          setLoader(true);
        }
      } else {
        setError(result.message);
      }
    } catch (err) {
      setError('An error occurred while fetching data.');
    } finally {
      setLoading(false);
    }
  };

  fetchCryptoData();
  fetchPortfolioData();
}, [mPortfolioName, sortType, crypto]);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sortRef.current && !sortRef.current.contains(event.target)) {
        setSortDropdownVisible(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = (index) => {
    setDropdownVisible(dropdownVisible === index ? null : index);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSortChange = (sortType) => {
    setSortType(sortType);
    setSortDropdownVisible(false);
    setSelectedSort(sortType);
  };

  const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));


  const openModal = async (data) => {
    console.log(data.crypto_symbol);
    console.log(data.forecaster_name);
    if (data.accuracy.toLowerCase() !== 'pending') {
      try {
        const response = await fetch('https://crowdwisdom.live/wp-json/api/v1/showCryptoRecommendationsPastData', { // Replace with your actual API endpoint
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            crypto_name: data.crypto_symbol,
            forecasterName: data.forecaster_name,
          }),
        });
  
        const result = await response.json();
        console.log(result);
  
        if (result.code === '200') {
          setModalData(result.response);
          setModalIsOpen(true);

        } else {
          setError(result.message);
        }
      } catch (err) {
        setError('An error occurred while fetching data.');
      }
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setModalData(null);
  };

  const filteredData = data;


  const handleLoadMore = () => {
    setRowsToShow(rowsToShow + 7);
  };


const compareValues = (val1, val2, order = 'asc') => {
  if (val1 === 'Pending') return 1;  // Ensure 'Pending' is always last
  if (val2 === 'Pending') return -1; // Ensure 'Pending' is always last
  if (order === 'asc') return val1 > val2 ? 1 : val1 < val2 ? -1 : 0;
  return val1 < val2 ? 1 : val1 > val2 ? -1 : 0;
};

const sortedData = [...filteredData].sort((a, b) => {
  switch (sortType) {
    case 'ac_asc':
      return compareValues(a.accuracy, b.accuracy, 'asc');
    case 'ac_desc':
      return compareValues(a.accuracy, b.accuracy, 'desc');
    default:
      return 0;
  }
});

const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    if (portfolioData && portfolioData.length > 0) {
      setIsDataLoaded(true); // Set to true when data is available
    }
  }, [portfolioData]);

  const handleButtonClick = (buttonName) => {
    // If the same button is clicked again, don't toggle the view
    if (selectedButton === buttonName) {
      return;
    }

    // Toggle the view based on the clicked button
    setSelectedButton(buttonName);
    setIsTableView(buttonName === "CoinPrices" ? false : true); // Show table view only if 'CoinPrices' is selected
  };

  const [isPredictionDataLoaded, setIsPredictionDataLoaded] = useState(false);
  

  const currentUrl = window.location.pathname; // Get the current URL path

let url;

if (currentUrl.includes('/my-portfolio/')) {
  url = `/my-portfolio/${portfolioId}/${portfolioName}/detailed-portfolio-analysis`;
} else if (currentUrl.includes('/portfolio/')) {
  url = `/portfolio/${portfolioName}/detailed-portfolio-analysis`;
}

  


// Check if all rows are ignored
const handleStarClick = (portfolio_id) => {
  console.log("portf_id", portfolio_id);

  const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
  const user_id = userData.user_id;
  const token = userData.token;

  // Toggle the starred state for this portfolio
  setStarredPortfolios(prevState => {
      const isStarred = prevState[portfolio_id];

      // If currently starred, remove from local storage and watchlist
      const updatedState = {
          ...prevState,
          [portfolio_id]: !isStarred // Toggle star state for this portfolio ID
      };

      // Save the updated starred portfolios to local storage
      localStorage.setItem("starred_portfolios", JSON.stringify(updatedState));

      // Update the watchlist in the backend
      updateWatchlist({
          user_id,
          portfolio_id,
          token,
          action: isStarred ? 'remove' : 'add'
      }).then(res => {
          if (res.code === "200") {
              console.log("Watchlist updated successfully:", res.data);
              setWatchlistdata(res.data);
          } else {
              console.log("Data Fetching Error");
          }
      });

      return updatedState;
  });
};

// Load saved starred portfolios from local storage when the component mounts
useEffect(() => {
  const savedStarredPortfolios = JSON.parse(localStorage.getItem("starred_portfolios"));
  if (savedStarredPortfolios) {
    setStarredPortfolios(savedStarredPortfolios);
  }
}, []);
useEffect(() => {
  if (open || showModal) {
    fetchDropdownOptions();
  }
}, [open, showModal]);
const fetchDropdownOptions = async () => {
  const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));

  getFetchdropdown({
    user_id: userData.user_id,

    Token: userData.token,
  }).then((res) => {
    if (res.code === "200") {
      const newOptions = res.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setOptions(newOptions);
      setuser_role(res.user_role);
      //console.log(res.user_role);
    } else {
      console.log("Data fething error");
    }
  });
};














  const { filter } = props; // Destructure filter from props
  const { onButtonClick } = props;
  const [selectedmenu, setselectedmenu] = useState("");
  const [value, setValue] = React.useState(0);

  const [alignment, setAlignment] = React.useState("left");
  const [showloginpage, setShowloginpage] = useState(false);
  const [index, setIndex] = useState(0);
  const [addMoreLoader, setAddMoreLoader] = useState(false);

  const [loaderbutton, setLoaderButton] = useState(true);
  const [loadingForLogin, setLoadingForLogin] = useState(false);
  const [lastUpdatedTime, setLastUpdatedTiem] = useState("--");
  const [loaderForSymbolLink, setLoaderForSymbolLink] = useState(false);
  
  const [categories, setCategories] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [hashtagData, setHashtagData] = useState('');
  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
 
  const mediaQueryVar5 = useMediaQuery(
    json2mq({
      minWidth: 1900,
    })
  );
 
  useEffect(() => {
    setShowloginpage(true);
    const search = window.location.search;
    const params = new URLSearchParams(search);
   // console.log(params);
    if (params) {
      const googleLoginCode = params.get("code");
      if (googleLoginCode) {
        setLoadingForLogin(true);
        completeGoogleLogin(googleLoginCode).then((data) => {
          //console.log(data);
          if (data.code == "200") {
            //console.log(data.message);
            localStorage.setItem(
              "cw_portfolio_user",
              JSON.stringify(data.data)
            );
            const portfolio_userId = localStorage.getItem("portfolio_userId");
            if (portfolio_userId) {
              //  navigate(portfolio/${portfolio_userId}, { replace: true });
              window.location.replace(
                `${process.env.REACT_APP_HOME_URL}portfolio/${portfolio_userId}`
              );
              localStorage.removeItem("portfolio_userId");
            } else {
              if (data.data.portfolio_form_redirect === "yes") {
                //navigate("/profile", { replace: true });
                window.location.replace(
                  `${process.env.REACT_APP_HOME_URL}profile`
                );
              } else {
                window.location.replace(
                 `${process.env.REACT_APP_HOME_URL}cryptocurrencylist`
                );
                //navigate("/cryptocurrencylist", { replace: true });
              }
            }
          } else {
            console.log("GOOGLE LOGIN FAILED");
            // TODO
            // pop up the error
          }
        });
      } else {
        setShowloginpage(false);
      }
    }
  });

  
  const getBackgroundColor = (item) => {
    const textColor = item.outlook_for_today[0].textcolor;
    if (textColor === "#ff6900") {
      return "rgba(236, 82, 29, 0.16)";
    } else if (textColor === "#00d084") {
      return "rgba(0, 185, 174, 0.16)";
    } else if (textColor === "#cf2e2e") {
      return "rgba(206, 45, 79, 0.16)";
    }
    // Add more conditions for other text colors if needed
    // If the color doesn't match any specific condition, return a default color
    return "defaultBackgroundColor";
  };

  useEffect(() => {
    if (open || showModal) {
      fetchDropdownOptions();
    }
  }, [open, showModal]);
  console.log(hashtagData);
  console.log("index:"+ index);
  

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const open1 = Boolean(anchorEl);
  const [loading1, setLoading1] = useState(true);

  const fetchDistinctCategories = async () => {
    try {
      const response = await fetch('https://crowdwisdom.live/wp-json/api/v1/getDistinctCategories', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const result = await response.json();
      console.log(result);
      if (result.code === '200') {
        setCategories(result.data); // Set categories in state
      } else {
        setError(result.message);
      }
    } catch (err) {
      setError('An error occurred while fetching categories.');
    } finally {
      setLoading1(false);
    }
  };

  // Separate useEffect for fetching categories
  useEffect(() => {
    fetchDistinctCategories();
  }, []); // This runs only once after the component mounts

 

  
 // Step 1: Create a shallow copy of slugdata
const slugdata1 = slugdata.slice();

// Step 2: Sort slugdata1 based on total_roi_value
const sortedSlugdata = slugdata1;

const filterData = [
  { categories: "Bollinger Bands", subOptions: ["Bullish", "Bearish"] },
  { categories: "Fibonacci Retracement", subOptions: ["Bullish", "Bearish"] },
 
];

const [filterAnchor, setFilterAnchor] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState([]); // Store selected filters as objects

  const handleFilterOpen = (event) => setFilterAnchor(event.currentTarget);
  const handleFilterClose = () => setFilterAnchor(null);

  const isFilterOpen = Boolean(filterAnchor);

  const handleCheckboxChange = (categories, subOption) => {
    const filterKey = `${categories} ${subOption}`; // Create unique key like 'Category SubOption'
    
    setSelectedFilters((prev) => {
      // Check if the current filter key is already selected
      const isCurrentlySelected = prev.includes(filterKey);
  
      // If the current option is already selected, deselect it
      if (isCurrentlySelected) {
        return prev.filter((item) => item !== filterKey);
      }
  
      // Create a new array that deselects any other option in the same category
      const updatedFilters = prev.filter(item => !item.startsWith(categories));
  
      // Add the newly selected option
      return [...updatedFilters, filterKey];
    });
  };
  

  const clearAllFilters = () => {
    setSelectedFilters([]); // Clear all selected filters
    handleFilterClose(); // Close the popover
  };
  // Debug: Log selected filters to check the values
  console.log("Selected Filters:", selectedFilters);


  

  const handleAddToPortfolio = (idx) => {
    console.log("IDX value is : ", idx);

    getCryporCurrencyListHome({
      slug: idx,
    }).then((res) => {
      if (res.code === "200") {
        console.log("data got successfully", res.data);
        
        // Update slugdata
        setSlugdata(res.data);
        setuser_role(res.user_role);

        // Use res.data directly here
        const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
        if (userData) {
          console.log("User DAta is there");
          setSlugpopupdata({
            slugname: res.data[0].slug,
            slug: res.data[0].name,
            units: res.data[0].total_unit,
            pricetoday: res.data[0].price_today,
            logo: res.data[0].logo,
          });
          console.log("Slug Popup Data : ", res.data);
          setOpen(true);
        } else {
          window.history.pushState({}, "", "/login");
          setLoginModalOpen(true);
        }
      } else {
        console.log("Data fetching error");
      }
    });
};
const sortedData1 = data;
const [ignoredRows, setIgnoredRows] = useState([]); // Tracks ignored rows
const [displayData, setDisplayData] = useState([]); // Displayed cryptos
const [queueData, setQueueData] = useState([]); // Cryptos queued for later

// Organize batches based on numeric prefixes in crypto symbols
const organizeBatches = (data) => {
  const batches = {};

  data.forEach((crypto) => {
    const prefix = crypto.crypto_symbol.match(/^\d+/)?.[0];
    if (prefix) {
      if (!batches[prefix]) {
        batches[prefix] = [];
      }
      batches[prefix].push(crypto);
    }
  });

  return batches;
};

// Round-robin display logic for batches
const roundRobinDisplay = (data) => {
  const batches = organizeBatches(data);
  let displayOrder = [];
  let batchQueue = [];

  Object.keys(batches).forEach((batchPrefix) => {
    const batch = batches[batchPrefix];
    displayOrder.push(...batch.slice(0, 2)); // First 2 cryptos per batch
    batchQueue.push(...batch.slice(2)); // Remaining cryptos
  });

  return { displayOrder, batchQueue };
};

// Effect to recalculate display data when sortedData1 changes
useEffect(() => {
  if (sortedData1 && sortedData1.length > 0) {
    const { displayOrder, batchQueue } = roundRobinDisplay(sortedData1);
    setDisplayData(displayOrder);
    setQueueData(batchQueue);
    setIsPredictionDataLoaded(true);
  } else {
    setIsPredictionDataLoaded(false);
  }
}, [sortedData1]);

// Handle ignore click and update display data dynamically
const handleIgnoreClick = (index) => {
  setIgnoredRows((prevIgnored) => [...prevIgnored, displayData[index].crypto_symbol]);

  setDisplayData((prevDisplay) => {
    const updatedDisplay = prevDisplay.filter(
      (_, idx) => idx !== index // Remove the ignored row from display
    );

    const ignoredCrypto = prevDisplay[index];
    const batchPrefix = ignoredCrypto.crypto_symbol.match(/^\d+/)?.[0];
    const nextInQueue = queueData.find((data) => data.crypto_symbol.startsWith(batchPrefix));

    if (nextInQueue) {
      setQueueData((prevQueue) =>
        prevQueue.filter((data) => data.crypto_symbol !== nextInQueue.crypto_symbol)
      );
      return [...updatedDisplay, nextInQueue];
    }

    return updatedDisplay;
  });
};

// Check if all rows are ignored
const allRowsIgnored = displayData.every((crypto) =>
  ignoredRows.includes(crypto.crypto_symbol)
);

  

  return (



    
    <div className="crypto-page" style={{backgroundColor: mediaQueryVar? "#EDF1F6" : "white"}}>

<Modal
  isOpen={open}
  onRequestClose={handleClose}
  overlayClassName={{
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",   // Semi-transparent black overlay
      backdropFilter: "blur(5px)",              // Optional: Add a blur effect to the overlay
    },
  }}
  className={{
    content: {
      position: "relative",
      backgroundColor: "white",                 // Set background for inner popup
      padding: "20px",                          // Add padding for content
      borderRadius: "8px",                      // Rounded corners
      maxWidth: mediaQueryVar?"100%":"100%",                        // Set a max width for popup
      margin: "0",                           // Center the popup horizontally
      boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.2)", // Optional: Popup shadow
    },
  }}
>
  <div className="CardOpener" style={{ overflow: "hidden" }}>
  {isFormSubmitted ? (
  <div
    style={{
      position: "fixed",          // Fix the popup to the top of the viewport
      top: 0,                     // Align it to the top
      left: 0,
      width: "100%",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba(0, 0, 0, 0.5)", // Optional: darken the background for emphasis
      zIndex: 1000,              // High z-index to overlay other elements
    }}
  >
    <Card className="card__container">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
         <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CheckCircleOutlineIcon sx={{ fontSize: 50, mr: 1 }} />
          </div>        
          <Typography
            severity="success"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Portfolio added successfully!
          </Typography>
          <div style={{ display: "flex", justifyContent: "space-evenly", marginTop:"10px",width: "100%" }}>
          <Button
            onClick={() => {
              setIsFormSubmitted(false);
              setOpen(false);
              setUnit("");
              setSelectedOption("");
              setPrice("");
              setTotalUnits(0);
              handleDateChange("");
              setUsablewallet("");
            }}
            color="primary"
            style={{
              backgroundColor: "rgba(67, 97, 238, 0.15)",
              borderRadius: "6px",
              border: "none",
              textTransform: "none",
              borderStyle: "solid",
              borderColor: "rgba(67, 97, 238, 0.15)",
              borderWidth: "1px",
              color: "#4361ee",
              whiteSpace: "nowrap",
              float: "inline-end",
              width: mediaQueryVar === true ? "150px" : "100px",
              height: mediaQueryVar === false ? "30px" : "",
              fontSize: mediaQueryVar === false ? "10px" : "11px",
            }}
          >
            Add More Cryptos
          </Button>
          <Button
           onClick={() =>
            navigate(
              `/my-portfolio/${portfolioTypeId}/${encodeURIComponent(
                selectedOption.label.replace(/\s+/g, '-')
              )}`
            )
          }
            variant="outlined"
            style={{
              backgroundColor: "rgba(67, 97, 238, 0.15)",
              borderRadius: "6px",
              border: "none",
              textTransform: "none",
              borderStyle: "solid",
              borderColor: "rgba(67, 97, 238, 0.15)",
              borderWidth: "1px",
              color: "#4361ee",
              whiteSpace: "nowrap",
              float: "inline-end",
              width: mediaQueryVar === true ? "100px" : "100px",
              height: mediaQueryVar === false ? "30px" : "",
              fontSize: mediaQueryVar === false ? "10px" : "11px",
            }}
            color="primary"
          >
            View Portfolio
          </Button>
        </div>
      </div>
    </Card>
  </div>
): (
      <Dialog
        PaperProps={{
          sx: {
            position: mediaQueryVar ? 'static' : 'fixed',
            bottom: mediaQueryVar ? '' : 0,
            left: mediaQueryVar ? '' : 0,
            right: mediaQueryVar ? '' : 0,
            marginLeft: mediaQueryVar ? "" : "0px",
            marginRight: mediaQueryVar ? "" : "0px",
            marginTop: '1%',
            maxWidth: '100%',
            minHeight: mediaQueryVar? '60%':'', // Adjust max height if needed
            borderRadius: mediaQueryVar ? "24px" : "24px 24px 0 0",
            marginBottom:mediaQueryVar?"":"0px",
            padding: mediaQueryVar?"10px":"1px",
            alignItems: "center",
            overflow: "scroll", 
            '&::-webkit-scrollbar': {
              display: 'none',
              },
               '-ms-overflow-style': 'none',  
               'scrollbar-width': 'none'// Ensure no scrollbar
          },
        }}
        open={open}
        onClose={handleClose}
      >
        <div style={{ padding: '20px', width: mediaQueryVar? '400px':'100%', margin: '0' ,marginTop:"-5px",}}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px', borderBottom:"1px #E3E3E3 solid" }}>
            <Typography style={{ fontWeight: '500', fontSize: '20.767px', paddingLeft: "100px" }} gutterBottom>
              Add To Portfolio
            </Typography>
            

            <div className="primaryButton">
              <img
                className="frameIcon"
                alt=""
                onClick={handleClose}
                src={window.constants.asset_path + '/images/frame17.svg'}
                style={{ background: "#F3F3F3", width: "25px", height: "25px", 
                }}
              />
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '5px' ,marginLeft: mediaQueryVar === false ? '10px' : ''}}>
            <Typography style={{ fontSize: "13.845px", fontWeight: '500', marginBottom: '1px', color: 'black',marginTop:"-8px" }}>
              Select Portfolio
            </Typography>
            <div style={{ marginTop: '5px' }}>
              <CreatableSelect
                isClearable
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderRadius: '12px',
                    border: '1px solid #1877F2',
                    boxShadow: 'none',
                    minHeight: '40px',
                    '&:hover': {
                      borderRadius: '12px',
                      border: '2px solid #1877F2',
                    },
                  }),
                  menu: (provided) => ({
                    ...provided,
                    borderRadius: '8px',
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: '',
                  }),
                  dropdownIndicator: (provided) => ({
                    ...provided,
                    color: '#1877F2',
                    border: "1px solid #1877F2",
                    padding: "5px",
                    margin: "8px",
                    borderRadius: "3.786px",
                    '&:hover': {
                      color: '#1877F2',
                    },
                  }),
                  indicatorSeparator: (provided) => ({
                    display: 'none',
                  }),
                }}
                placeholder=""
                onChange={(options) => handleChange(options, slugpopupdata.slugname)}
                onCreateOption={handleCreate}
                options={[
                  { value: '', label: 'Create new portfolio', _isNew_: true }, // Dynamic "create new option" item
                  ...options // Existing options
                ]}
                value={selectedOption}
              />
            </div>
            {showAlert && (
        <Alert severity="error" sx={{ marginBottom: '10px' }}>
          {errorMessage}
        </Alert>
        
      )}
          </div>
          <Typography style={{ fontSize: '13.845px', fontWeight: '500', color: '#000', marginTop: '10px', marginBottom: '5px' }}>
          Usable Wallet Value: {Usablewallet}
          </Typography>
          <div style={{ padding: mediaQueryVar? "8px":"5px", background: '#F3F3F3', borderRadius: '8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px' }}>
            <div style={{ display: 'flex', alignItems: 'center', flex: 0, minWidth: 0 }}>
              <img
                style={{ marginRight: '10px', border: "2px solid #000", borderRadius: "19px", height: "27.69px", width: "27.69px" }}
                alt=""
                src={slugpopupdata.logo}
              />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ fontWeight: '500', marginBottom: '0px', fontSize: "14px", color: "#7F7F80" }}>
                  {slugpopupdata.slugname}
                </Typography>
                
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '20%', flex: 1 }}>
              <Typography sx={{ fontWeight: '500', marginBottom: '4px', fontSize: "13px", color: "#7F7F80" }}> Price</Typography>
              <Typography sx={{ fontSize: '12.114px', fontWeight: '500', color: 'black', margin: '0' , marginLeft:"5%"}}>
                {slugpopupdata.pricetoday}
              </Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '20px' }}>
              <Typography sx={{ fontWeight: '500', marginBottom: '4px', fontSize: "13px", color: "#7F7F80" }}> Units</Typography>
              <Typography sx={{ fontSize: '12.114px', fontWeight: '500', color: 'black', margin: '0' }}>
                {totalUnits}
              </Typography>
            </div>
          </div>
          <div className="card__container__row3">
            <div style={{marginLeft:"-5px"}}>
              <p style={{ fontSize: "14px", fontWeight: "500", }}>Enter number of Units</p>
              <input
                style={{ backgroundColor:"white",color:"black",padding: "20px", border: "1px solid #1877F2", borderRadius: "10.384px", width: mediaQueryVar? "350px":"100%", height: "45px",margin:0 }}
                min="1"
                max="10"
                type="number"
                placeholder=""
                value={unit}
                onChange={(e) => setUnit(e.target.value)}
              ></input>
            </div>
            <div>
            ${formatToNonZero(
  unit * parseFloat((slugpopupdata.pricetoday || '').replace(/^\$/, ''))
)}

    </div>
            {user_role === "superuser" && (
              <>
                <div className="card__container__row3__r2">
                  <div style={{marginLeft:"-5px"}}>
                  <p  style={{ fontSize: "14px", fontWeight: "500" }}>
                    Buy/Sell Price($)
                  </p>
                  <input
                  style={{ backgroundColor:"white",color:"black",padding: "20px", border: "1px solid #1877F2", borderRadius: "10.384px", width: "345px", height: "45px" }}
                    className="card__container__row3__r2__input"
                    min="1"
                    max="10"
                    type="number"
                    placeholder="Enter Price"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                  ></input></div>
                </div>
                <div style={{marginBottom:"10px",marginLeft:"-5px"}}>
                      <p style={{fontSize:"14px", fontWeight:"500"}}>Date</p>
                      <TextField
                        type="date"
                        value={selectedDate}
                        onChange={(e) => handleDateChange(e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '10.384px',
                          border:"1px solid #1877F2",
                          boxShadow:"none",
                          width:"190%",
                          height:"45px",
                          
                        },
                        }}
                        />
                    </div>
              </>
            )}
            <div className="card__container__row3__r4" style={{ marginTop: "10px" }}>
              <Button
                className={
                  buySell === "buy"
                    ? "buttonToggleButActive"
                    : "buttonToggleBuy"
                }
                value="Buy"
                style={{
                  lineHeight: "20px",
                  width: mediaQueryVar? "159px":"130px",
                  borderRadius: "27px",
                  border: "1px solid #1877F2",
                  padding: mediaQueryVar?"15px 85px":"13px 75px",
                  marginLeft: "-6px",
                  backgroundColor: buySell === "buy" ? "" : "#1877F2",
                  color: buySell === "buy" ? "" : "white",
                  textTransform: "none",
                  fontSize: "18px",
                  fontWeight: "700"
                }}
                onClick={() => handleClick("buy")}
              >
                Buy
              </Button>
              <Button
                className={
                  buySell === "sell"
                    ? "buttonToggleSellActive"
                    : "buttonToggleSell"
                }
                value="sell"
                style={{
                  lineHeight: "20px",
                  width: mediaQueryVar? "159px":"130px",
                  borderRadius: "27px",
                  border: "1px solid #1877F2",
                  marginLeft: "10px",
                  padding: mediaQueryVar?"15px 85px":"13px 75px",
                  backgroundColor: buySell === "sell" ? "" : "#1877F2",
                  color: buySell === "sell" ? "" : "white",
                  textTransform: "none",
                  fontSize: "18px",
                  fontWeight: "700"
                }}
                onClick={() => handleClick("sell")}
              >
                Sell
              </Button>
             
            </div>
          </div>
        </div>
      </Dialog>
    )}
  </div>
</Modal>


      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} fullWidth maxWidth="sm">
  <DialogTitle>
    Create New Portfolio
    <IconButton aria-label="close"onClick={() => { setIsDialogOpen(false); setNewOptionValue(''); }}  sx={{ position: 'absolute', right: 13, top: 8 }}>
      <CloseIcon />
    </IconButton>
  </DialogTitle>
  <DialogContent>
  <div>
      <TextField
        autoFocus
        label="Enter Name"
        value={newOptionValue}
        onChange={handleChange2}
        fullWidth
        margin="dense"
      />
      {showAlert && (
        <Alert severity="error" sx={{ marginTop: '8px' }}>
          Hyphens are not allowed in the name.
        </Alert>
      )}
    </div>
  </DialogContent>
  <DialogActions sx={{marginRight:'14px'}}>
  <Button onClick={() => { setIsDialogOpen(false); setNewOptionValue(''); }} sx={{fontWeight:'bold'}}  variant="outlined" color="primary">
      Cancel
    </Button>
    <Button onClick={handleDialogSubmit} variant="contained" color="primary" sx={{fontWeight:'bold'}}disabled={!newOptionValue.trim()}>
      Submit
    </Button>
  
  </DialogActions>
</Dialog>

        <Snackbar
          open={snackbaropen}
          autoHideDuration={5000}
          onClose={closesnackbar}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert severity={severity}>{msgforUpdatePortfolio}</Alert>
        </Snackbar>


      <Grid item xs={12} align="left">
      <div style={{ 
        display: "flex", 
        alignItems: "center", 
        justifyContent: "space-between", // Changed from space-between to flex-end
        marginTop: "0px", 
        marginBottom: "10px",
        marginLeft: "5px"
    }}>
       <Helmet>
        <title>Recommendations</title>
</Helmet>
      {isLoginModalOpen && (
        <LoginPopUp 
          isLoginModalOpen={isLoginModalOpen}
          setLoginModalOpen={setLoginModalOpen} // Pass the state setter down
          onClose={handleModalClose}
        />
      )}
    {mediaQueryVar===false ? (
        <Box
        sx={{
          backgroundColor: "#1877F2", // Background color for the container
          padding: "3.105px", // Padding inside the container
          borderRadius: "38.042px", // Border radius for the container
          display: 'flex', // Ensure that the box is flexible
          alignItems: 'center', // Center the content vertically
          width: mediaQueryVar2?"300px":"330px", // Full width on extra small screens
          height: "36.63px",
          border: 'none',
          marginLeft: "-12px",
          marginTop: "3%",
          marginBottom:"5%"
        }}
      >
        <Tabs
          value={selectedButton}
          onChange={(event, newValue) => handleButtonClick(newValue)}
          aria-label="basic tabs example"
          sx={{
            flexGrow: 1, // Make the tabs take up remaining space
            "& .MuiTabs-indicator": {
              display: "none",
            },
            display: 'flex', // Ensure that the tabs are displayed in a row
            overflow: 'hidden', // Prevent the tabs from spilling out of the border radius
          }}
        >
          <Tab
            label="Portfolio Analysis"
            value="PortfolioGallery"
            component={Link}
            to={url}
            sx={{
              whiteSpace: "nowrap",
              borderRadius: "20.962px",
              minHeight: "31.5px",
              height: "31.5px",
              fontWeight: selectedButton === "PortfolioGallery" ? "700" : "500",
              marginLeft: "1px",
              paddingLeft: "-15px",
              marginTop: "8px",
              border: "1px solid black",
              marginBottom: "-5px",
              fontSize: mediaQueryVar2?"13px":"15px",
              marginRight: "-15px",
              backgroundColor: selectedButton === "PortfolioGallery" ? "#FFFFFF" : "transparent", // White background for selected tab
              color: selectedButton === "PortfolioGallery" ? "#000000 !important" : "#FFFFFF", // Black text for selected tab
              textTransform: "none",
              border: "none"
            }}
          />
          <Tab
            label="Recommendations"
            value="CoinPrices"
            
            sx={{
              whiteSpace: "nowrap",
              borderRadius: "20.962px",
              minHeight: "31px",
              height: "31px",
              marginLeft: "5px",
              marginTop: "8px",
              border: "1px solid black",
              fontWeight: selectedButton === "CoinPrices" ? "700" : "500",
              marginRight: "0px",
              fontSize: mediaQueryVar2?"13px":"15px",
              paddingRight: "-8px",
              textTransform: "none",
              backgroundColor: selectedButton === "CoinPrices" ? "#FFFFFF" : "transparent", // White background for selected tab
              color: selectedButton === "CoinPrices" ? "#000000 !important" : "#FFFFFF", // Black text for selected tab
              border: 'none', // Remove border for tabs
            }}
          />
        </Tabs>
      </Box>
    ) : (null)}
        <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerToggle}
            sx={{ display: { sm: "none"} }}
        >
            <img 
                src='/images/menubar.svg'
                alt="Menu Icon" 
                style={{ 
                    width: "24px", 
                    height: "24px",
                    marginRight: "10px",
                    marginTop: "10px" 
                }} 
            />
        </IconButton>
    </div>

</Grid>
<Drawer
    container={container}
    variant="temporary"
    open={mobileOpen}
    onClose={handleDrawerToggle}
    ModalProps={{
        keepMounted: true, // Better open performance on mobile.
    }}
    sx={{
        display: { xs: 'block', sm: 'none' },
        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: "400", backgroundColor: 'white', color: '#727376' },
    }}
>
    {drawer}
</Drawer>
{mediaQueryVar ? null : (
  <Typography
  variant="h6"
  component="div"
  style={{
    fontFamily: "Satoshi, sans-serif",
    fontWeight: 500,
    fontSize: mediaQueryVar2 ? "14px" : "16px",
    marginRight:"3px"
  }}
>
  <span style={{
    backgroundColor:"#1877f2",
    padding:"4px 4px",
    borderRadius:"5px",
    color:"white",
    marginLeft:'0px',
    
  }}> Top Portfolio</span> Recommendations
</Typography>

)}



{mediaQueryVar ? null : (
      !isDataLoaded && !loader ? (
        <div
  style={{
    position: "relative", 
    border: "1px solid #B6B6B8",
    margin: "3% 2% 2% 2%",
    padding: "2%",
    borderRadius: "8px",
    textAlign: "center",
    height: "25vh",
    overflow: "hidden", // Ensures content stays within the div,
    marginBottom:"30px"
  }}
>
  {/* Blurred background image */}
  <div style={{
    backgroundImage: `url(${window.constants.asset_path}/images/default-recommend-mobile.png)`,
    backgroundSize: "cover",
    backgroundPosition: "center center",
    filter: "blur(5px)",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 0,
  }}></div>

  {/* Overlay content without blur */}
  <div style={{ 
    position: "relative", 
    zIndex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  }}>
    <style>
      {leftRightMotion}
    </style>
    <div style={{ position: "relative", width: "64px", height: "64px" }}>
      {/* Lens image with left-right motion animation */}
      <img 
        src={window.constants.asset_path + '/images/lens.png'}
        alt="Searching Lens"
        style={{
          width: "64px", 
          height: "64px", 
          position: "absolute",
          animation: `leftRightMotion 1.5s infinite ease-in-out`,
        }}
      />
    </div>
    <p style={{ fontFamily:"Satoshi,sans-serif", marginTop: "10px", fontSize: "18px", color: "black" }}>
      <b>Searching For The Best Crypto Portfolio, Personalized</b>
    </p>
  </div>
</div>

        ) : loader === true ? (
          <div style={{ marginBottom:"15%",border:"1px solid #B6B6B8",marginTop:"4%",textAlign: "center", color: "black", fontSize: "18px", backgroundColor: "white", padding: "2% ", borderRadius: "8px" }}>
            No Portfolio Recommendations at the moment.
          </div>
        ) : (
          portfolioData.map((portfolio, index) => (
            <React.Fragment key={index}>
            <Box 
            
              sx={{ 
                overflowY: "auto", 
                "&::-webkit-scrollbar": { display: "none" }, 
                scrollbarWidth: "none",
                filter: !userData && index >= 2 ? "blur(4px)" : "none",
                marginBottom: index === portfolioData.length - 1 ? "50px" : "12px" // Ensure this margin for the last element
              }}
            >
              <Box 
                sx={{ 
                  padding: "0", 
                  background:
        "var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
                  borderRadius: "6px", 
                  marginTop: "15px", 
                  marginBottom: "10px", 
                  border: "1px solid #DADEDF", 
                  marginLeft: "3px", 
                  marginRight: "5px" 
                }}
              >
                <Box sx={{ margin: "8px", marginRight: "16px",  }}>
                  <span style={{ color:  "black" }}>
                  {portfolio.portfolio_logo ? (
                  <img
                    src={window.constants.asset_path + portfolio.portfolio_logo}
                    style={{
                      width: "38px",
                      height: "38px",
                      borderRadius: "33px",
                      border: "2px solid black",
                      marginRight:"2%"
                    }}
                    alt="Profile Pic"
                  />
                ) : (
                  <img
                    src={window.constants.asset_path + "/images/avtar.jpeg"}
                    style={{
                      width: "38px",
                      height: "38px",
                      borderRadius: "33px",
                      border: "2px solid black",
                      marginLeft:"3px",
                     marginRight:"2%"
                    }}
                    alt="Default Profile Pic"
                  />
                )}
                  <a href={`${process.env.REACT_APP_HOME_URL}portfolio/${portfolio.portfolio_name.replace(/\s+/g, '-')}`} style={{color:"black"}}>{portfolio.portfolio_name}</a>
                  </span>
                </Box>
                <Box sx={{ background: "#fff", borderRadius: "6px", padding: "10px" }}>
                  <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: "0px", borderBottom: "2px #DFDFDF solid" }}>
                    <Box sx={{ flex: 1, marginRight: "8px" }}>
                      <div style={{ display: "flex", margin: "4px", marginTop: "-5px" }}>
                        <span style={{ textAlign: "left", fontSize: "13px", color: "#76787A", fontWeight: "500", marginRight: "10px" }}>Last 7 Days:</span>
                        <span style={{ textAlign: "right", fontWeight: "500", fontSize: "14px" }}>{portfolio.roi_7d}</span>
                      </div>
                    </Box>
                    <Box sx={{ flex: 1 }}>
                      <div style={{ display: "flex", justifyContent: "space-between", margin: "4px", marginTop: "-5px" }}>
                        <span style={{ textAlign: "left", fontSize: "13px", color: "#76787A", fontWeight: "500", marginLeft: "20px" }}>Last 30 Days:</span>
                        <span style={{ textAlign: "right", fontWeight: "500", fontSize: "14px" }}>{portfolio.roi_30d}</span>
                      </div>
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "space-between", paddingBottom: "5px", marginBottom: "0px" }}>
                    <Box sx={{ flex: 1, marginRight: "8px" }}>
                    <div style={{ display: "flex", alignItems: "center", margin: "4px", marginBottom: "-5px" }}>
  <span style={{ fontSize: "13px", color: "#76787A", fontWeight: "500", marginRight: "10px" }}>
    Last 12 Months:  NA
  </span>
  <span style={{ fontWeight: "500", fontSize: "14px", flexGrow: 1, textAlign: "right" }}>
    
  </span>
  <img
    width="28.62px"
    height="18px"
    src={starredPortfolios[portfolio.portfolio_id] ? `${window.constants.asset_path}/images/selectedStar.svg` : `${window.constants.asset_path}/images/star.svg`}
    alt="View Icon"
    onClick={(e) => {
      e.stopPropagation();
      handleStarClick(portfolio.portfolio_id, portfolio.portfolio_name);
    }}
    style={{
      cursor: 'pointer',
      width: '24px',
      height: '20px',
    }}
  />
</div>

                      
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            </React.Fragment>
          ))
        )
      )}

      
      {mediaQueryVar ? (
        
        <div className="layout">
          <div className>
          <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}

                    sx={{

                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: 'white', color: '#727376' },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{

                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: 'white', color: '#727376'},
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            </div>
            
        <div className="content">
        <a href={`./`}>
            <Button size="small" color="primary" edge="start"  aria-label="menu"
            style={{
              borderRadius: "42px",
              padding:"10px 14px",
              backgroundColor:"#FFF",
              border:"1px solid #F0F0F0",
              width:"85px",
              height:"38px",
              marginLeft:"15px",
              marginBottom:"10px"
            }}>
            <img src={window.constants.asset_path + '/images/backarrow.svg'} alt="" /> 
            <span style={{fontSize:"14px",fontWeight:"500",color:"#727272",marginLeft:"8px",textTransform:"none"}}>Back</span>
            </Button>
            </a>
        <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="center"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    marginBottom: "20px",
                    marginTop: "5px",
                    
                  }}
                >

<Box
      sx={{
        backgroundColor: "#1877F2", // Background color for the container
        paddingTop: {xs:'10px', sm:'4px'},// Padding inside the container
        paddingLeft: {xs:'0px', sm:'0px'},
        paddingRight:{xs:'0px', sm:'0px'},
        paddingBottom: {xs:'0px', sm:'4px'},
        borderRadius: "50px", // Border radius for the container
        display: 'flex', // Make sure the box is flexible
        justifyContent: 'flex-start', // Center the content
        alignItems: 'center', // Center the content
        width: "395px",
        height:{xs:'44px', sm:'48px'},
        border:'none',
        marginLeft:"10px",
        marginTop:"10px"
      }}
    >
                  
                  <Tabs
  value={selectedButton}
  onChange={(event, newValue) => handleButtonClick(newValue)}
  aria-label="basic tabs example"
  sx={{
    alignSelf: mediaQueryVar === true ? "flex-start" : "",
    marginLeft: mediaQueryVar === true ? "2px" : "2px",
    borderRadius: "50px", // Adjust border radius for more rounded corners
    border: "none",
    "& .MuiTabs-indicator": {
      display: "none",
    },
    display: 'flex', // Make sure the tabs are displayed in a row
    overflow: 'hidden', // Prevent the tabs from spilling out of the border radius
  }}
>
  <Tab
    label="Portfolio Analysis"
    value="PortfolioGallery"
    component={Link}
            to={url}
    sx={{
      whiteSpace: "nowrap",
      bottom: "0px",
      marginTop:"1.1px",
      marginBottom:"0px",
      marginLeft:"3px",
      marginRight:"3px",
      paddingBottom:"2px",
      paddingTop: "0px",
      paddingLeft:"20px",
      paddingRight: "20px",
     minHeight:"20px",
     lineHeight:{xs:'28px', sm:'36px'},
      borderRadius: selectedButton === "PortfolioGallery" ? "20px" : "20px", // Rounded corners for selected tab
      textTransform: "none",
      fontSize: {xs:'13px', sm:'17px'},
      fontStyle: "normal",
      fontWeight: selectedButton === "PortfolioGallery" ? "700" : "500",
      
      backgroundColor: selectedButton === "PortfolioGallery" ? "#FFFFFF" : "transparent", // White background for selected tab
      color: selectedButton === "PortfolioGallery" ? "#000000 !important" : "#FFFFFF", // Black text for selected tab
      border: 'none', // Remove border for tabs
      background: selectedButton === "PortfolioGallery" ? "#FFFFFF" : "transparent", // Blue background for unselected tab
    }}
  />
  <Tab
    label="Recommendations"
    value="CoinPrices"
    
    sx={{
      whiteSpace: "nowrap",
      bottom: "0px",
      marginTop:"0px",
      marginBottom:"0px",
      marginLeft:"3px",
      marginRight:"3px",
      paddingBottom:"0px",
      paddingTop: "0px",
      paddingLeft:"20px",
      paddingRight: "20px",
     minHeight:"20px",
     lineHeight:{xs:'36px', sm:'36px'},
      borderRadius: selectedButton === "CoinPrices" ? "50px" : "50px", // Rounded corners for selected tab
      textTransform: "none",
      fontSize: "17px",
      fontStyle: "normal",
      fontWeight: selectedButton === "CoinPrices" ? "700" : "500", 
      
      backgroundColor: selectedButton === "CoinPrices" ? "#FFFFFF" : "transparent", // White background for selected tab
      color: selectedButton === "CoinPrices" ? "#000000 !important" : "#FFFFFF", // Black text for selected tab
      border: 'none', // Remove border for tabs
      background: selectedButton === "CoinPrices" ? "#FFFFFF" : "transparent", // Blue background for unselected tab
    }}
  />
</Tabs>

</Box>

                </Grid>
        <Typography
  variant="h6"
  component="div"
  style={{
    fontFamily: "Satoshi, sans-serif",
    fontWeight: "500",
    fontSize: "16px",
    marginRight:"3px",
    marginLeft:"2%"
  }}
>
  <span style={{
    backgroundColor:"#1877f2",
    padding:"4px 4px",
    borderRadius:"5px",
    color:"white",
    marginLeft:'0px',
    
  }}> Top Portfolio</span> Recommendations
</Typography>
    
                 {!isDataLoaded && !loader ? (
  // Show skeleton loaders while data is loading
 
    // Inside your component's JSX

<div
  style={{
    position: "relative", 
    border: "1px solid #B6B6B8",
    margin: "3% 2% 2% 2%",
    padding: "2%",
    borderRadius: "8px",
    textAlign: "center",
    height: "30vh",
    overflow: "hidden" // Ensures content stays within the div
  }}
>
  {/* Blurred background image */}
  <div style={{
    backgroundImage: `url(${window.constants.asset_path}/images/default-recommed.png)`,
    backgroundSize: "cover",
    backgroundPosition: "center center",
    filter: "blur(5px)",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 0,
  }}></div>

  {/* Overlay content without blur */}
  <div style={{ 
    position: "relative", 
    zIndex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  }}>
    <style>
      {leftRightMotion}
    </style>
    <div style={{ position: "relative", width: "64px", height: "64px" }}>
      {/* Lens image with left-right motion animation */}
      <img 
        src={window.constants.asset_path + '/images/lens.png'}
        alt="Searching Lens"
        style={{
          width: "64px", 
          height: "64px", 
          position: "absolute",
          animation: `leftRightMotion 1.5s infinite ease-in-out`,
        }}
      />
    </div>
    <p style={{ fontFamily:"Satoshi,sans-serif", marginTop: "10px", fontSize: "18px", color: "black" }}>
      <b>Searching For The Best Crypto Portfolio, Personalized</b>
    </p>
  </div>
</div>



) : loader === true ? (
  <div style={{ marginBottom:"2%",marginLeft:"2%",marginRight:"2%",border:"1px solid #B6B6B8", marginTop:"4%",textAlign: "center", color: "black", fontSize: "18px", backgroundColor: "white", padding: "2% ", borderRadius: "8px" }}>
    No Portfolio Recommendations at the moment.
  </div>
) : (
  // Render the table when data is loaded and portfolioData is not empty
  <TableContainer 
    style={{ 
      width: "96%", 
      overflowX: "hidden", 
      border: "1px solid #DADEDF", 
      borderRadius: "5px", 
      padding: 0, 
      marginTop: "2%", 
      marginBottom: "5%" ,
      marginLeft:"2%",
    }}
  >
    <div style={{ backgroundColor: "white", padding: "2%", borderRadius: "8px"}}>
      <Table className="non_responsiveTable" aria-label="customized table" style={{ borderCollapse: "collapse"}}>
        <TableHead>
          <TableRow>
            <StyledTableCell 
              className="cryptotableviewHeader" 
              style={{ 
                borderRadius: "8px 0 0 8px", 
                border: 0, 
                padding: 0, 
                width: "20%" 
              }}
            >
              <button 
                disabled 
                style={{ 
                  textAlign: "left", 
                  color: "#727376", 
                  fontSize: "16px", 
                  width: "100%", 
                  height: "100%", 
                  border: "none", 
                  background: "white", 
                  padding: "12px 12px", 
                  margin: "0px 2px 0px 2px", 
                  boxShadow: "0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)" ,
                  fontWeight:"500"
                }}
              >
                Portfolio Name
              </button>
            </StyledTableCell>

            <StyledTableCell className="cryptotableviewHeader" style={{ padding: 0, width: "17%" }}>
            <button disabled style={{
               textAlign: "center", 
               color: "#727376", 
               fontSize: "16px", 
               width: "100%", 
               height: "100%", 
               border: "none", 
               background: "white", 
               padding: "12px 12px", 
               margin: "0px 0px 0px 2px", 
               boxShadow: "0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)" ,
               fontWeight:"500"
            }}>
              Watchlist
            </button>
          </StyledTableCell>

            
            <StyledTableCell 
              className="cryptotableviewHeader" 
              style={{ padding: 0, width: "auto" }}
            >
              <button 
                disabled 
                style={{ 
                  textAlign: "center", 
                  color: "#727376", 
                  fontSize: "16px", 
                  width: "100%", 
                  height: "100%", 
                  border: "none", 
                  background: "white", 
                  padding: "12px 12px", 
                  margin: "0px 0px 0px 2px", 
                  boxShadow: "0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)" ,
                  fontWeight:"500"
                }}
              >
                Last 7 Days
              </button>
            </StyledTableCell>
            <StyledTableCell 
              className="cryptotableviewHeader" 
              style={{ padding: 0, width: "auto" }}
            >
              <button 
                disabled 
                style={{ 
                  textAlign: "center", 
                  color: "#727376", 
                  fontSize: "16px", 
                  width: "100%", 
                  height: "100%", 
                  border: "none", 
                  background: "white", 
                  padding: "12px 12px", 
                  margin: "0px 0px 0px 2px", 
                  boxShadow: "0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)" ,
                  fontWeight:"500"
                }}
              >
                Last 30 Days
              </button>
            </StyledTableCell>
            <StyledTableCell 
              className="cryptotableviewHeader" 
              style={{ padding: 0, width: "auto" }}
            >
              <button 
                disabled 
                style={{ 
                  textAlign: "center", 
                  color: "#727376", 
                  fontSize: "16px", 
                  width: "100%", 
                  height: "100%", 
                  border: "none", 
                  background: "white", 
                  padding: "12px 12px", 
                  margin: "0px 0px 0px 2px", 
                  boxShadow: "0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)" ,
                  fontWeight:"500"
                }}
              >
                Last 12 Months
              </button>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {portfolioData.map((portfolio, index) => (
             <React.Fragment key={index}>
            <StyledTableRow 
            style={{ 
              backgroundColor: index % 2 === 0 ? "white" : "#F0F0F0", 
              borderTop:"7px solid white",
              filter: !userData && index >= 2 ? "blur(4px)" : "none",
              }}>
              <StyledTableCell
                component="th"
                scope="row"
                style={{
                  borderBottom: "none",
                  color: "#000",
                  fontSize: "16px",
                  fontWeight: "500",
                  paddingLeft: "16px",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  borderRight: "1px solid white",
                  width: "38%",
                  padding:"1.5%",
                  color:  "black",
                  background: "var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))"
                }}
              >
                <a href={`${process.env.REACT_APP_HOME_URL}portfolio/${portfolio.portfolio_name.replace(/\s+/g, '-')}`} style={{color:"black", display:"flex",alignItems:"center"}}>
                {portfolio.portfolio_logo ? (
        <img
          src={window.constants.asset_path + portfolio.portfolio_logo}
          style={{
            border: "2px solid black",
            width: "35px",
            height: "35px",
            borderRadius: "50%",
            marginRight:"2%"
          }}
          alt="Profile"
        />
      ) : (
        <img
          src={window.constants.asset_path + "/images/avtar.jpeg"}
          style={{
            border: "2px solid black",
            width: "35px",
            height: "35px",
            borderRadius: "50%",
            marginRight:"2%"
          }}
          alt="Default Avatar"
        />
      )}{portfolio.portfolio_name}
               </a> 
              </StyledTableCell>
              <StyledTableCell style={{
  borderBottom: "none",
  color: "#000",
  fontSize: "16px",
  fontWeight: "500",
  paddingLeft: "16px",
  paddingTop: "8px",
  paddingBottom: "8px",
  borderRight: "1px solid #DADEDF",
  textAlign: "center"
}}>
  <img
    width="28.62px"
    height="18px"
    src={starredPortfolios[portfolio.portfolio_id] ? `${window.constants.asset_path}/images/selectedStar.svg` : `${window.constants.asset_path}/images/star.svg`}
    alt="View Icon"
    onClick={(e) => {
      e.stopPropagation();
      handleStarClick(portfolio.portfolio_id, portfolio.portfolio_name);
    }}
    style={{
      cursor: 'pointer',
      width: '24px',
      height: '20px',
    }}
  />
</StyledTableCell>
              <StyledTableCell
                className="one"
                style={{
                  borderBottom: "none",
                  color: "#000",
                  fontSize: "16px",
                  fontWeight: "500",
                  textAlign: "center",
                  paddingTop: "8px",
                  paddingBottom: "8px",
                  borderRight: "1px solid #DADEDF",
                  width: "200px"
                }}
              >
               {portfolio.roi_7d} 
              </StyledTableCell>
              <StyledTableCell
                className="one"
                style={{
                  borderBottom: "none",
                  color: "#000",
                  fontSize: "16px",
                  fontWeight: "500",
                  textAlign: "center",
                  paddingTop: "8px",
                  paddingBottom: "8px",
                  borderRight: "1px solid #DADEDF",
                  width: "200px"
                }}
              >
                {portfolio.roi_30d} 
              </StyledTableCell>
              <StyledTableCell
                className="one"
                style={{
                  borderBottom: "none",
                  color: "#000",
                  fontSize: "16px",
                  fontWeight: "500",
                  textAlign: "center",
                  paddingTop: "8px",
                  paddingBottom: "8px",
                  borderRight: "1px solid #DADEDF",
                  width: "200px"
                }}
              >
                NA 
              </StyledTableCell>
            </StyledTableRow>
           

                 </React.Fragment> 
          ))}
        </TableBody>
      </Table>
    </div>
  </TableContainer>
)}

  
{isPredictionDataLoaded === false && predictionLoader ? (
      <div style={{ border:"1px solid #B6B6B8",margin:"0px 2%",marginTop:"4%",textAlign: "center", color: "black", fontSize: "18px", backgroundColor: "white", padding: "2% ", borderRadius: "8px" }}>
        No Crypto Recommendations at the moment.
      </div>
    ) : (
      <div>
        <Typography
          variant="h6"
          component="div"
          style={{
            fontFamily: "Satoshi, sans-serif",
            fontWeight: 500,
            fontSize: "16px",
            marginRight:"3px",
            marginLeft:"2%"
          }}
        >
          <span style={{
            backgroundColor:"#1877f2",
            padding:"4px 4px",
            borderRadius:"5px",
            color:"white",
            marginLeft:'0px',
            
          }}> Top Crypto</span> Recommendations
        </Typography>
        
        <div style={{ border:allRowsIgnored && isPredictionDataLoaded ? "1px solid #B6B6B8" :"",fontFamily: "Satoshi, sans-serif",backgroundColor: isPredictionDataLoaded? "white":"", padding: "0%", borderRadius: "8px", margin:isPredictionDataLoaded?"2%":"0%", marginRight:isPredictionDataLoaded?"4%":"0%"}}> 
          <table className={isPredictionDataLoaded ? "predictions-table" : ""}
 style={{ width: "100%" }}>
          {isPredictionDataLoaded==true && (
            <thead>
              <tr>
                <th style={{ textAlign: "left", width: "15%", fontWeight: "500", color: "#727376", fontSize: "16px", paddingTop: "2%" }}>
                {performanceType}
                </th>
                <th style={{ textAlign: "center", fontWeight: "500", color: "#727376", fontSize: "16px", paddingTop: "2%" }}>
                  Recommendation
                </th>
                <th style={{ textAlign: "center", fontWeight: "500", color: "#727376", fontSize: "16px", paddingTop: "2%" }}>
                Today Price
                </th>
                <th style={{ textAlign: "center", fontWeight: "500", color: "#727376", fontSize: "16px", paddingTop: "2%" }}>
                Forecast 2025
                </th>
                <th></th>
              </tr>
            </thead>
          )}
            <tbody>
              {isPredictionDataLoaded === false ? (
              <tr colspan="5">
                <td>
                <div
                  style={{
                    position: "relative", 
                    border: "1px solid #B6B6B8",
                    margin: "3% 2% 2% 2%",
                    padding: "2%",
                    borderRadius: "8px",
                    textAlign: "center",
                    height: "30vh",
                    overflow: "hidden" // Ensures content stays within the div
                  }}
                >
                  {/* Blurred background image */}
                  <div style={{
                    backgroundImage: `url(${window.constants.asset_path}/images/default-recommed.png)`,
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                    filter: "blur(5px)",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    zIndex: 0,
                  }}></div>
                
                  {/* Overlay content without blur */}
                  <div style={{ 
                    position: "relative", 
                    zIndex: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}>
                    <style>
                      {leftRightMotion}
                    </style>
                    <div style={{ position: "relative", width: "64px", height: "64px" }}>
                      {/* Lens image with left-right motion animation */}
                      <img 
                        src={window.constants.asset_path + '/images/lens.png'}
                        alt="Searching Lens"
                        style={{
                          width: "64px", 
                          height: "64px", 
                          position: "absolute",
                          animation: `leftRightMotion 1.5s infinite ease-in-out`,
                        }}
                      />
                    </div>
                    <p style={{ fontFamily:"Satoshi,sans-serif", marginTop: "10px", fontSize: "18px", color: "black" }}>
                      <b>Searching For The Best Crypto, Personalized</b>
                    </p>
                  </div>
                </div>
                </td>
                </tr>
              ) : allRowsIgnored ? (
                <tr>
                  <td colSpan={5} style={{textAlign: "center", color: "black", fontSize: "18px", padding: "2%" }}>
                  No more recommendations available today.
                  </td>
                </tr>
              ) : (
                displayData.map((data, index) => (
                    <React.Fragment key={index}>
                      <tr style={{ filter: !userData && index >= 2 ? "blur(4px)" : "none" }}>
                      <td
  style={{
    width: "38%",
    background: 'var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))',
    borderBottom: "6px solid #fff",
    padding: "5px 7px",
    color: "#000",
    fontSize: "16px",
    fontWeight: "500",
  }}
>
  <div
    style={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center", // Ensure proper vertical alignment
    }}
  >
    {/* Left Section: Logo and Crypto Symbol */}
    <div style={{ display: "flex", alignItems: "center" }}>
      <img
        alt=""
        src={data.target_logo}
        style={{
          borderRadius: "19px",
          border: "2px solid #000",
          width: "36px",
          height: "36px",
          marginRight: "8px",
        }}
      />
      <a
        href={`${process.env.REACT_APP_HOME_URL}crypto/${data.target_name}`}
        style={{
          color: "black",
          fontWeight: "500",
        }}
      >
        {data.crypto_symbol.split("-")[1]}
      </a>
    </div>

    {/* Right Section: Category */}
    <span
      style={{
        borderRadius: "15px",
        fontSize: "12px",
        fontWeight: "500",
        color: "#000",
        border: "1px solid grey",
        padding: "2px 11px",
        textAlign: "center",
      }}
    >
      {data.category}
    </span>
  </div>
</td>

                        <td style={{ textAlign: "center", color: "black", fontWeight: "500", fontSize: "16px", paddingLeft: "10px" }}>
                        <img 
                            alt="" 
                            src={data.symbol_logo} 
                            style={{ 
                              borderRadius: "19px", 
                              marginRight: "16px", 
                              border: "2px solid #000", 
                              width: "36px", 
                              height: "36px",
                              marginLeft: "0.5%",
                              marginRight: "4%"
                            }} 
                          />
                          <a href={`${process.env.REACT_APP_HOME_URL}crypto/${data.crypto_name}`} style={{ color: "black", marginLeft: "2%" }}>
                            {data.crypto_symbol.split("-")[3]}
                          </a>
                        </td>
             
                        <td style={{ textAlign: "center", color: "black", fontWeight: "500", fontSize: "16px", paddingLeft: "10px" }}>
                        {data.current_price}
                        </td>
                        <td style={{ textAlign: "center", color: "black", fontWeight: "500", fontSize: "16px", paddingLeft: "10px" }}>
                          {data.forecast_price}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <Button onClick={() => handleAddToPortfolio(data.crypto_symbol.split("-")[3])} style={{ marginLeft: "-10px",marginRight:"-20px" }}>
                            <div
                              style={{
                                display: "inline-flex",
                                border: "1px solid #1877F2",
                                borderRadius: "50%",
                                padding: "3px 4px",
                                marginLeft:"-5px"
                              }}
                            >
                              <img
                                width="12px"
                                src={window.constants.asset_path + '/images/plus.svg'}
                                alt="Add"
                              />
                            </div>
                          </Button>
                          <Button 
                            onClick={() => handleIgnoreClick(index)}
                          >
                            <img width="20px" src={window.constants.asset_path + '/images/trash.svg'} alt="Ignore"/>
                          </Button>
                        </td>
                      </tr>
                      {index === 1 && !userData && (
                        <tr style={{ height: "auto", backgroundColor: "transparent", border: "none", position: "relative" }}>
                          <td colSpan={5} style={{ borderBottom: "none", padding: "0", position: "relative", textAlign: "center" }}>
                            <Button
                              onClick={() => {
                                const originalUrl = window.location.pathname + window.location.search;
                                sessionStorage.setItem('originalUrl', originalUrl);
                                handleLoginClick();
                              }}
                              style={{
                                color: "white",
                                backgroundColor: "#1877F2",
                                textTransform: "none",
                                width: "200px",
                                height: "45px",
                                fontWeight: "600",
                                fontSize: "18px",
                                borderRadius: "30px",
                                padding: "8px 24px",
                                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
                                cursor: "pointer"
                              }}
                            >
                              Login/Signup
                            </Button>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                ))
              )}
            </tbody>
          </table>
          
        </div>
      </div>
    )}
    </div>
  </div>
      
      ) : (
        <div style={{paddingBottom:"70px"}}>
        {isPredictionDataLoaded === false && predictionLoader ? (
          <div>
          <div>
        <Typography
          variant="h6"
          component="div"
          style={{
            fontFamily: "Satoshi, sans-serif",
            fontWeight: 500,
            fontSize: "16px",
            marginRight:"3px",
            marginLeft:"2%",
            marginTop:"-30px"
          }}
        >
          <span style={{
            backgroundColor:"#1877f2",
            padding:"4px 4px",
            borderRadius:"5px",
            color:"white",
            marginLeft:'0px',
            
          }}> Top Crypto</span> Recommendations</Typography></div>          
      <div style={{ border:"1px solid #B6B6B8",margin:"0px 2%",marginTop:"4%",textAlign: "center", color: "black", fontSize: "18px", backgroundColor: "white", padding: "2% ", borderRadius: "8px" }}>
        No Crypto Recommendations at the moment.
      </div>
      </div>
    ) : (
      
      <div>
        <Typography
          variant="h6"
          component="div"
          style={{
            fontFamily: "Satoshi, sans-serif",
            fontWeight: 500,
            fontSize: "16px",
            marginRight:"3px",
            marginLeft:"2%",
            marginTop:"-30px"
          }}
        >
          <span style={{
            backgroundColor:"#1877f2",
            padding:"4px 4px",
            borderRadius:"5px",
            color:"white",
            marginLeft:'0px',
            
          }}> Top Crypto</span> Recommendations
        </Typography>
        {isPredictionDataLoaded === false  && (
            // Show skeleton loaders while data is loading
           
              // Inside your component's JSX
          
          <div
            style={{
              position: "relative", 
              border: "1px solid #B6B6B8",
              margin: "3% 2% 2% 2%",
              padding: "2%",
              borderRadius: "8px",
              textAlign: "center",
              height: "30vh",
              overflow: "hidden" // Ensures content stays within the div
            }}
          >
            {/* Blurred background image */}
            <div style={{
              backgroundImage: `url(${window.constants.asset_path}/images/default-recommend-2.png)`,
              backgroundSize: "cover",
              backgroundPosition: "center center",
              filter: "blur(5px)",
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              zIndex: 0,
            }}></div>
          
            {/* Overlay content without blur */}
            <div style={{ 
              position: "relative", 
              zIndex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}>
              <style>
                {leftRightMotion}
              </style>
              <div style={{ position: "relative", width: "64px", height: "64px" }}>
                {/* Lens image with left-right motion animation */}
                <img 
                  src={window.constants.asset_path + '/images/lens.png'}
                  alt="Searching Lens"
                  style={{
                    width: "64px", 
                    height: "64px", 
                    position: "absolute",
                    animation: `leftRightMotion 1.5s infinite ease-in-out`,
                  }}
                />
              </div>
              <p style={{ fontFamily:"Satoshi,sans-serif", marginTop: "10px", fontSize: "18px", color: "black" }}>
                <b>Searching For The Best Crypto, Personalized</b>
              </p>
            </div>
          </div>
        )}
        {isPredictionDataLoaded && allRowsIgnored ? (
        <div style={{ border:"1px solid #B6B6B8",margin:"0px 2%",marginTop:"4%",textAlign: "center", color: "black", fontSize: "18px", backgroundColor: "white", padding: "2% ", borderRadius: "8px" }}>
          No more recommendations available today.
        </div>
        ) : (
        <div style={{ border:allRowsIgnored ? "1px solid #B6B6B8" :"",fontFamily: "Satoshi, sans-serif",backgroundColor: "white", padding: "0%", borderRadius: "8px", margin:"2%", marginRight:"4%"}}> 
        {displayData.map((data, index) => (
            <React.Fragment key={index}>
            <Box
  sx={{
    overflowY: "auto",
    "&::-webkit-scrollbar": { display: "none" },
    scrollbarWidth: "none",
    filter: !userData && index >= 2 ? "blur(4px)" : "none",
    marginBottom: index === displayData.length - 1 ? "50px" : "12px",
  }}
>
  <Box
    sx={{
      padding: "0",
      background:
        "linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%)",
      borderRadius: "6px",
      marginTop: "15px",
      marginBottom: "10px",
      border: "1px solid #DADEDF",
    }}
  >
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "120px auto auto",
        alignItems: "center",
        padding: "8px 4px",
        borderBottom: "2px solid #DFDFDF",
      }}
    >
      {/* Column 1: Performance Type */}
      <span
        style={{
          fontSize: "12px",
          color: "black",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {performanceType}
      </span>

      {/* Column 2: Crypto Details */}
      <span
        style={{
          display: "flex",
          alignItems: "center",
          whiteSpace: "nowrap",
        }}
      >
        <img
          alt=""
          src={data.target_logo}
          style={{
            borderRadius: "19px",
            border: "2px solid #000",
            width: "36px",
            height: "36px",
            marginRight: "8px",
          }}
        />
        <a
          href={`${process.env.REACT_APP_HOME_URL}crypto/${data.target_name}`}
          style={{
            color: "black",
            marginLeft: "4px",
            whiteSpace: "nowrap",
            fontSize: "12px",
          }}
        >
          {data.crypto_symbol.split("-")[1]}
        </a>
      </span>

      {/* Column 3: Category */}
      <span
        style={{
          borderRadius: "15px",
        fontSize: "12px",
        fontWeight: "500",
        color: "#000",
        border:"1px solid grey",
        padding:"2px 11px",
        textAlign:"center",
        }}
      >
        {data.category}
      </span>
    </Box>
    <Box sx={{background:"#fff"}}>
    {/* Recommendation Row */}
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "120px auto 50px",
        alignItems: "center",
        padding: "8px 4px",
        borderBottom: "2px solid #DFDFDF",
      }}
    >
      {/* Column 1: Recommendation Label */}
      <span
        style={{
          fontSize: "12px",
          color: "#76787A",
          fontWeight: "500",
        }}
      >
        Recommendation:
      </span>

      {/* Column 2: Recommendation Details */}
      <span style={{ display: "flex", alignItems: "center" }}>
        <img
          alt=""
          src={data.symbol_logo}
          style={{
            borderRadius: "19px",
            border: "2px solid #000",
            width: "36px",
            height: "36px",
            marginRight: "8px",
          }}
        />
        <a
          href={`${process.env.REACT_APP_HOME_URL}crypto/${data.crypto_name}`}
          style={{ color: "black", marginLeft: "4px" , fontSize: "12px",}}
        >
          {data.crypto_symbol.split("-")[3]}
        </a>
      </span>

      {/* Column 3: Action Buttons */}
      <span
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button onClick={() => handleAddToPortfolio(data.crypto_symbol.split("-")[3])} style={{ }}>
                            <div
                              style={{
                                display: "inline-flex",
                                border: "1px solid #1877F2",
                                borderRadius: "50%",
                                padding: "3px 4px",
                                marginLeft:"12vw",
                              }}
                            >
                              <img
                                width="12px"
                                src={window.constants.asset_path + '/images/plus.svg'}
                                alt="Add"
                              />
                            </div>
                          </Button>
                          <Button 
                            onClick={() => handleIgnoreClick(index)}
                            style={{marginRight:"-5px"}}
                          >
                            <img width="20px" src={window.constants.asset_path + '/images/trash.svg'} alt="Ignore"/>
                          </Button>
    </span>
    </Box>

    {/* Price and Forecast Row */}
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        alignItems: "center",
        padding: "8px 4px",
      }}
    >
      {/* Column 1: Today's Price */}
      <span
        style={{
          fontSize: "13px",
          color: "#76787A",
          fontWeight: "500",
          marginLeft:"1%"
        }}
      >
        Today's Price: {data.current_price}
      </span>

      {/* Column 2: Forecast Price */}
      <span
        style={{
          fontSize: "13px",
          color: "#76787A",
          fontWeight: "500",
          textAlign: "right",
          marginRight:"1%"
        }}
      >
        Forecast 2025: {data.forecast_price}
      </span>
    </Box>
  </Box>
</Box>
</Box>

            </React.Fragment>
        ))}
        </div>
        )}
      </div>
    )}
    </div>
      )}
      {mediaQueryVar === false && (


<div
  style={{
    position: "fixed",
    bottom: "0",
    backgroundColor: "white",
    width: "105%",
    zIndex: "100",
    marginBottom:"0px",
    marginLeft:"-8%",
    
  }}
>
<BottomNavigation showLabels>
  <BottomNavigationAction
        label="Gallery"
        onClick={() =>
          navigate("/portfolio-gallery")
        }
        icon={<img src={loc === '/portfolio-gallery' ? "/images/dashboardS.svg" : "/images/dashboardU.svg"} />}
        
      />
      <BottomNavigationAction
        label="My Portfolios"
        onClick={() => 
          navigate("/my-portfolio")
        }
        icon={<img src={loc === '/my-portfolio' ? "/images/portfolioS.svg" : "/images/portfolioU.svg"}  />}
      />
     <BottomNavigationAction
              label="$1/Month Subscription"
              onClick={() => {
                if (userData) {
                  navigate("/subscriptions/screener360");
                } else {
                  const originalUrl = '/subscriptions/screener360';
                  sessionStorage.setItem("originalUrl", originalUrl);
                  console.log("original url: " + originalUrl);
                  handleLoginClick();
                }
              }}
              icon={
                <img
                  src={
                    
                       loc === "/subscriptions/screener360"
                        ? "/images/dollarS.svg"
                        : "/images/dollarU.svg"
                     
                  }
                  width={24}
                  height={24}
                  alt="Icon"
                  style={{ marginBottom: "-3px" }}
                />
              }
            />
</BottomNavigation>
</div>
)}






      </div>
    
  );
      
};

export default Recommendations;