import React, { useState, useEffect } from "react";

const CryptoAlerts = () => {
  const [crypto, setCrypto] = useState("");
  const [percentage, setPercentage] = useState("");
  const [alerts, setAlerts] = useState([]);
  const [cryptoList, setCryptoList] = useState([]);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [cryptoPrices, setCryptoPrices] = useState([]);
  const [notifications, setNotifications] = useState([]);

  // Sample data for cryptocurrencies and initial prices
  useEffect(() => {
    const sampleData = [
      { name: "Bitcoin", price: 50000 },
      { name: "Ethereum", price: 3000 },
      { name: "Cardano", price: 2 },
      { name: "Polkadot", price: 30 },
      { name: "Ripple", price: 1 },
      { name: "Solana", price: 100 },
    ];
    setCryptoList(sampleData.map((item) => item.name));
    setCryptoPrices(sampleData);
  }, []);

  // Simulate price updates every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCryptoPrices((prevPrices) =>
        prevPrices.map((crypto) => ({
          ...crypto,
          price: parseFloat(
            (crypto.price * (1 + (Math.random() - 0.5) * 0.01)).toFixed(2)
          ), // Randomly adjust price by ±0.5%
        }))
      );
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  // Check alerts when prices update
  useEffect(() => {
    alerts.forEach((alert) => {
      const cryptoPrice = cryptoPrices.find(
        (crypto) => crypto.name === alert.crypto
      );

      if (cryptoPrice) {
        const priceChange =
          ((cryptoPrice.price - alert.initialPrice) / alert.initialPrice) * 100;

        if (
          (alert.percentage > 0 && priceChange >= alert.percentage) ||
          (alert.percentage < 0 && priceChange <= alert.percentage)
        ) {
          // Add to notifications
          setNotifications((prev) => [
            ...prev,
            `Alert! ${alert.crypto} has changed by ${priceChange.toFixed(
              2
            )}% (${cryptoPrice.price}$)`,
          ]);
        }
      }
    });
  }, [cryptoPrices, alerts]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setCrypto(value);

    if (value.length > 1) {
      const suggestions = cryptoList.filter((name) =>
        name.toLowerCase().startsWith(value.toLowerCase())
      );
      setFilteredSuggestions(suggestions);
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  };

  const handleSuggestionClick = (name) => {
    setCrypto(name);
    setShowSuggestions(false);
  };

  const handleAddAlert = () => {
    if (!crypto || !percentage) {
      alert("Please fill out both fields.");
      return;
    }

    const initialPrice =
      cryptoPrices.find((item) => item.name === crypto)?.price || 0;

    const newAlert = {
      crypto,
      percentage: parseFloat(percentage),
      initialPrice,
    };

    setAlerts([...alerts, newAlert]);
    setCrypto("");
    setPercentage("");
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Crypto Alerts</h1>
      <div style={styles.formContainer}>
        <div style={styles.inputGroup}>
          <label style={styles.label}>Cryptocurrency</label>
          <input
            type="text"
            value={crypto}
            onChange={handleInputChange}
            placeholder="e.g., Bitcoin"
            style={styles.input}
          />
          {showSuggestions && (
            <ul style={styles.suggestionsList}>
              {filteredSuggestions.map((suggestion, index) => (
                <li
                  key={index}
                  style={styles.suggestionItem}
                  onClick={() => handleSuggestionClick(suggestion)}
                >
                  {suggestion}
                </li>
              ))}
            </ul>
          )}
        </div>
        <div style={styles.inputGroup}>
          <label style={styles.label}>Percentage Threshold</label>
          <input
            type="number"
            value={percentage}
            onChange={(e) => setPercentage(e.target.value)}
            placeholder="e.g., 10 for +10%"
            style={styles.input}
          />
        </div>
        <button style={styles.button} onClick={handleAddAlert}>
          Add Alert
        </button>
      </div>

      <h2 style={styles.subtitle}>Active Alerts</h2>
      <div style={styles.alertsContainer}>
        {alerts.length === 0 ? (
          <p style={styles.noAlerts}>No alerts created yet.</p>
        ) : (
          alerts.map((alert, index) => (
            <div key={index} style={styles.alertCard}>
              <p style={styles.alertText}>
                <strong>{alert.crypto}</strong>: {alert.percentage}% (Initial: $
                {alert.initialPrice})
              </p>
            </div>
          ))
        )}
      </div>

      <h2 style={styles.subtitle}>Triggered Alerts</h2>
      <div style={styles.notificationsContainer}>
        {notifications.length === 0 ? (
          <p style={styles.noNotifications}>No alerts triggered yet.</p>
        ) : (
          notifications.map((note, index) => (
            <div key={index} style={styles.notificationCard}>
              <p style={styles.notificationText}>{note}</p>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

const styles = {
  container: { padding: "20px", maxWidth: "600px", margin: "0 auto" },
  title: { fontSize: "24px", fontWeight: "bold", marginBottom: "20px" },
  formContainer: { display: "flex", flexDirection: "column", gap: "10px" },
  inputGroup: { display: "flex", flexDirection: "column", gap: "5px" },
  label: { fontSize: "14px", fontWeight: "bold" },
  input: {
    padding: "8px",
    fontSize: "14px",
    borderRadius: "4px",
    border: "1px solid #ccc",
    backgroundColor: "white",
    color: "black"
  },
  button: {
    padding: "10px 20px",
    fontSize: "14px",
    backgroundColor: "#007BFF",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  subtitle: { fontSize: "18px", fontWeight: "bold", marginTop: "20px" },
  alertsContainer: { marginTop: "10px" },
  alertCard: {
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    marginBottom: "10px",
    backgroundColor: "#f9f9f9",
  },
  alertText: { fontSize: "14px", fontWeight: "bold" },
  notificationsContainer: { marginTop: "20px" },
  notificationCard: {
    padding: "10px",
    border: "1px solid #d9534f",
    borderRadius: "4px",
    marginBottom: "10px",
    backgroundColor: "#ffecec",
  },
  notificationText: { fontSize: "14px", color: "#d9534f" },
  noAlerts: { fontSize: "14px", color: "#666" },
  noNotifications: { fontSize: "14px", color: "#666" },
  suggestionsList: {
    margin: "0",
    padding: "10px",
    listStyle: "none",
    background: "#fff",
    border: "1px solid #ddd",
    borderRadius: "5px",
    maxHeight: "150px",
    overflowY: "auto",
    position: "absolute",
    zIndex: 1000,
  },
  suggestionItem: {
    padding: "10px",
    cursor: "pointer",
    borderBottom: "1px solid #ddd",
  },
};

export default CryptoAlerts;
