import { Margin } from "@mui/icons-material";
import * as React from "react";
import { Box, Typography } from '@mui/material';
import { purple } from '@mui/material/colors';
import { Helmet } from 'react-helmet';

const primary = purple[510]; // #f44336
function Error(props) {


    return (
        <>
        <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection:'column',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: "black",
      }}
    >
      <Typography variant="h3" style={{ color: 'white' }}>
        404 
      </Typography>
      <Typography variant="h3" style={{ color: 'white' }}>
       Page Not Found
      </Typography>
        <div style={{marginLeft:"10px" ,color:"white"}}><a href={process.env.REACT_APP_HOME_URL }>click here </a> to goto homepage.</div>
    </Box>
        </>
    );
}

export default Error;