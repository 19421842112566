import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/material/styles";
import "./menulist.css";
import { useState } from "react";


const StyledList = styled(List)({
  "&& .Mui-selected, && .Mui-selected:hover": {
    background: "linear-gradient(90deg, #1877F2 0%, #519DFF 100%)",
    color: "white",
    fontWeight: "bold !important",
    "&, & .MuiListItemIcon-root": {
      color: "white",
      fontWeight: "bold",
      borderRadius: "10px",
    },
  },
  "& .MuiListItemButton-root:hover": {
    background: "linear-gradient(90deg, #1877F2 0%, #519DFF 100%)",
    color: "white",
    fontWeight: "bold !important",
    "&, & .MuiListItemIcon-root": {
      color: "white",
      fontWeight: "bold",
      borderRadius: "10px",
    },
  },
});



const menuItems = [
  { name: "Overview", icon: `/images/createicon.svg`, activeIcon: `/images/activecreateicon.svg`, route: "overview" },
  { name: "Creator and Investor", icon: `/images/portfolioicon.svg`, activeIcon: `/images/activeportfolioicon.svg`, route: "CreatorNInvestor" },
  { name: "How it works?", icon: `/images/accounticon.svg`, activeIcon: `/images/activeaccounticon.svg`, route: "working" },
  { name: "Tokenomics", icon: `/images/privacyicon.svg`, activeIcon: `/images/activeprivacyicon.svg`, route: "tokenomics" },
  { name: "Plan", icon: `/images/createicon.svg`, activeIcon: `/images/activecreateicon.svg`, route: "Plan" },
  { name: "Use Cases", icon: `/images/portfolioicon.svg`, activeIcon: `/images/activeportfolioicon.svg`, route: "UseCases" },
  { name: "Roadmap", icon: `/images/privacyicon.svg`, activeIcon: `/images/activeprivacyicon.svg`, route: "roadmap" },

];

function MenuListWhitepaper({ onSelectSection, selectedSection }) {
 
  return (
    <StyledList>
      <div
        className="frameWrapper_menulist"
        style={{
          display: "flex",
          alignItems: "center",
          backgroundColor: "white",
          paddingTop: "10px",
          paddingBottom: "20px",
        }}
      >
        <a href="/">
          <img
            className="frameChild_menulist"
            alt=""
            src="/images/logo_with_bg.png"
            style={{
              width: "180px",
              height: "40px",
              marginBottom: "25px",
              marginTop: "7px",
            }}
          />
        </a>
      </div>
      {menuItems.map((item, index) => (
        <ListItem
          key={index}
          button
          selected={selectedSection === item.route}
          onClick={() => {
            onSelectSection(item.route); // Handle section change
          }}
          style={{
            borderRadius: selectedSection === item.route ? "10px" : "0",
            marginLeft: "25px",
            backgroundColor:
              selectedSection === item.route
                ? "linear-gradient(90deg, #1877F2 0%, #519DFF 100%)"
                : "transparent",
          }}
        >
          <ListItemIcon>
            <img
              src={selectedSection === item.route ? item.activeIcon : item.icon}
              alt={item.name}
              style={{
                marginLeft: "10px",
                marginRight: "0px",
                color: selectedSection === item.route ? "white" : "black",
              }}
            />
          </ListItemIcon>
          <ListItemText
            primary={item.name}
            style={{
              fontSize: "14px",
              fontWeight: "600",
              color: selectedSection === item.route ? "white" : "black",
              fontFamily:"Satoshi, sans-serif",
              marginLeft: "2px",
              letterSpacing: "0.7px",
            }}
          />
        </ListItem>
      ))}
    </StyledList>
  );
}

export default MenuListWhitepaper;
