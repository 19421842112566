import "./account.css";
import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import { EditText } from "react-edit-text";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Helmet } from 'react-helmet';
import { useState } from "react";
import { Button, Grid,Dialog, DialogActions, DialogContent, DialogTitle, TextField, Card, CardContent } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { useNavigate } from "react-router-dom";
import { updateAccount } from "../../apihelper/profile";
import SidebarMenuList from "../../includes/sidebarMenuList";
import {
getPortfolioForGallery} from "../../apihelper/homepage";
import { useEffect } from "react";
import LoginPopUp from '../loginpopup';

import useMediaQuery from "@mui/material/useMediaQuery";
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { BottomNavigation,Paper,Chip } from "@mui/material";
import { getInfluencerData } from "../../apihelper/portfolio";

import json2mq from "json2mq";
import { useParams } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import {
  getPortfolioForGalleryList
} from "../../apihelper/homepage";



const drawerWidth = 292;

function InfluencerAccount(props) {

  const { influencerName } = useParams();
  const minfluencerName = influencerName ? influencerName.replace(/-/g, ' ') : '';

  const navigate = useNavigate();
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
  const handleLoginClick = () => {
    // Mask the URL by adding /login at the end without navigating
    window.history.pushState({}, "", "/login");
    localStorage.setItem("isPopupManuallyOpened", "true");
    // Open the login modal
    setLoginModalOpen(true);
  };
  const handleModalClose = () => {
    // Close the modal and reset the URL to the welcome page
    setLoginModalOpen(false);
    navigate(location.pathname, { replace: true });
  };
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [redirectpage, setRedirectpage] = useState(false);
  const [snackbaropen, setSnackbaropen] = React.useState(false);
  const [severity, setSeverity] = React.useState("info");
  const [snackbarmessage, setSnackbarmessage] = React.useState("Loading...");
  const [fullName, setFullName] = React.useState("");
  const [profilePhoto, setProfilePhoto] = React.useState();
  const [userLocalData, setUserLocalData] = useState(undefined);
  const [gallarydata, setGallarydata] = useState([]);
  const [predictionsdata, setpredictiondata] = useState(false);
  const [portfoliodata, setportfoliodata] = useState(false);
  const location = useLocation(); 
  const loc = location.pathname;


  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div className="d-flex flex-column h-100" style={{ overflowY: "hidden" }}>
      <SidebarMenuList />
    </div>
  );

  const mediaQueryVar = useMediaQuery(
    json2mq({
      minWidth: 900,
    })
  );


  const [youtubeLink, setYoutubeLink] = useState('');
  const [twitterLink, setTwitterLink] = useState('');
  const [telegramLink, setTelegramLink] = useState('');
  const [instagramLink, setInstagramLink] = useState('');
  const [description, setDescription] = useState('');
  const [name, setName] = useState('');
  const [role, setRole] = useState('guest');
  const [profilepic, setProfilePic] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [showPortfolioPopup, setShowPortfolioPopup] = useState(false);
  
  const container =
    window !== undefined ? () => window().document.body : undefined;

    const [loading, setLoading] = useState(true);

    useEffect(() => {
      const fetchGalleryData = async () => {
        try {
          // Fetch user data
          const userRes = await getInfluencerData({
            portfolio_id: minfluencerName,
          });
  
          if (userRes.code === "200") {
            console.log("User data fetched successfully: ", userRes.data);
            const userData = userRes.data[0];
            setName(userData.name);
            setDescription(userData.description);
            setProfilePic(userData.photo);
            setYoutubeLink(userData.youtube);
            setInstagramLink(userData.instagram);
            setTelegramLink(userData.telegram);
            setTwitterLink(userData.twitter);
            setRole(userData.role);
            setGallarydata(userRes.data); // Update gallery data
          } else {
            console.log(userRes.message);
          }
        } catch (error) {
          console.error("Error fetching gallery data: ", error);
        } finally {
          setLoading(false); // Stop loading after fetching
        }
      };
  
      fetchGalleryData();
    }, [minfluencerName]);
  
    // useEffect for fetching portfolio and predictions data
    useEffect(() => {
      const fetchPortfolioAndPredictionData = async () => {
        try {
          // Fetch portfolio data for gallery list
          const portfolioRes = await getPortfolioForGalleryList({
            portfolioName: minfluencerName,
            start_index: 0,
            limit: 7,
          });
  
          let portfolioDataExists = false;
          if (portfolioRes.code === "200" && portfolioRes.data.length > 0) {
            portfolioDataExists = true;
          } else {
            console.log("Portfolio data fetching error or no data available");
          }
  
          // Fetch crypto future data
          const response = await fetch(
            "https://crowdwisdom.live/wp-json/api/v1/showCryptoFutureDataInvesting",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                forecasterName: minfluencerName,
                sortType: "desc",
              }),
            }
          );
          const result = await response.json();
  
          let predictionDataExists = false;
          if (result.code === "200") {
            console.log("Data retrieved successfully");
            if (result.response.length > 0) {
              predictionDataExists = true;
            }
          } else {
            console.log("Data fetching error: Invalid response code");
          }
  
          // Update states for portfolio and prediction data
          setportfoliodata(portfolioDataExists);
          setpredictiondata(predictionDataExists);
        } catch (error) {
          console.error("Error fetching portfolio or prediction data: ", error);
        }
      };
  
      fetchPortfolioAndPredictionData();
    }, [minfluencerName]);
    
    

  if (redirectpage) {
    return navigate("/");
  }

  console.log("gallary data: ",gallarydata);

  const handleButtonClick = () => {
    if (predictionsdata) {
      // Navigate if predictions data is true
      navigate("../../influencer/" + name.replace(/\s+/g, '-') + "/predictions");
    } else {
      // Show pop-up if no predictions are found
      setShowPopup(true);
    }
  };

  const closePopup = () => {
    setShowPopup(false); // Close the pop-up
  };

  const handlePortfolioButtonClick = () => {
    if (portfoliodata) {
      // Navigate if predictions data is true
      navigate("../../influencer/" + name.replace(/\s+/g, '-') + "/portfolios");
    } else {
      // Show pop-up if no predictions are found
      setShowPortfolioPopup(true);
    }
  };

  const closePortfolioPopup = () => {
    setShowPortfolioPopup(false); // Close the pop-up
  };

  return (
    <>
    {showPopup && (
        <div style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          padding:"30px",
          alignItems: "center",
          textAlign:"center",
          borderRadius: "10px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          zIndex: 1000
        }}>
          <p style={{ margin: 0,
          fontWeight: "700",
          fontSize:"20px" 

          }}>No predictions found.</p>
          <Button
            variant="contained"
            style={{
              marginTop: "20px",
              backgroundColor: "#1877F2",
              color: "white",
              borderRadius: "5px",
             
            }}
            onClick={closePopup}
          >
            Close
          </Button>
        </div>
      )}
      {showPortfolioPopup && (
        <div style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          padding: "30px",
          alignItems: "center",
          textAlign:"center",
          borderRadius: "10px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          zIndex: 1000
        }}>
         <p style={{ margin: 0,
          fontWeight: "700",
          fontSize:"20px" 

          }}>No Portfolios found.</p>
          <Button
            variant="contained"
            style={{
              marginTop: "20px",
              backgroundColor: "#1877F2",
              color: "white",
              borderRadius: "5px",
            
            }}
            onClick={closePortfolioPopup}
          >
            Close
          </Button>
        </div>
      )}

    {mediaQueryVar === true? (
    <Box sx={{ display: "flex", flexWrap: "wrap", backgroundColor:"#EDF1F6" }} className="main_account">
      <Helmet>
        <title>Crypto Influencer {name} Profile</title>
      </Helmet>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "white",
              color: "#727376",
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "white",
              color: "#727376",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <div
          style={{
            backgroundColor: "#EDF1F6",
            padding: "5px",
            borderRadius: "10px",
          }}
        >
          <Box sx={{ flexGrow: 1, padding: "2px" }} >
            <Grid container spacing={2}>
              <Grid item xs={12} align="left">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "0px",
                    marginBottom: "10px",
                    marginLeft: "5px",
                  }}
                >
                 
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="end"
                    onClick={handleDrawerToggle}
                    sx={{ display: { sm: "none" } }}
                  >
                    <img
                      src="/images/menubar.svg"
                      alt="Menu Icon"
                      style={{
                        width: "24px",
                        height: "24px",
                        marginRight: "10px",
                        marginTop: "-20px",
                      }}
                    />
                  </IconButton>
                </div>
              </Grid>
            </Grid>
          </Box>

          <Box
           
            sx={{
              "& .MuiTextField-root": { m: 1 },
            }}
            noValidate
            autoComplete="off"
           
          >
            
            <Grid container spacing={2}>
             

              <Box style ={{
 backgroundColor:"white",
 marginTop:"18px",
 marginLeft:"18px",
 marginRight:"18px",
 padding:"32px",
 borderRadius:"10px",
 width:"100%",
 marginBottom:"20px"
}}>
<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
  <div style={{
    display: "inline-flex",
    gap: "20px",
    padding: "5px 2px",
    borderRadius: "38px",
    paddingRight: "20px",
    marginBottom: "20px",
    background: "linear-gradient(90deg, #EEEEEE 0%, #F6F6F6 100%)"
  }}>
    {profilepic ? (
      <img src={profilepic} alt="profile_pic" style={{
        width: "42px",
        height: "42px",
        position: "relative",
        borderRadius: "33px",
        border: "2px black solid"
      }} />
    ) : (
      <img src="/images/avtar.jpeg" alt="profile_pic" style={{
        width: "42px",
        height: "42px",
        position: "relative",
        borderRadius: "33px",
        border: "2px black solid"
      }} />
    )}

    <div style={{ justifyContent: "center", alignItems: "center", gap: "5px", display: "flex" }}>
      <div style={{
        color: "black",
        fontSize: "20px",
        fontWeight: "600",
        marginLeft: "-5px",
        fontFamily: "Satoshu, sans-serif",
        wordWrap: "wordWrap"
      }}>{minfluencerName}</div>
    </div>
  </div>

  <div style={{ display: "flex", alignItems: "center" }}>
   
      <Button
        variant="outlined"
        style={{
          backgroundColor: "white",
          color: "#1877F2",
          borderColor: "#1877F2",
          borderWidth: "2px",
          borderStyle: "solid",
          borderRadius: "27px",
          textTransform: "none",
          fontFamily: "Satoshi, sans-serif",
          fontSize: "14px",
          fontWeight: "700",
          padding: "3px 14px",
          maxHeight: "40px",
          whiteSpace: "nowrap",
          marginRight: "10px",
        }}
          onClick={handleButtonClick}
          onMouseDown={(e) => {
          e.currentTarget.style.backgroundColor = "#1877F2";
          e.currentTarget.style.color = "white";
        }}
        onMouseUp={(e) => {
          e.currentTarget.style.backgroundColor = "white";
          e.currentTarget.style.color = "#1877F2";
        }}
      >
        Predictions
      </Button>
    

  
      <Button
        variant="outlined"
        style={{
          backgroundColor: "white",
          color: "#1877F2",
          borderColor: "#1877F2",
          borderWidth: "2px",
          borderStyle: "solid",
          borderRadius: "27px",
          textTransform: "none",
          fontFamily: "Satoshi, sans-serif",
          fontSize: "14px",
          fontWeight: "700",
          padding: "3px 14px",
          maxHeight: "40px",
          whiteSpace: "nowrap",
        }}
        onClick={handlePortfolioButtonClick}        
        onMouseDown={(e) => {
          e.currentTarget.style.backgroundColor = "#1877F2";
          e.currentTarget.style.color = "white";
        }}
        onMouseUp={(e) => {
          e.currentTarget.style.backgroundColor = "white";
          e.currentTarget.style.color = "#1877F2";
        }}
      >
        Portfolios
      </Button>
    
  </div>
</div>

        <div style={{width:"100%",border:"1px solid #EEEEEE",height:"1px",marginBottom:"20px"}}></div>

             

              
  <div style={{ marginBottom: '15px' }}>
      <div>
        <Typography
          style={{
            display: 'inline-flex',
            fontSize: '18px',
            fontWeight: '600',
            fontFamily: 'Satoshi, sans-serif',
          }}
        >
          Description
        </Typography>
        <div style={{
            color: '#7F7F80',
            fontSize: '16px',
            fontWeight: '500',
            fontFamily: 'Satoshi, sans-serif',
            wordWrap: 'break-word',
            marginTop: '10px',
        }}>
            {description}
        </div>

      </div>
    </div>

    </Box>

    <Box style ={{
 backgroundColor:"white",
 marginTop:"10px",
 marginLeft:"18px",
 marginRight:"18px",
 padding:"32px",
 borderRadius:"10px",
 width:"100%"
}}>
              

    <div style={{ marginBottom: "10px" }}>
      <div>
        <Typography
          style={{
            fontSize: "17px",
            color: "black",
            fontWeight: "600",
            fontFamily: "Satoshi, sans-serif",
          }}
        >
          Tags
        </Typography>
        <div>
          <div
            className="wrapper1"
            style={{ marginTop: "10px", display: "flex", flexWrap: "wrap" }}
          >
            {loading ? (
              <div>Loading...</div>
            ) : (
              gallarydata.length > 0 &&
              gallarydata[0].slugs.map((slug, index) => (
                <div
                  key={index}
                  className={`tag${index + 1}`}
                  style={{
                    gap: "20px",
                    display: "flex",
                    padding: "5px 2px",
                    borderRadius: "38px",
                    paddingRight: "5px",
                    marginBottom: "10px",
                    background: "#F7F7F7",
                    marginRight: "10px",
                    justifyContent: "space-between",
                    flexWrap: "nowrap",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      color: "black",
                      fontSize: "16px",
                      fontWeight: "500",
                      marginLeft: "20px",
                      whiteSpace: "nowrap",
                      marginTop: "0px",
                    }}
                  >
                    {slug.category}
                  </div>
                  <div
                    style={{
                      padding: "4px 12px",
                      borderRadius: "48px",
                      backgroundColor: "#1877F2" ,
                      color: "#FFF",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    <img
                      src="/images/tag.svg"
                      style={{
                        width: "22px",
                        height: "22px",
                        marginBottom: "2px",
                      }}
                      alt="Remove"
                    />
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>

             
                <div>
                  <div>
                    <Typography style={{
                      fontSize:"17px",
                      color:"black",
                      fontWeight:"600",
                      fontFamily:"Satoshi,sans-serif"
                    }}>Social Profile</Typography>
                    <div>

                    
                    <div className="wrapper1" style={{ marginTop: "10px", display: "flex", flexWrap: "wrap" }}>
      {/* YouTube Block */}
      <div>
        <img src="/images/youtube.svg" width="32px" height="32px"
          style={{ border: "1.29px solid black", borderRadius: "50%", padding: "5px", marginRight: "5px", marginTop: "5px" }} />
      </div>
      <div className="socialBtn1" style={{
         width: "200px", gap: "30px", display: "inline-flex", padding: "8px 10px", borderRadius: "38px", paddingRight: "5px",
         marginBottom: "10px", background: "#F7F7F7", marginRight: "10px", justifyContent: "space-between",
         whiteSpace:"nowrap", overflow:"hidden", color: '#7F7F80',
       }}>
        
      <a href={youtubeLink} target="_blank" style={{
        textDecoration :"none",
        color: '#7F7F80',
      }}>{youtubeLink? youtubeLink : "Not Provided"}</a>
      </div>
    

      {/* Twitter Block */}
      <div>
        <img src="/images/twitter.svg" width="32px" height="32px"
          style={{ border: "1.29px solid black", borderRadius: "50%", padding: "5px", marginRight: "5px", marginTop: "5px" }} />
      </div>
      <div className="socialBtn2" style={{
        width: "200px", gap: "30px", display: "inline-flex", padding: "8px 12px", borderRadius: "38px", paddingRight: "5px",
        marginBottom: "10px", background: "#F7F7F7", marginRight: "10px", justifyContent: "space-between",whiteSpace:"nowrap", overflow:"hidden",color: '#7F7F80'
      }}>
         <a href={twitterLink} target="_blank" style={{
        textDecoration :"none",
        color: '#7F7F80',
      }}>{twitterLink? twitterLink:"Not Provided"}</a>
       
      </div>

      {/* Telegram Block */}
      <div>
        <img src="/images/telegram.svg" width="32px" height="32px"
          style={{ border: "1.29px solid black", borderRadius: "50%", padding: "5px", marginRight: "5px", marginTop: "5px" }} />
      </div>
      <div className="socialBtn3" style={{
        width: "200px", gap: "30px", display: "inline-flex", padding: "8px 12px", borderRadius: "38px", paddingRight: "5px",
        marginBottom: "10px", background: "#F7F7F7", marginRight: "10px", justifyContent: "space-between",whiteSpace:"nowrap", overflow:"hidden",color: '#7F7F80',
      }}>

<a href={telegramLink} target="_blank" style={{
        textDecoration :"none",
        color: '#7F7F80',
      }}>{telegramLink? telegramLink:"Not Provided"}</a>
       
      </div>

      {/* Instagram Block */}
      <div>
        <img src="/images/instagram.svg" width="32px" height="32px"
          style={{ border: "1.29px solid black", borderRadius: "50%", padding: "5px", marginRight: "5px", marginTop: "5px" }} />
      </div>
      <div className="socialBtn4" style={{
        width: "200px", gap: "30px", display: "inline-flex", padding: "8px 12px", borderRadius: "38px", paddingRight: "5px",
        marginBottom: "10px", background: "#F7F7F7", justifyContent: "space-between", marginRight: "-31px",whiteSpace:"nowrap", overflow:"hidden",color: '#7F7F80',
      }}>

<a href={instagramLink} target="_blank" style={{
        textDecoration :"none",
        color: '#7F7F80',
      }}>{instagramLink ? instagramLink : "Not Provided"}</a>
       
      </div>
    </div>

                  </div>
                </div>
              </div>
             
              </Box>




  <div style={{ display: "flex", flexWrap: "wrap", gap: "15px", justifyContent: "space-between", marginTop: "30px", marginLeft: "20px", width: "100%",marginRight:"20px" }}>
  

  <div style={{
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "5px 14px",
    borderRadius: "10px",
    background: "white",
    boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.06)",
    alignItems: "center",
    width: "360px",
    minHeight: "90px",
    height: "90px",
    border: "1px solid #E4E4E4"
  }}>
    <div style={{ display: "flex", alignItems: "center" }}>
      <img src="/images/watchlist.svg" alt="Watchlist" style={{  marginRight: "30px",marginLeft:"10px" }} />
      <Typography style={{
        color: "black",
        fontSize: "18px",
        whiteSpace: "nowrap"
      }}>
        Go To Watchlist
      </Typography>
    </div>
    <img src="/images/go.svg" alt="Go" style={{  marginRight: "15px"}} />
  </div>
</div>

              <div style={{
                marginLeft:"20px",
                marginTop:"20px",
                width:"100%",
                marginRight:"20px"
              }}>
                <Typography style={{
                  marginBottom:"10px",
                  fontSize:"24px",
                  fontWeight:"600"
                }}>Milestone</Typography>
                <div style={{ display: "flex", flexWrap: "wrap", gap: "15px", justifyContent: "space-between" }}>
  <div class="milestone1" style={{
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    padding: "5px 14px",
    borderRadius: "12px",
    backgroundColor: "white",
    boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.06)",
    borderRadius: "14px",
    alignItems: "center",
    flex: "1 1 calc(25% - 20px)"
  }}>
    <div style={{ display: "flex", alignItems: "center" }}>

    <div style={{ marginRight: "10px", position: "relative" }}>
        <img src="/images/milestone1.svg" alt="Milestone1" style={{
          
          width: "76px",  // Adjust width as necessary
          height: "76px", // Adjust height as necessary
          clipPath: "circle(33% at 50% 50%)",
        }}></img>
        <img src="/images/lock.svg" alt="Lock" style={{ position: "absolute", top: 20, left: 25 }}></img>
      </div>
      
      <Typography style={{
        borderRadius: "10px",
        padding: "10px 26px",
        background: "rgba(226.08, 236, 112, 0.14)",
        color: "#A87727",
        fontSize: "12px",
        whiteSpace: "nowrap"
      }}>
        Yet to complete
      </Typography>
    </div>
  </div>

  <div class="milestone2" style={{
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    padding: "5px 14px",
    borderRadius: "12px",
    backgroundColor: "white",
    boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.06)",
    borderRadius: "14px",
    alignItems: "center",
    flex: "1 1 calc(25% - 20px)"
  }}>
    <div style={{ display: "flex", alignItems: "center" }}>
      <div style={{ marginRight: "10px", position: "relative" }}>
        <img src="/images/milestone2.svg" alt="Milestone2" style={{ display: "block" }}></img>
        <img src="/images/lock.svg" alt="Lock" style={{ position: "absolute", top: 20, left: 25 }}></img>
      </div>
      <Typography style={{
        borderRadius: "10px",
        padding: "10px 26px",
        background: "rgba(226.08, 236, 112, 0.14)",
        color: "#A87727",
        fontSize: "12px",
        whiteSpace: "nowrap"
      }}>
        Yet to complete
      </Typography>
    </div>
  </div>

  <div class="milestone3" style={{
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    padding: "5px 14px",
    borderRadius: "12px",
    background: "white",
    boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.06)",
    borderRadius: "14px",
    alignItems: "center",
    flex: "1 1 calc(25% - 20px)"
  }}>
    <div style={{ display: "flex", alignItems: "center" }}>
      <div style={{ marginRight: "10px", position: "relative" }}>
        <img src="/images/milestone3.svg" alt="Milestone3" style={{ display: "block" }}></img>
        <img src="/images/lock.svg" alt="Lock" style={{ position: "absolute", top: 20, left: 25 }}></img>
      </div>
      <Typography style={{
        borderRadius: "10px",
        padding: "10px 26px",
        background: "rgba(226.08, 236, 112, 0.14)",
        color: "#A87727",
        fontSize: "12px",
        whiteSpace: "nowrap"
      }}>
        Yet to complete
      </Typography>
    </div>
  </div>

  <div class="milestone4" style={{
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    padding: "5px 14px",
    borderRadius: "12px",
    backgroundColor: "white",
    boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.06)",
    borderRadius: "14px",
    alignItems: "center",
    flex: "1 1 calc(25% - 20px)"
  }}>
    <div style={{ display: "flex", alignItems: "center" }}>
      <div style={{ marginRight: "10px", position: "relative" }}>
        <img src="/images/milestone4.svg" alt="Milestone4" style={{ display: "block" }}></img>
        <img src="/images/lock.svg" alt="Lock" style={{ position: "absolute", top: 20, left: 25 }}></img>
      </div>
      <Typography style={{
        borderRadius: "10px",
        padding: "10px 26px",
        background: "rgba(226.08, 236, 112, 0.14)",
        color: "#A87727",
        fontSize: "12px",
        whiteSpace: "nowrap"
      }}>
        Yet to complete
      </Typography>
    </div>
  </div>
</div>

                </div>
              

              <Grid item xs={12} style={{marginLeft:"5px"}}>
      <Typography style={{ fontSize: "24px", fontWeight: "600", marginBottom:"10px" }}>Transaction History</Typography>
      <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Card style={{
          display: "flex",
          flexDirection: "row",
          borderRadius: "24px",
          boxShadow: "0px 4px 20px -10px rgba(0, 0, 0, 0.10)",
          marginRight: "10px"
        }}>
          <CardContent style={{ flex: "1", textAlign: 'left' }}>
            <Typography style={{
              fontSize: "32px",
              fontWeight: "700",
              marginBottom:"20px"
            }}>0</Typography>
            <Typography variant="body2" color="textSecondary">Total Received in Farming</Typography>
          </CardContent>
          <img src="/images/handcoin.png" alt="Hand with coins" width="149px" height="130px" style={{ alignSelf: 'center', marginRight: '0px' }} />
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card style={{
          display: "flex",
          flexDirection: "row",
          borderRadius: "24px",
          boxShadow: "0px 4px 20px -10px rgba(0, 0, 0, 0.10)",
          marginRight: "10px"
        }}>
          <CardContent style={{ flex: "1", textAlign: 'left' }}>
            <Typography style={{
              fontSize: "32px",
              fontWeight: "700",
              marginBottom:"20px"
            }}>0</Typography>
            <Typography variant="body2" color="textSecondary">Buy Back History</Typography>
          </CardContent>
          <img src="/images/clockcoin.png" alt="Hand with coins" width="149px" height="130px" style={{ alignSelf: 'center', marginRight: '0px' }} />
        </Card>
      </Grid>
      </Grid>
    </Grid>

            </Grid>
          </Box>
        </div>
       
      </Box>
    </Box>
    ):(
      <Box
sx={{
display: "flex",
flexDirection: "column",
minHeight: "100vh", // Ensures it takes at least the full viewport height
flexGrow: 1, // Allows the Box to grow and fill the available space
overflowY: "auto", 

}}
>
<Helmet>
        <title>Crypto Influencer {name} Profile</title>
</Helmet>
<CssBaseline />

<Box
component="nav"
sx={{
  width: "100%", // Take full width on mobile
}}
aria-label="mailbox folders"
>
<Drawer
  container={container}
  variant="temporary"
  open={mobileOpen}
  onClose={handleDrawerToggle}
  ModalProps={{
    keepMounted: true,
  }}
  sx={{
    "& .MuiDrawer-paper": {
      width: "80%", // Adjust width for mobile
      backgroundColor: "white",
      color: "#727376",
    },
  }}
>
  {drawer}
</Drawer>
</Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          paddingTop: 1,
          paddingBottom: 3,
          paddingLeft: 1,
          paddingRight: 0,
          backgroundColor: "white",
          paddingBottom: "19vw",
          marginRight: "6px",
          overflow: "auto", // Allow scrolling
        }}
      >
        <div
          style={{
            backgroundColor: "#FFF",
           
          }}
        >
          <Box sx={{ flexGrow: 1, padding: "2px" }} >
            <Grid container spacing={2}>
              <Grid item xs={12} align="left">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: "0px",
                    marginBottom: "10px",
                    marginLeft: "5px",
                  }}
                >
               
                </div>
              </Grid>
            </Grid>
          </Box>

          <Box
           
            sx={{
              "& .MuiTextField-root": { m: 1 },
            }}
            noValidate
            autoComplete="off"
            
          >
            
            <Grid container spacing={2}>
             

<Box style ={{
 backgroundColor:"white",
 marginTop:"0px",
 marginLeft:"5px",
 marginRight:"0px",
 padding:"10px",
 borderRadius:"17px",
 border:"1px solid #b6b6b8",
 width:"100%",
 margin:"6px",
 boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.06)",
 marginLeft:"18px"
}}>
<div style={{
 display:"flex",
 justifyContent:"space-between",


}}>
<div style={{
  gap: "20px",
  display: "inline-flex",
  padding:"5px 2px",
  borderRadius:"38px",
  paddingRight:"20px",
  marginBottom:"20px",
  background: "linear-gradient(90deg, #EEEEEE 0%, #F6F6F6 100%)"
  }}>

{profilepic ?(
  <img src={profilepic} alt ="profile_pic" style={{
    width: "42px", 
    height: "42px", 
    position: "relative", 
    borderRadius: "33px",
    border: "2px black solid"}}
     ></img>
):(
  <img src="/images/avtar.jpeg" alt ="profile_pic" style={{
    width: "42px", 
    height: "42px", 
    position: "relative", 
    borderRadius: "33px",
    border: "2px black solid"}}
     ></img>
)}

<div style={{
  justifyContent: "center", 
  alignItems: "center", 
  gap: "5px",
   display: "flex"
   }}>
<div style={{
  color: "black",
   fontSize: "18px",
   fontWeight: "600",
   marginLeft:"-5px",
   fontFamily:"Satoshi,sans-serif",
    wordWrap: "wordWrap"
    }}>
      {minfluencerName}
      </div>
</div>
</div>
<IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="end"
                    onClick={handleDrawerToggle}
                    sx={{ display: { sm: "none" } }}
                  >
                    <img
                      src="/images/menubar.svg"
                      alt="Menu Icon"
                      style={{
                        width: "24px",
                        height: "24px",
                        marginRight: "6px",
                        marginTop: "-20px",
                      }}
                    />
                  </IconButton>
</div>

        

        <div style={{width:"100%",border:"1px solid #EEEEEE",height:"1px",marginBottom:"20px"}}></div>

              
              
  <div style={{ marginBottom: '15px' }}>
      <div>
        <Typography
          style={{
            display: 'inline-flex',
            fontSize: '18px',
            fontWeight: '600',
            marginLeft:'5px',
            fontFamily: 'Satoshi, sans-serif',
          }}
        >
         <span style={{fontSize:"18px"}}>Description</span> 
        </Typography>
        <div style={{
            color: '#7F7F80',
            fontSize: '16px',
            fontWeight: '500',
            fontFamily: 'Satoshi, sans-serif',
            wordWrap: 'break-word',
            marginTop: '10px',
            marginLeft:'5px',
        }}>
            {description}
        </div>

        
      </div>
    </div>
              
</Box>
<Box style ={{
 backgroundColor:"white",
 marginTop:"15px",
 marginLeft:"5px",
 marginRight:"0px",
 padding:"10px",
 borderRadius:"17px",
 border:"1px solid #b6b6b8",
 width:"100%",
 boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.06)",
 margin:"10px",
 marginBottom:"-5px",
 marginLeft:"20px"
}}>

    <div style={{ marginBottom: "10px" }}>
      <div>
        <Typography
          style={{
            fontSize: "17px",
            color: "black",
            fontWeight: "600",
            fontFamily: "Satoshi, sans-serif",
          }}
        >
       <span style={{fontSize:"18px"}}>Tags</span>
        </Typography>
        <div>
          <div
            className="wrapper1"
            style={{ marginTop: "10px", display: "flex", flexDirection:"column",flexWrap: "wrap" }}
          >
            {loading ? (
              <div>Loading...</div>
            ) : (
              gallarydata.length > 0 &&
              gallarydata[0].slugs.map((slug, index) => (
                <div
                  key={index}
                  className={`tag${index + 1}`}
                  style={{
                    gap: "20px",
                    display: "flex",
                    minWidth:"30px",
                    padding: "5px 2px",
                    borderRadius: "38px",
                    paddingRight: "5px",
                    marginBottom: "10px",
                    background: "#F7F7F7",
                    marginRight: "10px",
                    justifyContent: "space-between",
                    flexWrap: "nowrap",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      color: "black",
                      fontSize: "16px",
                      fontWeight: "500",
                      marginLeft: "20px",
                      whiteSpace: "nowrap",
                      marginTop: "0px",
                    }}
                  >
                    {slug.category}
                  </div>
                  <div
                    style={{
                      padding: "4px 12px",
                      borderRadius: "48px",
                      backgroundColor: "#1877F2" ,
                      color: "#FFF",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    <img
                      src="/images/tag.svg"
                      style={{
                        width: "22px",
                        height: "22px",
                        marginBottom: "2px",
                      }}
                      alt="Remove"
                    />
                  </div>
                </div>
              ))
            )}
          </div>
          
        </div>
        
      </div>
    </div>
             

             
                <div>
                  <div>
                    <Typography style={{
                      fontSize:"17px",
                      color:"black",
                      fontWeight:"600",
                      marginLeft:"5px",
                      fontFamily:"Satoshi,sans-serif"
                    }}>
                      
                      <span style={{fontSize:"18px"}}>Social Profile</span></Typography>
                    <div>

                    
    <div className="wrapper1" style={{ marginTop: "10px", display: "flex", flexWrap: "wrap" }}>
      {/* YouTube Block */}
      <div style={{marginLeft:"8px"}}>
        <img src="/images/youtube.svg" width="32px" height="32px"
          style={{ border: "1.29px solid black", borderRadius: "50%", padding: "5px", marginRight: "5px", marginTop: "5px" }} />
      </div>
      <div className="socialBtn1" style={{
         width: "80%", gap: "30px", display: "inline-flex", padding: "5px 12px", borderRadius: "38px", paddingRight: "5px",
         marginBottom: "10px", background: "#F7F7F7", marginRight: "10px", justifyContent: "space-between",whiteSpace:"nowrap",overflow:"hidden"
       }}>
       <a href={youtubeLink} target="_blank" style={{
        textDecoration :"none",
        color: '#7F7F80',
      }}>{youtubeLink ? youtubeLink: "Not Provided"}</a>
      </div>
   

      {/* Twitter Block */}
      <div style={{marginLeft:"8px"}}>
        <img src="/images/twitter.svg" width="32px" height="32px"
          style={{ border: "1.29px solid black", borderRadius: "50%", padding: "5px", marginRight: "5px", marginTop: "5px" }} />
      </div>
      <div className="socialBtn2" style={{
        width: "80%", gap: "30px", display: "inline-flex", padding: "5px 12px", borderRadius: "38px", paddingRight: "5px",
        marginBottom: "10px", background: "#F7F7F7", marginRight: "10px", justifyContent: "space-between",whiteSpace:"nowrap",overflow:"hidden"
      }}>
        <a href={twitterLink} target="_blank" style={{
        textDecoration :"none",
        color: '#7F7F80',
      }}>{twitterLink ? twitterLink:"Not Provided"}</a>
      </div>

      {/* Telegram Block */}
      <div style={{marginLeft:"8px"}}>
        <img src="/images/telegram.svg" width="32px" height="32px"
          style={{ border: "1.29px solid black", borderRadius: "50%", padding: "5px", marginRight: "5px", marginTop: "5px" }} />
      </div>
      <div className="socialBtn3" style={{
        width: "80%", gap: "30px", display: "inline-flex", padding: "5px 12px", borderRadius: "38px", paddingRight: "5px",
        marginBottom: "10px", background: "#F7F7F7", marginRight: "10px", justifyContent: "space-between",whiteSpace:"nowrap",overflow:"hidden"
      }}>
       <a href={telegramLink} target="_blank" style={{
        textDecoration :"none",
        color: '#7F7F80',
      }}>{telegramLink? telegramLink:"Not Provided"}</a>
      </div>

      {/* Instagram Block */}
      <div style={{marginLeft:"8px"}}>
        <img src="/images/instagram.svg" width="32px" height="32px"
          style={{ border: "1.29px solid black", borderRadius: "50%", padding: "5px", marginRight: "5px", marginTop: "5px" }} />
      </div>
      <div className="socialBtn4" style={{
        width: "80%", gap: "30px", display: "inline-flex", padding: "5px 12px", borderRadius: "38px", paddingRight: "5px",
        marginBottom: "10px", background: "#F7F7F7", justifyContent: "space-between", marginRight: "-31px",whiteSpace:"nowrap",overflow:"hidden"
      }}>
        <a href={instagramLink} target="_blank" style={{
        textDecoration :"none",
        color: '#7F7F80',
      }}>{instagramLink ? instagramLink : "Not Provided"}</a>
       
      </div>
    </div>

                  </div>
                </div>
              </div>
             
              </Box>




  <div style={{ display: "flex", flexWrap: "wrap", gap: "15px", justifyContent: "space-between", marginTop: "30px", marginLeft: "20px", width: "100%",marginRight:"20px" }}>
  
  <div style={{
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "5px 14px",
    borderRadius: "10px",
    background: "white",
    boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.06)",
    alignItems: "center",
    width: "330px",
    minHeight: "80px",
    height: "80px",
    marginRight:"10px",
    border: "1px solid #E4E4E4"
  }}>
    <div style={{ display: "flex", alignItems: "center" }}>
      <img src="/images/watchlist.svg" alt="Watchlist" style={{  marginRight: "30px",marginLeft:"10px" }} />
      <Typography style={{
        color: "black",
        fontSize: "18px",
        whiteSpace: "nowrap"
      }}>
        Go To Watchlist
      </Typography>
    </div>
    <img src="/images/go.svg" alt="Go" style={{  marginRight: "15px"}} />
  </div>
</div>


              <div style={{
                marginLeft:"20px",
                marginTop:"20px",
                width:"100%",
                marginRight:"20px"
              }}>
                <Typography style={{
                  marginBottom:"10px",
                  fontSize:"20px",
                  fontWeight:"700"
                }}>
                  <span style={{fontSize:"18px"}}>Milestone</span></Typography>
                <div style={{ display: "flex", flexWrap: "wrap", gap: "15px", justifyContent: "space-between" }}>
  <div class="milestone1" style={{
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    padding: "5px 14px",
    borderRadius: "12px",
    backgroundColor: "white",
    boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.06)",
    borderRadius: "14px",
    border:"1px solid #E4E4E4",
    alignItems: "center",
    flex: "1 1 calc(25% - 20px)"
  }}>
   <div style={{ display: "flex", alignItems: "center" }}>

<div style={{ marginRight: "10px", position: "relative" }}>
    <img src="/images/milestone1.svg" alt="Milestone1" style={{
      
      width: "76px",  // Adjust width as necessary
      height: "76px", // Adjust height as necessary
      clipPath: "circle(33% at 50% 50%)",
    }}></img>
    <img src="/images/lock.svg" alt="Lock" style={{ position: "absolute", top: 20, left: 25 }}></img>
  </div>
  
  <Typography style={{
    borderRadius: "10px",
    padding: "10px 26px",
    background: "rgba(226.08, 236, 112, 0.14)",
    color: "#A87727",
    fontSize: "12px",
    whiteSpace: "nowrap"
  }}>
    Yet to complete
  </Typography>
</div>
</div>

  <div class="milestone2" style={{
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    padding: "5px 14px",
    borderRadius: "12px",
    backgroundColor: "white",
    boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.06)",
    borderRadius: "14px",
    border:"1px solid #E4E4E4",
    alignItems: "center",
    flex: "1 1 calc(25% - 20px)"
  }}>
    <div style={{ display: "flex", alignItems: "center" }}>
      <div style={{ marginRight: "10px", position: "relative" }}>
        <img src="/images/milestone2.svg" alt="Milestone2" style={{ display: "block" }}></img>
        <img src="/images/lock.svg" alt="Lock" style={{ position: "absolute", top: 20, left: 25 }}></img>
      </div>
      <Typography style={{
        borderRadius: "10px",
        padding: "10px 26px",
        background: "rgba(226.08, 236, 112, 0.14)",
        color: "#A87727",
        fontSize: "12px",
        whiteSpace: "nowrap"
      }}>
        Yet to complete
      </Typography>
    </div>
  </div>

  <div class="milestone3" style={{
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    padding: "5px 14px",
    borderRadius: "12px",
    background: "white",
    boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.06)",
    borderRadius: "14px",
    border:"1px solid #E4E4E4",
    alignItems: "center",
    flex: "1 1 calc(25% - 20px)"
  }}>
    <div style={{ display: "flex", alignItems: "center" }}>
      <div style={{ marginRight: "10px", position: "relative" }}>
        <img src="/images/milestone3.svg" alt="Milestone3" style={{ display: "block" }}></img>
        <img src="/images/lock.svg" alt="Lock" style={{ position: "absolute", top: 20, left: 25 }}></img>
      </div>
      <Typography style={{
        borderRadius: "10px",
        padding: "10px 26px",
        background: "rgba(226.08, 236, 112, 0.14)",
        color: "#A87727",
        fontSize: "12px",
        whiteSpace: "nowrap"
      }}>
        Yet to complete
      </Typography>
    </div>
  </div>

  <div class="milestone4" style={{
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    padding: "5px 14px",
    borderRadius: "12px",
    backgroundColor: "white",
    boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.06)",
    borderRadius: "14px",
    border:"1px solid #E4E4E4",
    alignItems: "center",
    flex: "1 1 calc(25% - 20px)"
  }}>
    <div style={{ display: "flex", alignItems: "center" }}>
      <div style={{ marginRight: "10px", position: "relative" }}>
        <img src="/images/milestone4.svg" alt="Milestone4" style={{ display: "block" }}></img>
        <img src="/images/lock.svg" alt="Lock" style={{ position: "absolute", top: 20, left: 25 }}></img>
      </div>
      <Typography style={{
        borderRadius: "10px",
        padding: "10px 26px",
        background: "rgba(226.08, 236, 112, 0.14)",
        color: "#A87727",
        fontSize: "12px",
        whiteSpace: "nowrap"
      }}>
        Yet to complete
      </Typography>
    </div>
  </div>
</div>

                </div>
              

              <Grid item xs={12} style={{marginLeft:"5px",marginBottom:"60px"}}>
      <Typography style={{ fontSize: "20px", fontWeight: "700", marginBottom:"10px" }}>
      <span style={{fontSize:"18px"}}>Transaction History</span></Typography>
      <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Card style={{
          display: "flex",
          flexDirection: "row",
          borderRadius: "24px",
          boxShadow: "0px 4px 20px -10px rgba(0, 0, 0, 0.10)",
          marginRight: "10px"
        }}>
          <CardContent style={{ flex: "1", textAlign: 'left' }}>
            <Typography style={{
              fontSize: "32px",
              fontWeight: "700",
              marginBottom:"20px"
            }}>0</Typography>
            <Typography variant="body2" color="textSecondary">Total Received in Farming</Typography>
          </CardContent>
          <img src="/images/handcoin.png" alt="Hand with coins" width="149px" height="130px" style={{ alignSelf: 'center', marginRight: '0px' }} />
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card style={{
          display: "flex",
          flexDirection: "row",
          borderRadius: "24px",
          boxShadow: "0px 4px 20px -10px rgba(0, 0, 0, 0.10)",
          marginRight: "10px"
        }}>
          <CardContent style={{ flex: "1", textAlign: 'left' }}>
            <Typography style={{
              fontSize: "32px",
              fontWeight: "700",
              marginBottom:"20px"
            }}>0</Typography>
            <Typography variant="body2" color="textSecondary">Buy Back History</Typography>
          </CardContent>
          <img src="/images/clockcoin.png" alt="Hand with coins" width="149px" height="130px" style={{ alignSelf: 'center', marginRight: '0px' }} />
        </Card>
      </Grid>
      </Grid>
    </Grid>

            </Grid>
          </Box>
        </div>
        
      </Box>
      <div
          style={{
            position: "fixed",
            bottom: "0",
            backgroundColor: "white",
            width: "100%",
            zIndex: "100",
          }}
        >
<div style={{
  display:"flex",
  alignItems: "center",
  justifyContent: "center",
  gap:"10px",
  marginBottom:"8px",
  marginTop : "5px"
}}>


    <span>
    <Button
    variant="outlined"
    style={{
      backgroundColor: "white",
      color: "#1877F2",
      borderColor: "#1877F2",
      borderWidth: "2px",
      borderStyle: "solid",
      borderRadius: "27px",
      textTransform: "none",
      fontFamily: "Satoshi, sans-serif",
      fontSize: "14px",
      fontWeight: "700",
      padding: "3px 14px",
      maxHeight: "40px",
      whiteSpace: "nowrap",
      marginRight: "4px",
      marginLeft: "10px"
    }}
    onClick={handleButtonClick}
    onMouseDown={(e) => {
      e.currentTarget.style.backgroundColor = "#1877F2";
      e.currentTarget.style.color = "white";
    }}
    onMouseUp={(e) => {
      e.currentTarget.style.backgroundColor = "white";
      e.currentTarget.style.color = "#1877F2";
    }}
  >
    Predictions
  </Button>
  </span>
    
   


    <span>
    <Button
    variant="outlined"
    style={{
      backgroundColor: "white",
      color: "#1877F2",
      borderColor: "#1877F2",
      borderWidth: "2px",
      borderStyle: "solid",
      borderRadius: "27px",
      textTransform: "none",
      fontFamily: "Satoshi, sans-serif",
      fontSize: "14px",
      fontWeight: "700",
      padding: "3px 14px",
      maxHeight: "40px",
      whiteSpace: "nowrap",
      marginRight: "20px",
      marginLeft: "10px"
    }}
    onClick={handlePortfolioButtonClick}   
    onMouseDown={(e) => {
      e.currentTarget.style.backgroundColor = "#1877F2";
      e.currentTarget.style.color = "white";
    }}
    onMouseUp={(e) => {
      e.currentTarget.style.backgroundColor = "white";
      e.currentTarget.style.color = "#1877F2";
    }}
  >
    Portfolios
  </Button>
  </span>
    
   
   </div>




   <BottomNavigation showLabels>
  <BottomNavigationAction
        label="Gallery"
        onClick={() =>
          navigate("/portfolio-gallery")
        }
        icon={<img src={loc === '/portfolio-gallery' ? "/images/dashboardS.svg" : "/images/dashboardU.svg"} />}
        
      />
      <BottomNavigationAction
        label="My Portfolios"
        onClick={() => 
          navigate("/my-portfolio")
        }
        icon={<img src={loc === '/my-portfolio' ? "/images/portfolioS.svg" : "/images/portfolioU.svg"}  />}
      />
    <BottomNavigationAction
              label="$1/Month Subscription"
              onClick={() => {
                if (userData) {
                  navigate("/subscriptions/screener360");
                } else {
                  const originalUrl = '/subscriptions/screener360';
                  sessionStorage.setItem("originalUrl", originalUrl);
                  console.log("original url: " + originalUrl);
                  handleLoginClick();
                }
              }}
              icon={
                <img
                  src={
                    
                       loc === "/subscriptions/screener360"
                        ? "/images/dollarS.svg"
                        : "/images/dollarU.svg"
                     
                  }
                  width={24}
                  height={24}
                  alt="Icon"
                  style={{ marginBottom: "-3px" }}
                />
              }
            />
</BottomNavigation>
        </div>

        {isLoginModalOpen && (
        <LoginPopUp 
          isLoginModalOpen={isLoginModalOpen}
          setLoginModalOpen={setLoginModalOpen} // Pass the state setter down
          onClose={handleModalClose}
        />
      )}
    </Box>

    

)}
</>


  );
  
 
}
  
export default InfluencerAccount;
