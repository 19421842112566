import "./about.css";
import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import { Helmet } from 'react-helmet';
import Typography from "@mui/material/Typography";
import MenuList from "../../includes/menulist.jsx";
import MenuListHome from "../../includes/menuListHome.jsx";
import { useState } from "react";
import { Grid } from "@mui/material";
import { getContent } from "../../apihelper/content";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";
import LoginPopUp from '../loginpopup';

import json2mq from "json2mq";
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { ButtonGroup,BottomNavigation,Paper,Chip } from "@mui/material";

import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { useLocation } from "react-router-dom";
import SidebarMenuList from "../../includes/sidebarMenuList.jsx";
const drawerWidth = 292;

function Aboutus(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [aboutdata, setAboutData] = useState("");
  const [loader, setLoader] = useState(false);
  const [userData,setUserData]=useState(undefined)
  React.useEffect(() => {
    const localUserData=localStorage.getItem("cw_portfolio_user")
    setUserData(localUserData);
    getContent("about").then((data) => {
      if (data.code === "200") {
        setAboutData(data.content);
        //console.log(aboutdata);
        setLoader(true);
      } else {
        console.log("Api Call Error");
        // Todo pop up the error
      }
    });
  });
  const location = useLocation(); 
  const loc = location.pathname;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const handleLoginClick = () => {
    // Mask the URL by adding /login at the end without navigating
    window.history.pushState({}, "", "/login");
    localStorage.setItem("isPopupManuallyOpened", "true");
    // Open the login modal
    setLoginModalOpen(true);
  };
  const handleModalClose = () => {
    // Close the modal and reset the URL to the welcome page
    setLoginModalOpen(false);
    navigate(location.pathname, { replace: true });
  };

  const drawer = (
    <div className="d-flex flex-column h-100"style={{overflowY:'hidden'}}>
       <SidebarMenuList/>
    </div>
  );
  
  const container =
    window !== undefined ? () => window().document.body : undefined;

    const navigate = useNavigate();

  const mediaQueryVar = useMediaQuery(
      json2mq({
        minWidth: 900,
      })
    );

  // useEffect(() => {
  //   // Perform localStorage action
  //   const item = localStorage.getItem('userdata')
  //   if (item ==null){
  //      setredirectpage(true);
  //   }
  //   //console.log("tapan"+item);
  // }, [])
  // if (redirectpage) {

  //   return redirect('/');
  // }

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
      
      <Helmet>
        <title>About Us</title>
</Helmet>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "white",
              color: "#727376",
              
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "white",
              color: "#727376",
              borderRadius: "",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 2,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          paddingBottom: "50px"
        }}
      >
        
        <Box sx={{ flexGrow: 1, margin: "2px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} align="left">
            <div style={{ 
  display: "flex", 
  alignItems: "center", 
  justifyContent: "space-between", 
  marginTop: "0px", 
  marginBottom: "10px" ,
  marginLeft:"5px",
  
}}>

                <Typography
                  variant="h5"
                  component="div"
                  style={{
                   fontFamily: "'Satoshi', Arial, sans-serif",
                    fontSize: "1.5em",
                    fontStyle: "normal",
                    fontWeight: "600",
                  }}
                >
                   About Us - Crowdwisdom360
                </Typography>
                <IconButton
    color="inherit"
    aria-label="open drawer"
    edge="end"
    onClick={handleDrawerToggle}
    sx={{ display: { sm: "none" } }}
  >
   <img src='/images/menubar.svg'
 alt="Menu Icon" style={{ width: "24px", height: "24px",marginRight:"10px",marginTop:"-20px" }} />
  </IconButton>
</div>
            </Grid>

            <Grid item xs={12} align="left">
              {/* { loader===false &&
                        <Stack>
                             <Skeleton
                          variant="rounded"
                          width={"100%"}
                          height={60}
                          style={{marginBottom:"10px"}}
                        />
                          <Skeleton
                          variant="rounded"
                          width={"100%"}
                          height={60}
                          style={{marginBottom:"10px"}}
                        />
                          <Skeleton
                          variant="rounded"
                          width={"50%"}
                          height={60}
                          style={{marginBottom:"10px"}}
                        />
                        </Stack>

                        } */}
              {/* <div dangerouslySetInnerHTML={{ __html:aboutdata }} /> */}
              <div>
              <div style={{ fontFamily: "'Satoshi', sans-serif", lineHeight: "1.6" }}>
      <p>
        <strong>Crowdwisdom360</strong> is a cutting-edge platform designed to empower investors with actionable insights, innovative tools, and community-driven wisdom in the world of cryptocurrency and finance. We combine data-driven analysis, influencer expertise, and the collective intelligence of our users to deliver tailored portfolio recommendations, market trends, and precise predictions.
      </p>

      <p><strong>Our platform offers:</strong></p>
      <ul>
        <li>
          <strong>Customizable Portfolios:</strong> Create, analyze, and share crypto portfolios while leveraging our benchmarking and performance tracking tools. Our indices, like the <em>Crowdwisdom360 Memecoin Index</em> and <em>AI Index</em>, have consistently outperformed the market.
        </li>
        <li>
          <strong>Advanced Screeners:</strong> Discover investment opportunities using key technical indicators, patterns, and influencer signals. Our screener simplifies decision-making with tools like RSI, Fibonacci retracement, Bollinger Bands, and Head and Shoulders.
        </li>
        <li>
          <strong>Market Predictions:</strong> Access over 12,000 predictions backed by past accuracy data, enabling users to make informed decisions with confidence.
        </li>
        <li>
          <strong>Wisecoin Rewards:</strong> Engage with our platform to earn Wisecoins, a token designed to reward user participation. Subscribers can redeem Wisecoins, offsetting costs and enhancing value.
        </li>
        <li>
          <strong>Personalized Recommendations:</strong> Dynamic portfolio adjustments based on real-time market conditions, macro trends, and sentiment analysis powered by Lunar Crush.
        </li>
      </ul>

      <p>
        As we expand, our goal is to introduce unique features like category indices, real-time trend analysis, and seamless integration of predictions with actionable investment strategies. By focusing on simplicity, transparency, and community engagement, Crowdwisdom360 is reshaping how individuals and professionals navigate the crypto ecosystem.
      </p>

      <p>
        Visit us on <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer"><strong>LinkedIn</strong></a>
      </p>

      <p>
        Write to us if you have queries or concerns at:{" "}
        <a href="mailto:subscriptions@crowdwisdom.live"><strong>subscriptions@crowdwisdom.live</strong></a>
      </p>

      <p><strong>Registered in the United States and India:</strong></p>
      <ul>
        <li>
          <strong>United States:</strong> 8 The Green Suite R, Dover, DE-19901, United States
        </li>
        <li>
          <strong>India:</strong> 2205, Lake Lucerne, Powai, Mumbai-400076, India
        </li>
      </ul>
    </div>
                
                
              </div>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {mediaQueryVar === false && (


<div
  style={{
    position: "fixed",
    bottom: "0",
    backgroundColor: "white",
    width: "105%",
    zIndex: "100",
    marginBottom:"0px",
    marginLeft:"-10px"
  }}
>
<BottomNavigation showLabels>
  <BottomNavigationAction
        label="Gallery"
        onClick={() =>
          navigate("/portfolio-gallery")
        }
        icon={<img src={loc === '/portfolio-gallery' ? "/images/dashboardS.svg" : "/images/dashboardU.svg"} />}
        
      />
      <BottomNavigationAction
        label="My Portfolios"
        onClick={() => 
          navigate("/my-portfolio")
        }
        icon={<img src={loc === '/my-portfolio' ? "/images/portfolioS.svg" : "/images/portfolioU.svg"}  />}
      />
    <BottomNavigationAction
              label="$1/Month Subscription"
              onClick={() => {
                if (userData) {
                  navigate("/subscriptions/screener360");
                } else {
                  const originalUrl = '/subscriptions/screener360';
                  sessionStorage.setItem("originalUrl", originalUrl);
                  console.log("original url: " + originalUrl);
                  handleLoginClick();
                }
              }}
              icon={
                <img
                  src={
                    
                       loc === "/subscriptions/screener360"
                        ? "/images/dollarS.svg"
                        : "/images/dollarU.svg"
                     
                  }
                  width={24}
                  height={24}
                  alt="Icon"
                  style={{ marginBottom: "-3px" }}
                />
              }
            />
</BottomNavigation>
</div>
)}

{isLoginModalOpen && (
        <LoginPopUp 
          isLoginModalOpen={isLoginModalOpen}
          setLoginModalOpen={setLoginModalOpen} // Pass the state setter down
          onClose={handleModalClose}
        />
      )}
      
    </Box>
  );
}

export default Aboutus;
