export const completeGoogleLogin=(code)=>{
    console.log("COMPLETE GOOGLE LOGIN API CALL")
    console.log(code)
    return fetch(`${process.env.REACT_APP_API_URL}/completegooglelogin`,{ method:"POST",
    headers:{
        Accept:"application/json",
        "Content-Type":"application/json",
    },
    body : JSON.stringify({code:code})
   })
   .then(response=>{
       return response.json();
   })
   .catch(err=>console.log(err));
}

