import Homenav from "./homenav";
import HomeSideNav from "./homesidenav";

import React, { useEffect, useState } from "react";

export default function HomeSidebarMenuList() {

  const [userLocalData, setUserLocalData] = useState(undefined);
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    setUserLocalData(userData);
  },[]);

  
  return (
    <div>
      {userLocalData && <Homenav />}
      {!userLocalData && <HomeSideNav />}
    </div>
  );
}
