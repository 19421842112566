import React from 'react';

const LoadingScreen = () => {
  // Inline styles
  const containerStyle = {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    background: 'rgb(0,0,0,0.8)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '9999',
  };

  const loaderContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };

  const loaderStyle = {
    width: '100px',
    height: '100px',
    border: '12px solid rgba(255, 255, 255, 0.2)',
    borderTop: '12px solid #ffffff',
    borderRadius: '50%',
    animation: 'spin 1.5s infinite linear',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
  };

  const textStyle = {
    color: '#ffffff',
    fontSize: '26px',
    marginTop: '20px',
    fontWeight: '600',
    textShadow: '0px 3px 6px rgba(0, 0, 0, 0.3)',
    letterSpacing: '1.5px',
  };

  // Keyframes for spin animation
  const keyframes = `
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `;

  return (
    <>
      <style>
        {keyframes}
      </style>
      <div style={containerStyle}>
        <div style={loaderContainerStyle}>
          <div style={loaderStyle}></div>
          <div style={textStyle}>Validating...</div>
        </div>
      </div>
    </>
  );
};

export default LoadingScreen;
