import * as React from "react";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import { Link, Navigate, } from 'react-router-dom';
import { usePath } from './PathContext';
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import LoginPopUp from "../pages/loginpopup";
import ListItemText from "@mui/material/ListItemText";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import QuizIcon from "@mui/icons-material/Quiz";
import { Button } from "@mui/material";
import "./menulist.css";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import { styled } from "@mui/material/styles";
// import { usePathname } from 'next/navigation';
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useState,useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
import SecurityIcon from '@mui/icons-material/Security';
import json2mq from "json2mq";
import useMediaQuery from "@mui/material/useMediaQuery";
// const ASSET_PATH = process.env.PUBLIC_URL;

// import path from "path";
function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}
// const useStyles = makeStyles({
//   border_radius: {

//     borderRadius: 3,
//     backgroundColor:"red"
//   },
// });

function MenuListHome(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentPath, setCurrentPath } = usePath();

  const mediaQueryVar = useMediaQuery(
    json2mq({
      minWidth: 900,
    })
  );
  const mediaQueryVar2 = useMediaQuery(
    json2mq({
      maxHeight: 700,
    })
  );
  
  useEffect(() => {
    // Set the current path when the component mounts
    setCurrentPath(window.location.pathname);
  }, [setCurrentPath]);
  // const { window } = props;
  // const [mobileOpen, setMobileOpen] = React.useState(false);
  // const router = useRouter();

  //  const [subMenu, setSubMenu] = React.useState({ userMenu: false, docManager: false });

  // const handleDrawerToggle = () => {
  //   setMobileOpen(!mobileOpen);
  // };

  // const handleNavigation = (e,path, index) => {
  //   // handleListItemClick(null, index);
  //   router.push(`/${path}`);
  // };
  // const handleMenuToggle = (menu) => {
  //   if (menu === 'users') {
  //     setSubMenu({ ...subMenu, userMenu: !subMenu.userMenu })
  //   }
  //   else {
  //     setSubMenu({ ...subMenu, docManager: !subMenu.docManager })
  //   }

  // }
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const [isLoginModalOpen, setLoginModalOpen] = useState(false);

  const handleLoginClick = () => {

  

    sessionStorage.setItem('originalUrl', window.location.pathname + window.location.search);

    // Mask the URL by adding /login at the end without navigating
    window.history.pushState({}, "", "/login");
    localStorage.setItem("isPopupManuallyOpened", "true");

    // Open the login modal
    setLoginModalOpen(true);
  };

  const handleModalClose = () => {
    // Close the modal and reset the URL to the current page
    setLoginModalOpen(false);
    navigate(location.pathname, { replace: true });
};


  const handleClose = () => {
    localStorage.removeItem("cw_portfolio_user");
    // setSnackbaropen(true);
    navigate("/login", { replace: true });
    setOpen(false);
  };
  const handleCloseCancle = () => {
    //   localStorage.removeItem("cw_portfolio_user")
    // // setSnackbaropen(true);
    //   navigate("/", { replace: true });
    setOpen(false);
  };
  const [snackbaropen, setSnackbaropen] = React.useState(false);
  const [isHover, setIsHover] = useState(false);

  const StyledList = styled(List)({
    "&& .Mui-selected, && .Mui-selected:hover": {
      background: "linear-gradient(90deg, #1877F2 0%, #519DFF 100%)", color:"white", fontWeight:"bold !important",
      "&, & .MuiListItemIcon-root": {
        color: "white",
        fontWeight: "bold",
        borderRadius: "10px"
      },
    },
    "& .MuiListItemButton-root:hover": {
      background: "linear-gradient(90deg, #1877F2 0%, #519DFF 100%)",color:"white",fontWeight:"bold !important",
      "&, & .MuiListItemIcon-root": {
        color: "white",
        fontWeight: "bold",
        borderRadius: "10px"
      
      },
    },
  });

  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const closesnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbaropen(false);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const pathName = useLocation().pathname;

  const isActive = (route) => {
    return pathName.substring(1) === route;
  };

  const handleListItemClick = (path) => {
    // router.push(`/${path}`);
    // localStorage.removeItem("cw_portfolio_user")
    navigate(`/${path}`, { replace: true });
  };
  const hangleLogOutClick = (path) => {
    // router.push(`/${path}`);

    // setOpen(true);
    localStorage.removeItem("cw_portfolio_user");
    // setSnackbaropen(true);
    navigate(`/${path}`, { replace: true });
  };
  const menuItems = [
    {
      name: "Portfolio Gallery",
      icon: `${window.constants.asset_path}/images/createicon.svg`,
      hoverIcon: `${window.constants.asset_path}/images/activecreateicon.svg`,
      activeIcon: `${window.constants.asset_path}/images/activecreateicon.svg`,
      route: "portfolio-gallery",
    },
    {
      name: "My Portfolios",
      icon: `${window.constants.asset_path}/images/portfolioicon.svg`,
      hoverIcon: `${window.constants.asset_path}/images/activeportfolioicon.svg`,
      activeIcon: `${window.constants.asset_path}/images/activeportfolioicon.svg`,
      route: "my-portfolio",
    },
    {
      name: "Profile",
    
      icon: `${window.constants.asset_path}/images/accounticon.svg`,
      hoverIcon: `${window.constants.asset_path}/images/activeaccounticon.svg`,

activeIcon: `${window.constants.asset_path}/images/activeaccounticon.svg`,
      route: "account",
    },

    
 {
      name: "Crypto Screener",
    
      icon: `${window.constants.asset_path}/images/privacyicon.svg`,
      hoverIcon: `${window.constants.asset_path}/images/activeprivacyicon.svg`,

      activeIcon: `${window.constants.asset_path}/images/activeprivacyicon.svg`,
      route: "crypto-screener",
    },
    {
      name: "Indices",
    
      icon: `${window.constants.asset_path}/images/createicon.svg`,
      hoverIcon: `${window.constants.asset_path}/images/activecreateicon.svg`,
      activeIcon: `${window.constants.asset_path}/images/activecreateicon.svg`,
      route: "indices",
    },
    {
      name: "Whitepaper",
    
      icon: `${window.constants.asset_path}/images/whitepaper.svg`,
      hoverIcon: `${window.constants.asset_path}/images/whitepaper.svg`,

      activeIcon: `${window.constants.asset_path}/images/whitepaper.svg`,
      route: "whitepaper",
    },
   
    {
      name: "FAQs",
    
      icon: `${window.constants.asset_path}/images/faqicon.svg`,
      hoverIcon: `${window.constants.asset_path}/images/activefaqicon.svg`,

      activeIcon: `${window.constants.asset_path}/images/activefaqicon.svg`,
      route: "portfoliofaqs",
    },
    {
      name: "Contact us",
    
      icon: `${window.constants.asset_path}/images/accounticon.svg`,
      hoverIcon: `${window.constants.asset_path}/images/contacticon.svg`,

activeIcon: `${window.constants.asset_path}/images/contacticon.svg`,
      route: "contactus",
    },
    {
      name: "Terms of Use",
    
      icon: `${window.constants.asset_path}/images/frame16.svg`,
      hoverIcon: `${window.constants.asset_path}/images/activecreateicon.svg`,

      activeIcon: `${window.constants.asset_path}/images/frame10.svg`,
      route: "termofuse",
    },
    {
      name: "Privacy",
    
      icon: `${window.constants.asset_path}/images/privacyicon.svg`,
      hoverIcon: `${window.constants.asset_path}/images/activeprivacyicon.svg`,

      activeIcon: `${window.constants.asset_path}/images/activeprivacyicon.svg`,
      route: "privacyPolicy",
    },
  ];
  
 


  return (
    <div className="mai_div_menu" style={{backgroundColor: "white"}}>
      {isLoginModalOpen && (
        <LoginPopUp 
          isLoginModalOpen={isLoginModalOpen}
          setLoginModalOpen={setLoginModalOpen} // Pass the state setter down
          onClose={handleModalClose}
        />
      )}
      
      <StyledList>
        <List
          sx={{ margin: 0, padding: 0 }}
          component="nav"
          aria-label="secondary mailbox folder"
          className="sideMenuList"
        >
          <div className="frameWrapper_menulist" style={{display:'flex',alignItems:"center",backgroundColor:"white", paddingTop:"10px",paddingBottom:"20px",}} >
            {/* <div className="frameContainer_menulist" > */}
              {/* <div className="frameGroup_menulist" style={{ paddingLeft: "26px" }}> */}
                <a href="/">
                <img
                  className="frameChild_menulist"
                  alt=""
                  src={window.constants.asset_path  + '/images/logo_with_bg.png'}
                  // width={400} height={100}
                  style={{width:"180px",height:"40px",marginBottom:"25px",marginTop:"7px"}}
                />
                </a>
                {/* <b className="crypto_menulist">Crowd Wisdom</b> */}
              {/* </div> */}
            {/* </div> */}
          </div>
          {/* <div>  */}
            {/* There is a red HR */}
           
        {/* </div> */}
        {menuItems.map(({ name, icon, hoverIcon, activeIcon, route }) => {
  const active = isActive(route);

  const handleMenuClick = (route) => {
    if (route === "account") {
      const userData = localStorage.getItem("cw_portfolio_user");
      if (!userData) {
          const originalUrl = '/account';
          sessionStorage.setItem("originalUrl", originalUrl);
          // console.log("original url: " + originalUrl);
        handleLoginClick(); // Trigger login modal if user data is missing
        return;
      }
    }
    // Otherwise, navigate to the route as usual
    navigate(`/${route}`);
  };

  return (
    <ListItem
      disablePadding
      key={route}
      style={{
        paddingLeft: "26px",
        fontSize: "16px",
        fontFamily: "Satoshi, sans-serif",
        lineHeight: "24px",
        fontWeight: "500",
        color: "black",
        backgroundColor: "white",
      }}
      component="div" // Change from Link to div
      onClick={() => handleMenuClick(route)} // Add click handler
    >
      <ListItemButton
        className={active ? "activeMenu" : ""}
        style={{
          borderRadius: "10px",
          marginBottom: "8px",
          marginRight: "30px",
          paddingTop: "9px",
          paddingBottom: "9px",
          paddingLeft: "18px",
          paddingRight: "0px",
        }}
      >
        <ListItemIcon>
          <img
            className="frameIcon"
            alt=""
            src={active ? activeIcon : icon}
            style={{ marginRight: "-10px", right: 0 }}
          />
        </ListItemIcon>

        <span
          style={{
            fontSize: "14px",
            fontWeight: "540",
            fontFamily: "Satoshi, sans-serif",
            marginLeft: "-23px",
            letterSpacing: "0.7px",
          }}
        >
          {name}
        </span>
      </ListItemButton>
    </ListItem>
  );
})}

        </List>
      </StyledList>
      <Button
            
                aria-label="add"
               
                className="logout"
               
                style={{
                  background: isHover ? "linear-gradient(90deg, #1877F2 0%, #519DFF 100%)" : "transparent",
                  padding: "10px 15px",
                  fontWeight: isHover? "bold" : "",
                  justifyContent: "left",
                  textTransform: "none",
                  fontSize: isHover? "18px" : "16px",
                  color: isHover? "white":"black",
                  marginLeft: "35px",
                  marginTop:"160px",
                  marginBottom:"50px",
                  borderRadius:"10px"
                }}
              >
                
                <Button
    onClick={handleLoginClick}
    style={{
      display:"block",
      color: "white",
      backgroundColor: "#1877F2",
      textTransform: "none",
      width:  "180px",
      height:"40px",
      marginBottom:  mediaQueryVar === true ? "30px":"10px",
      fontWeight: "500",
      fontSize: "16px",
      borderRadius: "30px",
      marginTop: mediaQueryVar === true ? "-125%":(mediaQueryVar2?"-180%":"-120%"),
      
      padding: "5px 18px",
      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
      border: "none",
      cursor: "pointer",
      marginLeft:"-10px",
      
    }}
  >
    Login/Signup  
  </Button>
  </Button>      
      <StyledList>
        <List
          sx={{ margin: 0, paddingLeft: "26px" }}
          component="nav"
          aria-label="secondary mailbox folder"
        >
          
              <Dialog
                open={open}
                onClose={handleClose}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
              >
                <DialogTitle
                  style={{ cursor: "move" }}
                  id="draggable-dialog-title"
                >
                  Logout
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>Are you sure to logout?</DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button autoFocus onClick={handleCloseCancle}>
                    Cancel
                  </Button>
                  <Button onClick={handleClose}>Logout</Button>
                </DialogActions>
              </Dialog>
            
          
        </List>
      </StyledList>
    </div>
  );
}
export default MenuListHome;
