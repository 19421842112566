import { useNavigate } from "react-router-dom";
import { useState } from "react";
import "./profile.css";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { updateProfile } from "../../apihelper/profile";
import LoadingButton from "@mui/lab/LoadingButton";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Helmet } from 'react-helmet';
import React from "react";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export default function Profile() {
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
  const [name, setName] = useState(userData.full_name);
  const [country, setCountry] = useState("India");
  const [portfolioname, setPortfolioname] = useState(userData.full_name);
  const [loader, setLoader] = useState(false);
  const [error,setError]=useState(false);
  const [errormsg,setErrormsg]=useState("");

  // const []

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setError(false);
  };
  const handleSubmit = () => {
    // event.preventDefault();
    // console.log(name, country,portfolioname);

    setLoader(true);
    
    if (userData) {
      let id = userData.user_id;
      let token = userData.token;

      updateProfile({ id, name, country, portfolioname,token}).then((data) => {
       // console.log(data);
        if (data.code === "200") {
          //console.log("Profile Data saved");
          navigate("/cryptocurrencylist", { replace: true });
        } else {
         // console.log("Profile Data not Saved");
         // console.log("ERROR OCCURE");
          setLoader(false);
          setError(true)
          setErrormsg(data.message)
          // TODO
          // pop up the error
        }
      });
    } else {
     // console.log("LOGIN FIRST");
      navigate("/", { replace: true });
      // TODO
      // pop up the with warning to login first
    }
  };

  return (
    <div
      className="main_profile"
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        background: "#f8f6f8",
      }}
    >
      <Helmet>
        <title>Profile</title>
</Helmet>
      <div
        style={{ background: "white", padding: "10px", borderRadius: "10px" }}
      >
        <div className="logo_div">
          <img src="images/logo_with_bg.png" width={210} height={50} alt="CWLOGO"  />
        </div>
        <div className="main_div">
          <p className="update_profile_text">Update your profile</p>
          <form style={{ width: "100%" }}>
            <div className="form_group">
              <div>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Select Country
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={country}
                    label="Select Country"
                    onChange={(e) => setCountry(e.target.value)}
                  >
                    <MenuItem value={"India"}>India</MenuItem>
                    <MenuItem value={"USA"}>USA</MenuItem>
                    <MenuItem value={"UK"}>UK</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="form_group">
              <input
                type="text"
                className="form-control"
                id="text"
                value={name}
                aria-describedby="text"
                placeholder="Full Name"
                onChange={(e) => setName(e.target.value)}
                style={{ height: "50px" }}
              />
            </div>
            <div className="form_group">
              <input
                type="text"
                className="form-control"
                id="text"
                value={portfolioname}
                aria-describedby="text"
                placeholder="Username"
                onChange={(e) => setPortfolioname(e.target.value)}
                style={{ height: "50px" }}
              />
            </div>

            <div>
              <LoadingButton
                loading={loader}
                loadingPosition="start"
                variant="outlined"
                style={{
                  height: "50px",
                  marginTop: "5px",
                  width: "100%",
                  backgroundColor: "#0B5ED7",
                  color: "white",
                }}
                onClick={handleSubmit}
              >
                <span>Submit</span>
              </LoadingButton>
            </div>
            <Snackbar
          open={error}
          autoHideDuration={6000}
          onClose={handleClose}
          message= {errormsg}
          // action={action}
          style={{ width: "100%",display:"flex",alignItems:"center",justifyContent: "center"}}
        />
            {/* <Snackbar open={error} autoHideDuration={6000} onClose={handleClose} style={{ width: "100%",display:"flex",alignItems:"center",justifyContent: "center"}}>
              <Alert
                onClose={handleClose}
                severity="error"
                sx={{ width: "300px",position:"relative"}}
              >
                {errormsg}
              </Alert>
            </Snackbar> */}
          </form>
        </div>
      </div>
    </div>
  );
}
