import "./portfolio.css";
import * as React from "react";
import Tooltip from '@mui/material/Tooltip';
import Table from "@mui/material/Table";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import { Helmet } from 'react-helmet';
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import Dialog from "@mui/material/Dialog";
import CreatableSelect from "react-select/creatable";
import CloseIcon from "@mui/icons-material/Close";
import { getTotalunit } from "../../apihelper/cryptocurrencylist";
import { copyToPortfolio } from "../../apihelper/portfolio";
import LoginPopUp from "../loginpopup.js";
import {Popover} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import ClearIcon from "@mui/icons-material/Clear";

import {
  getPortfolioForGallery,
  getSymbolbyPortfolioid,
} from "../../apihelper/homepage";
import {
  Checkbox,
} from "@mui/material";
import {
  FormControlLabel,
  FormLabel,
  DialogTitle,
  DialogContent,
  DialogActions,
  Radio,
  RadioGroup,

  
} from "@mui/material";
import { getCryporCurrencyList,getFetchdropdown } from "../../apihelper/cryptocurrencylist";

import { ButtonGroup } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MenuList from "../../includes/menulist";
import MenuListHome from "../../includes/menuListHome";
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";
import { useEffect } from "react";
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { BottomNavigation,Paper,Chip } from "@mui/material";
import { useLocation } from "react-router-dom";
import { Card, Button, Grid, TextField } from "@mui/material";
import Modal from "@mui/material/Modal";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import InputAdornment from "@mui/material/InputAdornment";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import json2mq from "json2mq";
import useMediaQuery from "@mui/material/useMediaQuery";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
// import { getCryporCurrencyList } from "../../apihelper/cryptocurrencylist";
import { getsymbollink } from "../../apihelper/getsymbollink.js";
import { PortfolioGallery } from "../home/PortFolioGallery.js";
import CircularProgress from "@mui/material/CircularProgress";
import { useParams } from "react-router-dom";
import { getPortfolioData,getPortfolioShare, getPortfolioShareWithoutLogin } from "../../apihelper/portfolio";
import { addToPortfolio } from "../../apihelper/portfolio";
import { useNavigate } from "react-router-dom";
import Portfolioadd from "../addPage/portfolioadd";
import SidebarMenuList from "../../includes/sidebarMenuList";
import { getUserData } from "../../apihelper/portfolio";
import Footer from '../footer';

// const ASSET_PATH = process.env.PUBLIC_URL;
const useStyles = styled((theme) => ({
  tableContainer: {
    maxWidth: 600,
    margin: "0 auto",
  },
  table: {
    display: "none", // Hide the table on all screens
    [theme.breakpoints.down("sm")]: {
      display: "table", // Show the table on small screens only (mobile view)
    },
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none", // Hide the card container on small screens (mobile view)
    },
  },
  card: {
    width: "100%",
    margin: "8px",
    padding: "16px",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "300px", // Adjust the card width as needed
    },
  },
}));
const drawerWidth = 292;

function PortfolioShare(props) {
    
    const { portfolioName } = useParams();
    const mPortfolioName = portfolioName.replace(/-/g, ' ');
   
    // Now, userId contains the value of the "id" parameter from the URL
   // 
    //console.log("portfolio Name*:", mPortfolioName);
  const navigate = useNavigate();
  const { window1 } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const handleClose = () => setOpen(false);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [snackbaropen, setSnackbaropen] = React.useState(false);

  const classes = useStyles();
  const [alignment, setAlignment] = React.useState("left");
  const [loader, setLoader] = useState(false);
  const [slugdata, setSlugdata] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const [Usablewallet, setUsablewallet] = useState("");
  const [loadermodal, setloadermodal] = useState(false);
  const [symbolslugdata, setsymbolslugdata] = useState([]);
  const [isverified, setisverified] = useState("no");
  const [isChecked, setIsChecked] = useState(
    Array(symbolslugdata.length).fill(false)
  );
  const handleCheckboxChange = (event, idx) => {
    const newChecked = [...isChecked];
    newChecked[idx] = event.target.checked;
    setIsChecked(newChecked);
    const anyChecked = newChecked.some((checked) => checked);
    setIsButtonEnabled(anyChecked);
  };
  const [unitValues, setUnitValues] = useState(
    Array(symbolslugdata.length).fill("")
  );
  const handleUnitChange = (event, idx) => {
    const { value } = event.target;
    setUnitValues((prevState) => {
      // Ensure prevState is converted to an array if it's not already
      const updatedUnitValues = Array.isArray(prevState) ? [...prevState] : [];
      updatedUnitValues[idx] = value; // Update the unit value for the specified index
      return updatedUnitValues;
    });
  };
  const [index, setIndex] = useState(0);
  const [addMoreLoader, setAddMoreLoader] = useState(false);
  const [filter, setFilter] = useState("");
  const [profilename, setprofilename] = useState("...");
  const [loaderbutton, setLoaderButton] = useState(true);
  const [roivalue, setRoivalue] = useState("-");
  const [portId, setPortId] = useState("-");
  const [portfoliovalue, setPortfoliovalue] = useState("-");
  const [lastUpdatedTime, setLastUpdatedTiem] = useState("");
  const [slugpopupdata, setSlugpopupdata] = useState({
    slugname: "",
    slug: "",
    units: 0,
  });
  const [msgAfterLoading, setMsgAfterLoading] = useState("");
  const [unit, setUnit] = useState("");
  const [buySell, setbuySell] = React.useState("");
  const [price, setPrice] = useState("");
  const [gallarydata, setGallarydata] = useState([]);
  const [showModal, setShowModal] = useState(false); // Change the state name to showModal
  const [isButtonEnabled, setIsButtonEnabled] = useState(false); // State to control button disabled state
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [totalUnits, setTotalUnits] = useState(0);
  const [dropDown, setdropDown] = useState([]);
  const [dataslug, setdataslug] = useState("");
  const [newOptionValue, setNewOptionValue] = useState("");
  const [influencerName, setInfluencerName] = useState("");

  const [selectedDate, handleDateChange] = useState("");
  const [msgforUpdatePortfolio, setMsgforUpdatePortfolio] =
    useState("loading...");
  const [severity, setSeverity] = useState("info");
  const [initialPage, setInitialPage] = useState(false);
  const [localUserData,setLocalUserData]=useState(undefined)
  const [loaderForSymbolLink,setLoaderForSymbolLink]=useState(false);
  const [PortfolioRoivalue,setPortfolioRoivalue]=useState('-');
  const [nodatafoundmsgtype,setnodatafoundmsgtype]=useState('');
  const [currentwallet,setcurrentwallet]=useState('-');
  const [loading, setLoading] = useState(true);
  const [roi_days, setRoi_days] = useState("0");
  const location = useLocation(); 
  const loc = location.pathname;

  const [isLoginModalOpen, setLoginModalOpen] = useState(false);

  const handleLoginClick = () => {
    // Mask the URL by adding /login at the end without navigating
    window.history.pushState({}, "", "/login");
    localStorage.setItem("isPopupManuallyOpened", "true");

    // Open the login modal
    setLoginModalOpen(true);
  };

  const handleModalClose = () => {
    // Close the modal and reset the URL to the current page
    setLoginModalOpen(false);
    navigate(location.pathname, { replace: true });
};

  const handleSymbolLink=(symbolName)=>{
   // console.log(symbolName)
    setLoaderForSymbolLink(true);
    getsymbollink(symbolName).then(res=>{
      if(res.code==='200')
      window.open(res.article_link, '_blank', 'noreferrer');
      // window.location.replace(res.article_link);
      // setLoaderForSymbolLink(false);
      setLoaderForSymbolLink(false);
    })
  }
  const handleOpen = (idx) => {
    // setopenindex(idx);const today = new Date();

    setSlugpopupdata({
      slugname: slugdata[idx].slug,
      slug: slugdata[idx].name,
      units: slugdata[idx].total_unit,
      pricetoday: slugdata[idx].price_today,
      crypto_price: slugdata[idx].crypto_price,
    });
    setOpen(true);
  };

  const mediaQueryVar = useMediaQuery(
    json2mq({
      minWidth: 900,
    })
  );
  const isSmallScreen = useMediaQuery(
    json2mq({
      maxWidth: 420,
    })
  );
  var rows = [],
    i = 0,
    len = 15;
  while (++i <= len) rows.push(i);
  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  const handleCreateNew = () => {
    // router.push(`/${path}`);
    navigate("/cryptocurrencylist", { replace: true });
  };
  const handleGoBack = () => {
    navigate(-1); // Go back to the previous page
  };
  const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));

useEffect(() => {
  
    const user_idValue = userData ? userData.user_id : 0;
    const user_token = userData ? userData.token : 0;
    
    if (userData) {
      // User is logged in
      const userId  = userData['portfolio_name'];
      getPortfolioShare({
        slug: filter,
        user_id: user_idValue,
        portfolio_id: portfolioName,
       
        Token: user_token,
      }).then((res) => {
        if (res.code === "200") {
          setSlugdata(res.data);
          setData(res.data);
          if (res.data.length < 10) {
            setLoaderButton(false);
          } else {
            setLoaderButton(true);
          }
          setLoader(true);
          setAddMoreLoader(false);
        } else {
          setMsgAfterLoading(res.message);
        }
      });
    } else {
      // User is not logged in
      getPortfolioShareWithoutLogin({
        portfolio_id: portfolioName, 
        slug: filter,
        start_index: 0,
        limit: `${10}`,
      }).then((res) => {
        if (res.code === "200") {
          const sortedSlugdata = res.data.sort((a, b) => {
            const aValue = parseFloat(a.total_roi_value.replace('%', ''));
            const bValue = parseFloat(b.total_roi_value.replace('%', ''));
            return bValue - aValue;
          });
          // Slice the data to get the first two entries
          const slicedData = sortedSlugdata.slice(0, 5);
      
          // Set the state with the sliced data
          setSlugdata(slicedData);
          setData(slicedData);
          console.log(res);
          setLoading(false);
          if (res.data.length < 10) {
            
            setLoaderButton(false);
          } else {
            setLoaderButton(true);
          }
          setLoader(true);
          setAddMoreLoader(false);
        } else {
          setMsgAfterLoading(res.message);
        }
      });
    }
  
}, [filter]);

  const [user_role, setuser_role] = useState("");
  const [viewprofile, setviewprofile] = useState(false);
  
  const fetchDropdownOptions = async () => {
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));


    getFetchdropdown({
      user_id: userData.user_id,
      
      Token:userData.token
    }).then((res) => {
      if (res.code === "200") {
        
        const newOptions = res.data.map(item => ({ value: item.id, label: item.name }));
          setOptions(newOptions);
          setuser_role(res.user_role);
       } else {
        console.log("Data fething error");
      }
    
    });
   
    
  }

  const handleProfileClick = () => {
   // console.log("PF name : ",portfolioName);
    const encodedPortfolioName = encodeURIComponent(mPortfolioName);
    navigate(`../../influencer/${influencerName.replace(/\s+/g, '-')}`, {
      state: { portfolioName }
    });  };

  useEffect(() => {
    //console.log(100)
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    setLocalUserData(userData)
    const user_idValue = userData ? userData.user_id : 0;
    //console.log("User_Idvalue"+user_idValue);
    const user_token = userData ? userData.token : 0;
    // console.log("User_token"+user_token);

    getUserData({
      user_id: user_idValue,
      portfolio_id: portfolioName,
      Token: user_token,
    }).then((res) => {

    if (res.code === "200") {
     console.log("User data fetched successfully: ", res.data);
      const userData = res.data[0];
      if(!userData.name){
        setviewprofile(false);
      }
      else{
      setInfluencerName(userData.name);
      setviewprofile(true);
      }
    }
    else{
      console.log("Data fething error");
    }
  });

    getPortfolioForGallery({
      user_id: user_idValue,
      search_text:mPortfolioName,
     
      start_index: 0,
      limit: `${10}`,
    }).then((res) => {
      if (res.code === "200") {
        

        setGallarydata(res.data);
        setPortId(res.data[0].portfolio_id);
        //console.log("data got successfully",res.data[0].portfolio_id);
        setisverified(res.data[0].is_verified);
       
        if (res.data.length < 10) {
          //setgalleryloaderbutton(false);
        } else {
          //setgalleryloaderbutton(true);
        }
        //setphotogalleryloader(true);
        //setAddMoregalleryLoader(false);
      } else {
        console.log("Data fething error");
      }
      // console.log(slugdata)
    });



    getPortfolioShare({
      slug: filter,
      user_id: user_idValue,
      portfolio_id:portfolioName,
      start_index: `${index}`,
      limit: `${10}`,
      Token: user_token,
    }).then((res) => {
      if (res.code === "200") {
       // console.log("data got successfully: ",res.portfolio_name);
        setRoivalue(res.total_profit);
        setPortfoliovalue(res.total_portfolio_value);
        setPortfolioRoivalue(res.roi_average);
        setSlugdata(res.data);
        setData(res.data);
        setnodatafoundmsgtype(res.no_data_found_msgtype);
        setcurrentwallet(res.current_wallet);
        setLastUpdatedTiem(res.updated_on);
        setprofilename(res.portfolio_name);
        setLoading(false);
        setRoi_days(res.roi_days);
        console.log(res.data)
        if (res.data.length === 0) {
          setInitialPage(true);
        }
        if (res.data.length < 10) {
          setLoaderButton(false);
        } else {
          setLoaderButton(true);
        }
        setLoader(true);
        setAddMoreLoader(false);
      } else {
        //console.log("Data fething error");
      }
      // console.log(slugdata)
    });

    getPortfolioShareWithoutLogin({
      portfolio_id: portfolioName, 
        slug: filter,
        start_index: 0,
        limit: `${10}`,
    }).then((res) => {
      if (res.code === "200") {
       // console.log("data got successfully: ",res.portfolio_name);
        setRoivalue(res.total_profit);
        setPortfoliovalue(res.total_portfolio_value);
        setPortfolioRoivalue(res.roi_average);
        setSlugdata(res.data);
        setData(res.data);
        setnodatafoundmsgtype(res.no_data_found_msgtype);
        setcurrentwallet(res.current_wallet);
        setLastUpdatedTiem(res.updated_on);
        setprofilename(res.portfolio_name);
        setLoading(false);
        setRoi_days(res.roi_days);
        // console.log(res.data)
        if (res.data.length === 0) {
          setInitialPage(true);
        }
        if (res.data.length < 10) {
          setLoaderButton(false);
        } else {
          setLoaderButton(true);
        }
        setLoader(true);
        setAddMoreLoader(false);
      } else {
        //console.log("Data fething error");
      }
      // console.log(slugdata)
    });

  }, []);


  const [rowsToShow, setRowsToShow] = useState(5);
  
  const loadMore = () => {
    console.log("BEfore ROws to SHow: ",rowsToShow," for sorteddata : ", sortedData.length);
    
    setRowsToShow(rowsToShow + 5);
    
  };

  

  // const loadMore = () => {
  //   // setLoader(false);
  //   setAddMoreLoader(true);
  //   setIndex(index + 10);
  //   //console.log(index);
  //   const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
  //   getPortfolioShare({
  //     slug: filter,
  //     user_id: userData.user_id,
  //     portfolio_id:portfolioName,
  //     start_index: index + 10,
  //     limit: `${10}`,
  //     Token: userData.token,
  //   }).then((res) => {
  //     if (res.code === "200") {
  //       //console.log("data got successfully");
  //       setSlugdata([...slugdata, ...res.data]);
  //       //console.log(res.data);
  //       if (res.data.length < 10) {
  //         //console.log("change Loaderbutton");
  //         setLoaderButton(false);
  //       } else {
  //         setLoaderButton(true);
  //       }
  //       setLoader(true);
  //       setAddMoreLoader(false);
  //     } else {
  //       //console.log("Data fething error");
  //     }
  //     // console.log(slugdata)
  //   });
  // };

  const closesnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbaropen(false);
  };



  useEffect(() => {
    fetchphotogallery();
   // console.log("namee: ",mPortfolioName);
  }, []);
  const fetchphotogallery = () => {
   // console.log("namee: ",mPortfolioName);
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    const user_id = userData ? userData.user_id : 0;
    setLocalUserData(userData);
    getPortfolioForGallery({
      user_id: user_id,
      search_text:mPortfolioName,
     
      start_index: 0,
      limit: `${10}`,
    }).then((res) => {
      if (res.code === "200") {
        

        setGallarydata(res.data);
        //console.log("data got successfully1",gallarydata);
       
        if (res.data.length < 10) {
          //setgalleryloaderbutton(false);
        } else {
          //setgalleryloaderbutton(true);
        }
        //setphotogalleryloader(true);
        //setAddMoregalleryLoader(false);
      } else {
        console.log("Data fething error");
      }
      // console.log(slugdata)
    });
  };


  
  const handleCopy = (portf_Id) => {
    setShowAlert(false);
    //console.log("slug pop Data: ",slugdata);
    //console.log("handle copy portfolio Id",portf_Id);
    setloadermodal(true);
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    if (!userData) {
      window.history.pushState({}, "", "/login");
      // Open the login modal
      setLoginModalOpen(true);
      return; // Return early to prevent further execution
    }
    setUnitValues({});
    setShowModal(true);
    getSymbolbyPortfolioid({
      user_id: userData.user_id,
      portfolio_id: portf_Id,
      Token: userData.token,
    }).then((res) => {
      if (res.code === "200") {
        
        setsymbolslugdata(res.data);
        //console.log("SS Data: ",symbolslugdata);
        setloadermodal(false);
      } else {
        console.log("Data fething error");
      }
      // console.log(slugdata)
    });
  };
  const [retrievalLoading, setRetrievalLoading] = useState(true); // State for loading during retrieval
  const [thesisContent, setThesisContent] = useState('');
// Function to fetch the portfolio description using `fetch`
const fetchDescription = async () => {
  console.log(mPortfolioName);
  const portfolioTitle = mPortfolioName;
  try {
    const response = await fetch('https://crowdwisdom.live/wp-json/api/v1/getThesisDescription', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ portfolioTitle }),
    });
    const result = await response.json();
    console.log(result);
    if (result.code === '200') {
      const formattedDescription = result.description.replace(/\\n/g, "\n"); 
      setThesisContent(formattedDescription); // Assuming the API returns the description field
    }
  } catch (error) {
    console.error('Error fetching description:', error);

  } finally {
    setRetrievalLoading(false);
  }
};
useEffect(() => {
  fetchDescription();
}, [mPortfolioName]);

  const [showAlert, setShowAlert] = useState(false);
  const handleChange2 = (e) => {
    const value = e.target.value;
    // Check if the input contains a hyphen
    if (value.includes('-')) {
      setShowAlert(true); // Show the alert if there's a hyphen
    } else {
      setShowAlert(false); // Hide the alert if there's no hyphen
      setNewOptionValue(value); // Update the state with the new value
    }
  };

  const handleClick = () => {
    setSnackbaropen(true);
    setOpen(true);
    setSeverity("info");
    // setSnackbarcolor("success");
    // setSnackbarmessage("Data saved successfully");
    setMsgforUpdatePortfolio("loading...");
   // console.log(price, selectedDate, buySell, unit,slugpopupdata.slugname);
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    addToPortfolio({
      user_id: userData.user_id,
      slug: slugpopupdata.slugname,
      unit: unit,
      acquisition_price: price,
      date_time: selectedDate,
      type: buySell,
      Token: userData.token,
    }).then((res) => {
      if (res.code === "200") {
        setOpen(false);
        setSeverity("info");
        //console.log(res.message);
        setMsgforUpdatePortfolio(res.message);
        const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
        setLoader(false);
        getPortfolioShare({
          slug: filter,
          user_id: userData.user_id,
          portfolio_id:portfolioName,
         
          Token: userData.token,
        }).then((res) => {
          if (res.code === "200") {
            //console.log("data got successfully");
            setSlugdata(res.data);
            setData(res.data);
          
            if (res.data.length === 0) {
              setInitialPage(true);
              
            }
            if (res.data.length < 10) {
              setLoaderButton(false);
              
            } else {
              setLoaderButton(true);
            }
            setLoader(true);
            setAddMoreLoader(false);
          } else {
            
            //console.log("Data fething error");
          }
          // console.log(slugdata)
        });
        setUnit("");
        setbuySell("");
        setPrice("");
        handleDateChange("");
        //navigate("/portfolio");
      } else {
        setSeverity("error");
        // setOpen(true)
        setMsgforUpdatePortfolio(res.message);
      }
    });
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container =
    window1 !== undefined ? () => window().document.body : undefined;

  // useEffect(() => {
  //   // Perform localStorage action
  //   const item = localStorage.getItem('userdata')
  //   if (item ==null){
  //      setredirectpage(true);
  //   }
  //   //console.log("tapan"+item);
  // }, [])
  // if (redirectpage) {

  //   return redirect('/');
  // }

  const drawer = (
    <div className="d-flex flex-column h-100" style={{overflowY:'hidden'}}>
      <SidebarMenuList/>
    </div>
  );

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      border: 0,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      border: 0,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
   
    "& td, & th": {
      border: 0,

    },
  }));


  const handleCloseModal = () => {
    setShowModal(false);
    setTotalUnits(0);
    setUsablewallet("");
    setSelectedOption([]);
  };
  useEffect(() => {
    if (dropDown) {
      fetchDropdownOptions();
    }
  }, [dropDown]);

  const handleOpenSelect = () => {
    setdropDown(true);
  };

  const [errorMessage, setErrorMessage] = useState('');

  const handleChange1 = async (selectedOption,slug) => {

    if (selectedOption && selectedOption._isNew_) {
      setIsDialogOpen(true);
      console.log('Creating new option:', selectedOption.value);
      setSelectedOption(null);
      setdataslug
      (slug)
    } 
    else if(!selectedOption) {
      // Handle the case when the selection is cleared
      setSelectedOption(null);
      setShowAlert(false);
      // Perform any other necessary actions
      return;
    }else{

      const selectedValue = selectedOption.value;
      if (selectedValue.includes('-')) {
        setErrorMessage('Hyphens are not allowed in the option.'); // Set error message
        setShowAlert(true); // Show the alert
        return; // Abort further processing
      }
      setShowAlert(false);
    setSelectedOption(selectedOption);
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    getTotalunit({
      user_id: userData.user_id,
         slug:  slug, // Assuming value contains the slug
       portfolio_type_id: selectedOption.value ,// Adjust as needed
      Token:userData.token
    }).then((res) => {
      if (res.code === "200") {
        setTotalUnits(res.total_uint);
        setUsablewallet(res.wallet_amount);
       } else {
        console.log("Data fething error");
      }
      // console.log(slugdata)
    });
  }
  };

  const handleClickphotoGallery = () => {
    //console.log("Sorted slug symbol data",sortedSlugsymboldata,"ends");
    const checkedData = symbolslugdata.filter((obj, idx) => isChecked[idx]);
    if (checkedData.length > 0) {
      setMsgforUpdatePortfolio("loading...");

      setSnackbaropen(true);
      setShowModal(true);
      setSeverity("info");
      // setSnackbarcolor("success");
      // setSnackbarmessage("Data saved successfully");
      setMsgforUpdatePortfolio("loading...");
     // console.log("all check data",price, selectedDate, buySell, unit, checkedData);
      const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
      const portfolioId =
        selectedOption && !isNaN(parseInt(selectedOption.value))
          ? parseInt(selectedOption.value)
          : 0;
      const existingPortfolio = portfolioId > 0 ? "yes" : "no";

      const checkedUnitValues = Array.isArray(unitValues)
        ? unitValues.filter((unit, idx) => isChecked[idx])
        : [];
      const portfolioData = checkedData.map((obj, idx) => ({
        slug: obj.slug_name,
        unit: checkedUnitValues[idx] || "",
      }));
      //console.log('tapan',portfolioData);
      copyToPortfolio({
        portfolio_type_id: portfolioId,
        portfolio_name: selectedOption ? selectedOption.label : "", // Ensure selectedOption is defined
        portfolio_data: portfolioData,

        existing_portfolio: existingPortfolio,
        user_id: userData.user_id,

        Token: userData.token,
      }).then((res) => {
        if (res.code === "200") {
          setUnitValues({});
          setShowModal(false);

          setSeverity("info");
        //  console.log(res.message);
          setMsgforUpdatePortfolio(res.message);
          setIsChecked(new Array(symbolslugdata.length).fill(false));

          navigate(
  `/my-portfolio/${res.portfolio_type_id}/${encodeURIComponent(
    selectedOption.label.replace(/ /g, '-')
  )}`
);

          setSelectedOption([]);
          setTotalUnits(0);
          setUsablewallet("");
        } else {
          setSeverity("error");
          setSelectedOption([]);
          setMsgforUpdatePortfolio(res.message);
        }
      });
    } else {
      setSeverity("error");
      setSelectedOption([]);
    }
  };
  const handleDialogSubmit = () => {
    // Handle submitting the dialog (e.g., creating the new option)
    console.log("Creating new option:", newOptionValue);

    // Update options state with the new option
    const newOption = { value: newOptionValue, label: newOptionValue };
    setOptions([...options, newOption]);
    setSelectedOption(newOption);
    // Close the dialog
    setIsDialogOpen(false);

    // setSelectedOption(selectedOption);
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    getTotalunit({
      user_id: userData.user_id,
      slug: dataslug, // Assuming value contains the slug
      portfolio_type_id: newOption.value, // Adjust as needed
      Token: userData.token,
    }).then((res) => {
      if (res.code === "200") {
        setTotalUnits(res.total_uint);
        setNewOptionValue("");
        setUsablewallet(res.wallet_amount);
      } else {
        console.log("Data fething error");
      }
      // console.log(slugdata)
    });
  };
 // Step 1: Create a shallow copy of slugdata
const slugdata1 = slugdata.slice();

// Step 2: Sort slugdata1 based on total_roi_value
const sortedSlugdata = slugdata1.sort((a, b) => {
  const aValue = parseFloat(a.total_roi_value.replace('%', ''));
  const bValue = parseFloat(b.total_roi_value.replace('%', ''));
  return bValue - aValue;
});

const [data, setData] = useState([]);
const [sortOrder, setSortOrder] = useState({ // Track ascending/descending for each column
  roi : 'desc',
});
const [sortType, setSortType] = useState('roi_desc');

const handleSort = (column) => {
  console.log("Arrow Clicked");
  const newSortOrder = sortOrder[column] === 'asc' ? 'desc' : 'asc'; // Toggle sort order
  setSortOrder({ ...sortOrder, [column]: newSortOrder });

  console.log("Sorted");

  // Set the sort type based on the column
  switch (column) {
    case 'roi':
      setSortType(newSortOrder === 'asc' ? 'roi_asc' : 'roi_desc');
      break;
    default:
      break;
  }
};

console.log("Data : ",data);
const filteredData = data.filter(portfolio =>
  portfolio.total_roi_value
);

// Helper function to convert ROI value to a number
const convertRoiValue = (roi) => {
const number = parseFloat(roi.replace('%', ''));
return isNaN(number) ? 0 : number; // Handle invalid or empty values
};



const sortedData = [...filteredData].sort((a, b) => {
switch (sortType) {
  case 'roi_asc':
    return convertRoiValue(a.total_roi_value) - convertRoiValue(b.total_roi_value);
  case 'roi_desc':
    return convertRoiValue(b.total_roi_value) - convertRoiValue(a.total_roi_value);
  
  default:
    return 0;
}
});

console.log("Sorted Data: ", sortedData);



const [anchorEl, setAnchorEl] = useState(null);
const openfilter = Boolean(anchorEl);

const handlePopoverOpen = (event) => {
setAnchorEl(event.currentTarget);
};
const handleCloseeoption = () => {
setAnchorEl(null);
};

const handleClickROIHL = () => {
setSortType('roi_desc');
};
const handleClickROILH = () => {
setSortType('roi_asc');
};


const handleClearAll = () => {
setSortType('roi_asc');
};


 
  return (

    
    <>{mediaQueryVar === true? (
      <Box
      sx={{
        display: "flex",
       height: '100vh' 
      }}
      className="maindiv"
    >
       <Helmet>
    <title>{mPortfolioName}</title>
    <meta property="og:title" content={mPortfolioName} />
    <meta property="og:description" content="Track and compare shadow portfolios from financial influencers." />
    <meta property="og:type" content="website" />
    <meta name="description" content={mPortfolioName} />
    <meta name="twitter:card" content="Best Crypto Portfolios" />
    <meta name="twitter:title" content={mPortfolioName} />
    <meta name="twitter:description" content="Track and compare shadow portfolios from financial influencers." />
   
  </Helmet>
      <CssBaseline />
      <div className="windowheader">
        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
          }}
          style={{
            backgroundColor: "white",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Toolbar>
            <IconButton
              color="white"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <img
           src={window.constants.asset_path  + '/images/logo_with_bg.png'}
              width={220}
              height={50}
              alt="CWLOGO"
              style={{ margin: "10px", borderRadius: "5px" }}
            />
          </Toolbar>
        </AppBar>
      </div>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "white",
              color: "#727376",
             
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "white",
              color: "#727376",
             
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>

      <Box
        
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          padding: "2vw",
          backgroundColor: "#EDF1F6",
        }}
      >

{isLoginModalOpen && (
        <LoginPopUp 
          isLoginModalOpen={isLoginModalOpen}
          setLoginModalOpen={setLoginModalOpen} // Pass the state setter down
          onClose={handleModalClose}
        />
      )}
     
            <div className="windowheader">
              <Toolbar />
            </div>

            <Box sx={{ flexGrow: 1 }} style={{ marginBottom: "15px" }}>
            <Button size="small" color="primary"onClick={handleGoBack} edge="start"  aria-label="menu"
            style={{
              borderRadius: "42px",
              padding:"10px 14px",
              backgroundColor:"#FFF",
              border:"1px solid #F0F0F0",
              width:"85px",
              height:"38px",
              marginLeft:"15px",
              marginBottom:"30px"
            }}>
            <img src={window.constants.asset_path + '/images/backarrow.svg'} alt="" /> 
            <span style={{fontSize:"14px",fontWeight:"500",color:"#727272",marginLeft:"8px",textTransform:"none"}}>Back</span>
            </Button>
            <Grid
  container
  spacing={2}
  style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
>
<Grid item className="CryptocurrencyListHeader">
      <Typography
        variant="subtitle1"
        noWrap
        style={{
          fontFamily: "rta Sans",
          fontSize: mediaQueryVar === false ? "18px" : "25px",
          marginTop: mediaQueryVar === false ? "10px" : "-10px",
        }}
      >
        {loading ? (
          <span style={{fontWeight: "700", marginLeft: "20px"}}>...</span>
        ) : (
          <>
            <span style={{ fontSize: "24px", fontFamily: "Satoshi, sans-serif", fontWeight: "700", marginLeft: "20px" }}>
              {mPortfolioName}
            </span>
            <span>
              {viewprofile && (
                <Button
                  variant="outlined"
                  style={{
                    backgroundColor: "white",
                    color: "#1877F2",
                    borderColor: "#1877F2",
                    borderWidth: "2px",
                    borderStyle: "solid",
                    borderRadius: "27px",
                    textTransform: "none",
                    fontFamily: "Satoshi, sans-serif",
                    fontSize: "16px",
                    fontWeight: "700",
                    padding: "5px 14px",
                    maxHeight: "40px",
                    whiteSpace: "nowrap",
                    marginRight: "5px",
                    marginLeft: "10px"
                  }}
                  onClick={handleProfileClick}
                  onMouseDown={(e) => {
                    e.currentTarget.style.backgroundColor = "#1877F2";
                    e.currentTarget.style.color = "white";
                  }}
                  onMouseUp={(e) => {
                    e.currentTarget.style.backgroundColor = "white";
                    e.currentTarget.style.color = "#1877F2";
                  }}
                >
                  Profile
                </Button>
              )}
              <Button
                variant="outlined"
                style={{
                  backgroundColor: "white",
                  color: "#1877F2",
                  borderColor: "#1877F2",
                  borderWidth: "2px",
                  borderStyle: "solid",
                  borderRadius: "27px",
                  textTransform: "none",
                  fontFamily: "Satoshi, sans-serif",
                  fontSize: "16px",
                  fontWeight: "700",
                  padding: "5px 15px",
                  maxHeight: "40px",
                  whiteSpace: "nowrap",
                  marginLeft: "5px"
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleCopy(portId);
                }}
                onMouseDown={(e) => {
                  e.currentTarget.style.backgroundColor = "#1877F2";
                  e.currentTarget.style.color = "white";
                }}
                onMouseUp={(e) => {
                  e.currentTarget.style.backgroundColor = "white";
                  e.currentTarget.style.color = "#1877F2";
                }}
              >
                Copy Portfolio
              </Button>
            </span>
          </>
        )}
      </Typography>
    </Grid>
  
</Grid>

            </Box>




            <div class="container" style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              gap: "15px",
              marginBottom: "40px",
              marginLeft:"8px",
             // width:"1160px",
              marginTop:"-10px",
              marginRight:"10px",
              width:"98%"
              
            }}>
    <div class="box" style={{
      backgroundColor: "#FFB45C",
      padding: "20px",
      borderRadius: "24px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      height:"103px",
      minHeight:"100.748px",
      //width:"255px",
      
      flex: "1 1 calc(20% - 20px)", /* Adjust width and margin */
      //minWidth: "200px", /* Optional: to handle very small screens */
      color: "#000",
      boxShadow: "0px 4px 20px -10px rgba(0, 0, 0, 0.10)"
      }}>
      <div>
        <div class="value" style={{
          fontFmaily : "Satoshi, sans-serif",
          fontSize: "23px",
          fontWeight: "700",
          color : "black"
        }}>{roivalue}
        </div>

        <div class="label" style={{
          fontFmaily : "Satoshi, sans-serif",
          fontSize: "17px",
          fontWeight: "400",
          color : "black",
          marginTop: "10px"
        }}>Total Profit</div>
      </div>

      <img style={{

        marginTop:"40px"
      }}
      
      src={`${window.constants.asset_path}/images/totalprofit.svg`}  alt="profit" class="icon"/>
    </div>
    <div class="box0" style={{
      backgroundColor: "#D2FD8B",
      padding: "20px",
      borderRadius: "24px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      //width:"255px",
      height:"103px",
      minHeight:"100.748px",
     
      flex: "1 1 calc(20% - 20px)", /* Adjust width and margin */
      //minWidth: "200px", /* Optional: to handle very small screens */
      color: "#000",
      boxShadow: "0px 4px 20px -10px rgba(0, 0, 0, 0.10)"
      }}>
      <div>
        <div class="value" style={{
          fontFmaily : "Satoshi, sans-serif",
          fontSize: "23px",
          fontWeight: "700",
          color : "black"
        }}>{portfoliovalue}
        </div>

        <div class="label" style={{
          fontFmaily : "Satoshi, sans-serif",
          fontSize: "17px",
          fontWeight: "400",
          color : "black",
          marginTop: "10px",
          marginRight:"5px"
        }}>Active Portfolio Value</div>
      </div>
      <img style={{

marginTop:"50px"
}}src={`${window.constants.asset_path}/images/tpv.svg`}  alt="tpv" class="icon"/>
    </div>

    <div class="box1" style={{
      backgroundColor: "#FFE144",
      padding: "20px",
      borderRadius: "24px",
      display: "flex",
      alignItems: "center",
      //width:"255px",
      height:"103px",
      minHeight:"100.748px",
      
      justifyContent: "space-between",
      flex: "1 1 calc(20% - 20px)", /* Adjust width and margin */
      //minWidth: "200px", /* Optional: to handle very small screens */
      color: "#000",
      boxShadow: "0px 4px 20px -10px rgba(0, 0, 0, 0.10)"
      }}>
      <div>
        <div class="value" style={{
          fontFmaily : "Satoshi, sans-serif",
          fontSize: "23px",
          fontWeight: "700",
          color : "black"
        }}>{PortfolioRoivalue}
        </div>
        <div class="label" style={{
          fontFmaily : "Satoshi, sans-serif",
          fontSize: "17px",
          fontWeight: "400",
          color : "black",
          marginTop: "10px"
        }}>ROI ({roi_days} Days)</div>
      </div>
      <img style={{marginTop:"50px"}}src={`${window.constants.asset_path}/images/roi.svg`}  alt="roi" class="icon"/>
    </div>
    <div class="box2"  
    style={{
      backgroundColor: "#48CEFF",
      padding: "20px",
      borderRadius: "24px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      //width:"255px",
      height:"103px",
      minHeight:"100.748px",
      flex: "1 1 calc(20% - 20px)", /* Adjust width and margin */
      //minWidth: "200px", /* Optional: to handle very small screens */
      color: "#000",
      boxShadow: " 0px 4px 20px -10px rgba(0, 0, 0, 0.10)"
      }}>
      <div>
        <div class="value" style={{
          fontFmaily : "Satoshi, sans-serif",
          fontSize: "23px",
          fontWeight: "700",
          color : "black"
        }}>{currentwallet}</div>
        <div class="label" style={{
          fontFmaily : "Satoshi, sans-serif",
          fontSize: "17px",
          fontWeight: "400",
          color : "black",
          marginTop: "10px"
        }}>Wallet</div>
      </div>
      <img style={{marginTop:"50px"}}src={`${window.constants.asset_path}/images/wallet.svg`}  alt="wallet" class="icon"/>
    </div>
  </div>
  {/* <Box
  component="main"
  sx={{
    flexGrow: 1,
    p: 3,
    margin: "-30px 25px 10px 20px",
    padding: "15px",
    border: "1px solid #ddd",
    borderRadius: "24px",
    backgroundColor: "#fff",
    color: "#333",
    position: "relative",
    textTransform: "none",
  }}
>
  <h3 style={{ fontWeight: "600", color: "black", fontSize: "20px" }}>
    Thesis
  </h3>

  {retrievalLoading ? (
    <p>Loading Thesis...</p>
  ) : (
    <div
      style={{
        width: "100%",
        height: "auto",
        padding: "10px",
        marginTop: "10px",
        borderRadius: "8px",
        border: "1px solid #ccc",
        backgroundColor: "#f9f9f9",
        color: "#333",
        fontFamily: "inherit",
      }}
    >
      {thesisContent ? thesisContent : 'No description available.'}
    </div>
  )}

  
</Box> */}


  <Dialog
      open={showModal}
      onClose={handleCloseModal}
      PaperProps={{
        sx: {
          position: mediaQueryVar ===false ? 'fixed' : 'static',
          bottom: mediaQueryVar === false? '0' : '',
          left: mediaQueryVar === false? '0' : '',
          right: mediaQueryVar === false? '0' : '',
          margin: '0',
          maxWidth: '100%',
          maxHeight: '70%', // Adjust max height if needed
          borderRadius:mediaQueryVar === false? "24px 24px 0 0":"24px",
          padding:mediaQueryVar === false? '10px' : '10px',
          alignItems:"center",
          overflow:"hidden"
        }
      }}
    >
      {mediaQueryVar === false &&(
      <div style={{ display: 'flex', justifyContent: 'center' }}>
   <button style={{
    width: '48px',
    height: '4px',
    borderRadius: '100px',
    background: '#E2E2E2',
    border: 'none',
    margin: '10px',

  }}></button></div>)}
      <div style={{ padding: '20px', width: '400px', margin: '0',maxHeight:"589px" }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px',marginTop:"-10px" }}>
          <Typography style={{ fontWeight: '500', fontSize: '20.767px', paddingLeft:"100px" }} gutterBottom>
            Add To Portfolio
          </Typography>
          <div className="primaryButton">
          <img
            className="frameIcon"
            alt=""
            onClick={handleCloseModal}
            src="/images/frame17.svg"
            style={{ background: "#F3F3F3", width: "25px", height: "25px", marginRight: mediaQueryVar === false? '20px' : '',
            }}
         />
          </div>
        </div>
        <div style={{ 
          display: 'flex', 
          marginLeft: mediaQueryVar === false? '15px' : '',
          marginRight: mediaQueryVar === false? '15px' : '',

          flexDirection: 'column', marginBottom: '10px', }}>
          <Typography style={{ fontSize:"13.845px",fontWeight: '500', marginBottom: '5px', 
            color:'black',
             }}>
            Select Portfolio
          </Typography>
          <div style={{ marginTop: '5px' }}>
            {slugpopupdata.slugname}
            <CreatableSelect
                  isClearable
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      borderRadius: '12px',
                      border: '1px solid #1877F2',
                      boxShadow: 'none',
                      minHeight: '40px',
                      
                    }),
                    menu: (provided) => ({
                      ...provided,
                      borderRadius: '8px',
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      color: '',
                    }),
                    dropdownIndicator: (provided) => ({
                      ...provided,
                      color: '#1877F2', // Add color to the dropdown indicator
                      border:"1px solid #1877F2",
                      padding:"5px",
                      margin:"8px",
                      borderRadius:"3.786px",
                      '&:hover': {
                        color: '#1877F2', // Add hover color to the dropdown indicator
                      },
                    }),
                    indicatorSeparator: (provided) => ({
                      display: 'none', // Hide the vertical mark
                    }),
                  }}
                  placeholder=""
                  onClick={(e) => handleOpenSelect()}
                  onChange={(options) => handleChange1(options)}

                  options={[
                      ...options // Existing options
                  ]}
                  value={selectedOption}
                  
                />
          </div>
          {showAlert && (
        <Alert severity="error" sx={{ marginBottom: '10px' }}>
          {errorMessage}
        </Alert>
        
      )}
      
        </div>
        <Typography style={{ fontSize: '13.845px', fontWeight: '500', color: '#000', marginTop: '16px', marginBottom: '10px',marginLeft:mediaQueryVar ===false?'15px':'' }}>
        Usable Wallet Value:
          <span style={{ fontSize: '12px', color: 'gray', marginTop: '-10px', marginBottom: '10px',marginLeft:"10px" }}>
          {Usablewallet}</span>
        </Typography>
            
        <div style={{maxHeight:"150px" ,overflowY :"scroll",
          marginLeft:mediaQueryVar ===false?'10px':'',
          marginRight:mediaQueryVar ===false?'10px':'',
          display: 'flex', flexDirection: 'column', 
          marginBottom: '10px',
          '&::-webkit-scrollbar': {
              display:"none"
              },
               '-ms-overflow-style': 'none',  
               'scrollbar-width': 'none'
          
          }}>
          
          {loadermodal ? (
            <div style={{ textAlign: 'center', marginTop: '10px' }}>
              <CircularProgress />
            </div>
          ) : (
            <>
              {symbolslugdata.map((obj, idx) => (
                
                <div key={idx} style={{ width:"100%",padding: "8px", background: '#F3F3F3', borderRadius: '8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px' }}>
                {/* Slug Details */}
                {/* Checkbox */}
                <Checkbox  style={{ display: 'flex', alignItems: 'center', flex: 0, minWidth: 0, marginRight:"10px" }} checked={isChecked[idx]} onChange={(event) => handleCheckboxChange(event, idx)} />
                <div style={{ display: 'flex', alignItems: 'center', flex: 0, minWidth: 0 }}>
                  <img style={{ marginRight: '10px', border: "2px solid #000", borderRadius: "19px", height: "32px", width: "33.493px" }} alt="" src={obj.photo} />
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography sx={{ fontWeight: '500', marginBottom: '1px', fontSize: "11.249px", color: "#7F7F80" }}>{obj.slug_name}</Typography>
                    <Typography sx={{ fontSize: '14px', fontWeight: '500', color: 'black', margin: '0' }}>{obj.slug_title}</Typography>
                  </div>
                </div>
            
                {/* Price Details */}
                <div style={{ display: 'flex', flexDirection: 'column', marginRight:"-100px",alignItems: 'center', flex: 1 }}>
                  <Typography sx={{ fontWeight: '500', marginBottom: '2px', fontSize: "11.249px", color: "#7F7F80" }}>PRICE</Typography>
                  <Typography sx={{ fontSize: '12.114px', fontWeight: '500', color: 'black', margin: '0' }}>{obj.current_price}</Typography>
                </div>
                <div>
                <TextField
                  size="small"
                  InputProps={{
                    style: {
                    width: "140px",
                    height: "40px",
                    borderRadius:"12px",
                    border:"1px solid #1877F2",
                    textAlign: "center",
                    fontSize: "11px", // Adjust padding to decrease height
                    lineHeight: "1", // Ensure single line height for the input
                    boxShadow: "none", // Remove shadow if any
                    background: "#FFF",
                    fontWeight:"600",
                    fontfamily:"arial"
                    },
                  }}
                  type="number"
                  placeholder="Enter number of Units"
                  className="placeholder-text"
                  value={unitValues[idx] || ""} // Set the value from state
                  onChange={(event) => handleUnitChange(event, idx)} // Pass index to handleUnitChange
                />
                </div>
                
              </div>
              ))}
            </>
          )}
        </div>
        <Button
          style={{color:"white",marginLeft:mediaQueryVar ===false?'10px':'',
            marginRight:mediaQueryVar ===false?'10px':'',background: '#1877F2', textTransform:"none",width: mediaQueryVar ===false?'95%':'100%', height:"45px", marginTop: '8px', fontWeight: '700', fontSize: '15.575px', borderRadius:"23.363px" }}
          variant="contained"
          color="primary"
          size="large"
          onClick={handleClickphotoGallery}
          disabled={!isButtonEnabled}
        >
          Buy Now
        </Button>
        <Button
          style={{
            marginLeft:mediaQueryVar ===false?'10px':'',
            marginRight:mediaQueryVar ===false?'10px':'',
            width: mediaQueryVar ===false?'95%':'100%',
            border:"1px solid #1877F2",borderRadius:"23.363px",marginTop:"10px",color:"#1877F2",textTransform:"none", padding: '10px', fontWeight: '700', fontSize: '15.575px' }}
          variant="outline"
          size="large"
          onClick={() => setIsDialogOpen(true)}
          disabled={isButtonEnabled}
        >
          Create new portfolio
        </Button>
      </div>
    </Dialog>

    <Dialog
      open={isDialogOpen}
      onClose={() => setIsDialogOpen(false)}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingRight: '40px' }}>
        Create New Portfolio
        <IconButton
          aria-label="close"
          onClick={() => {
            setIsDialogOpen(false);
            setNewOptionValue("");
          }}
          style={{ position: "absolute", right: '13px', top: '8px' }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
      <div>
      <TextField
        autoFocus
        label="Enter Name"
        value={newOptionValue}
        onChange={handleChange2}
        fullWidth
        margin="dense"
      />
      {showAlert && (
        <Alert severity="error" sx={{ marginTop: '8px' }}>
          Hyphens are not allowed in the name.
        </Alert>
      )}
    </div>
      </DialogContent>
      <DialogActions style={{ marginRight: '14px' }}>
        <Button
          onClick={() => {
            setIsDialogOpen(false);
            setNewOptionValue("");
          }}
          style={{ fontWeight: 'bold' }}
          variant="outlined"
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={handleDialogSubmit}
          variant="contained"
          color="primary"
          style={{ fontWeight: 'bold' }}
          disabled={!newOptionValue.trim()}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
      <Snackbar
        open={snackbaropen}
        autoHideDuration={5000}
        onClose={closesnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity={severity}>{msgforUpdatePortfolio}</Alert>
      </Snackbar>



            
{initialPage &&   <Box
        component="main"
        sx={{
         
          flexGrow: 1,
          p: 3,
          
        }}
      >

        <Button size="small" color="primary"onClick={handleGoBack} edge="start"  aria-label="menu">
            <ArrowBackIcon /> Go Back
          </Button>
        <div style={{borderRadius:"10px",background:"white",padding:"10px"}}>
        <div>
          <h3>Portfolio</h3>
          
        </div>
        <div className="frameParent8">
          <div className="frameParent9" style={{ padding: "10px" }}>
            <img className="frameIcon6" alt="" src={window.constants.asset_path +'/images/frame20.svg'} />
            <div className="youDontHaveContainer">
            {nodatafoundmsgtype === 'zeroholding' ? (
      <strong>
        <h3 className="youDontHave" style={{ fontWeight: "400", color: "black" }}>
        You have  0 Holdings at the Moment
        </h3>
      
      </strong>
    ) : (
      <strong>
      <h3 className="youDontHave" style={{ fontWeight: "400", color: "black" }}>
      Create New 

      </h3>
      <h3 style={{ fontWeight: "400", color: "black" }}>Portfolio </h3>
    </strong>
    )}
              <button
                style={{
                  backgroundColor: "white",
                  width: "200px",
                  height: "50px",
                  borderRadius: "5px",
                  marginTop: "20px",
                  border: "2px solid #1877F2",
                  color: "#1877F2", fontSize: "12px"
                }}
                onClick={handleCreateNew}
              >
            Add Cryptos to this Portfolio
              </button>
            </div>
          </div>
        </div>
        </div>
      </Box>}


     

        {!initialPage && (
          <>
             <div class="searchtabcontainer" style={{
              paddingTop: "30px",
              paddingBottom: "10px",
              paddingLeft: "15px",
               paddingRight: "10px",
                background: "white",
                 borderRadius: "24px",
                 marginLeft:"22px",
                 marginRight:"22px"
              }}>

<div
  style={{
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "space-between",
    border: "none",
    margin: "0 8px 0 8px",
    fontSize: "14px",
  }}
>
  {mediaQueryVar === true && (
     <div
    
     style={{
       padding: "2px",
       background: "#F7F7F7",
       borderRadius: "46px",
       marginBottom: "10px",
     }}
   >
     <TextField
       style={{
         width: "100%",
         borderRadius: "50px",
         marginTop: mediaQueryVar === true ? "" : "60px",
         fontFamily: "Satoshi, sans-serif",
         borderRadius:"46px",
         border:" 1px #ECECEC solid",
         backgroundColor:"#F7F7F7",
         paddingTop:"2px",
         paddingBottom:"2px",
         paddingLeft:"12px",
         paddingRight:"30px"
       }}
       className="cryptocurrencyListHeaderSearch"
       size="small"
       placeholder="Search Crypto"
       onChange={(e) => setFilter(e.target.value)}
       value={filter}
       InputProps={{
         startAdornment: (
           <InputAdornment position="start">
             <img src={window.constants.asset_path  + '/images/search.svg'}
alt=""  />
           </InputAdornment>
         ),
         style: {
           border: "none", // Remove the border from the input
         },
       }}
       sx={{
         '& .MuiOutlinedInput-root': {
           '& fieldset': {
             borderColor: 'transparent', // Remove the border of the fieldset
           },
           '&:hover fieldset': {
             borderColor: 'transparent', // Remove the border on hover
           },
           '&.Mui-focused fieldset': {
             borderColor: 'transparent', // Remove the border when focused
           },
         },
       }}
     />
   </div>
  )}

  <div style={{ display: "flex", gap: "10px",
    marginRight:"25px"
  }}>
    <Button
      variant="outlined"
      style={{
        backgroundColor:"white",
        color: "#1877F2",
        borderColor: "#1877F2",
        borderWidth: "2px",
        borderStyle: "solid",
        borderRadius: "27px",
        textTransform: "none",
        fontFamily: "Satoshi, sans-serif",
        fontSize: "16px",
        fontWeight: "700",
        padding: "0px 20px",
        width:"auto",
        
      }}
      onClick={() => navigate("recommendations")}
      onMouseDown={(e) => {
        // Change button styles on click
        e.currentTarget.style.backgroundColor = "#1877F2";
        e.currentTarget.style.color = "white";
      }}
      onMouseUp={(e) => {
        // Revert button styles when click is released
        e.currentTarget.style.backgroundColor = "white";
        e.currentTarget.style.color = "#1877F2";
      }}
    >
          <img 
                alt="" 
                src={window.constants.asset_path + '/images/brain_blue_image.png'} 
                width={40} 
                height={40} 
                style={{ 
                  borderRadius: "19px", 
                  width: "36px", 
                  height: "36px", 
                  marginRight: "10px",
                }} 
                />
      Recommendations
    </Button>
    <Button
    variant="outlined"
    style={{
      backgroundColor: "white",
      color: "#1877F2",
      borderColor: "#1877F2",
      borderWidth: "2px",
      borderStyle: "solid",
      borderRadius: "27px",
      textTransform: "none",
      fontFamily: "Satoshi, sans-serif",
      fontSize: "16px",
      fontWeight: "700",
      padding: "5px 20px",
      maxHeight: "40px",
      whiteSpace: "nowrap",
      marginRight:"0px"
    }}
    onClick={() => navigate("detailed-portfolio-analysis")}
    onMouseDown={(e) => {
      e.currentTarget.style.backgroundColor = "#1877F2";
      e.currentTarget.style.color = "white";
    }}
    onMouseUp={(e) => {
      e.currentTarget.style.backgroundColor = "white";
      e.currentTarget.style.color = "#1877F2";
    }}
  >
    <img 
      alt="" 
      src={window.constants.asset_path + '/images/analysis.png'} 
      
      style={{ 
        borderRadius: "19px", 
        width: "34px", 
        height: "34px", 
        marginRight: "10px",
      }} 
    />
    Analysis
  </Button>

    <Button
      variant="outlined"
      style={{
        backgroundColor: "white",
        color: "#1877F2",
        borderColor: "#1877F2",
        borderWidth: "2px",
        borderStyle: "solid",
        borderRadius: "27px",
        textTransform: "none",
        fontFamily: "Satoshi, sans-serif",
        fontSize: "16px",
        fontWeight: "700",
        padding: "5px 20px",
        width:"160px"
      }}
      onClick={() => navigate("order-book")}
      onMouseDown={(e) => {
        // Change button styles on click
        e.currentTarget.style.backgroundColor = "#1877F2";
        e.currentTarget.style.color = "white";
      }}
      onMouseUp={(e) => {
        // Revert button styles when click is released
        e.currentTarget.style.backgroundColor = "white";
        e.currentTarget.style.color = "#1877F2";
      }}
    >
      Order Book
    </Button>
    

  </div>
</div>




<div style={{width: "96%", height: "100%",border: "1px #EFEFEF solid",marginTop:"20px",marginBottom:"0px",marginLeft:"15px"}}></div>

            <div style={{
              width:"auto",
              border: "1px solid white",  
              borderRadius: "8px", 
              padding: "25px 25px", 
              paddingRight:"-50px",
              
              marginRight: "0px",
              marginLeft:"-10px", 
              boxShadow: "0 2px 4px rgba(0,0,0,0)", 
              backgroundColor: "white" ,
              marginTop:"0px"
            }}>
              <TableContainer style={{ overflowX: "hidden", border: "1px solid #DADEDF", borderRadius: "5px", padding:0 }}>
                <Table
                  className="non_responsiveTable" aria-label="customized table" 
                  style={{ borderCollapse: "collapse" }}
                >
                  <TableHead>
                    <TableRow>
                        
<StyledTableCell className="cryptotableviewHeader" style={{ borderRadius: "8px 0px 0px 8px", border: 0, padding: 0, width:"25%" }}>
  <button disabled style={{ textAlign: "left", color: "#727376", fontSize: mediaQueryVar === false ? "11px" : "16px", width:"100%", height: "45px", border: "none", background: "white", padding: "12px 12px", margin: "0px 0px 10px 0px", boxShadow: "0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)" }}>
    Symbol
  </button>
</StyledTableCell>

<StyledTableCell className="cryptotableviewHeader" style={{ padding: 0, width:"20%" }}>
  <button disabled style={{ textAlign: "left", color: "#727376", fontSize: mediaQueryVar === false ? "11px" : "16px", width:"100%", height: "45px", border: "none", background: "white", padding: "12px 12px", margin: "0px 0px 10px 0px", boxShadow: "0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)" }}>
    Current value
  </button>
</StyledTableCell>

<StyledTableCell className="cryptotableviewHeader" style={{ padding: 0, width:"15%" }}>
  <button disabled style={{ textAlign: "left", color: "#727376", fontSize: mediaQueryVar === false ? "11px" : "16px", width:"100%", height: "45px", border: "none", background: "white", padding: "12px 12px", margin: "0px 0px 10px 0px", boxShadow: "0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)" }}>
    Units
  </button>
</StyledTableCell>

<StyledTableCell className="cryptotableviewHeader" style={{ padding: 0, width:"18%" }}>
  <button disabled style={{ textAlign: "left", color: "#727376", fontSize: mediaQueryVar === false ? "11px" : "16px", width:"100%", height: "45px", border: "none", background: "white", padding: "12px 12px", margin: "0px 0px 10px 0px", boxShadow: "0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)" }}>
    Purchase value
  </button>
</StyledTableCell>

<StyledTableCell className="cryptotableviewHeader" style={{ padding: 0, width:"15%" }}>
  <button onClick={() => handleSort('roi')} style={{ textAlign: "left", color: "#727376", fontSize: mediaQueryVar === false ? "11px" : "16px", width:"100%", height: "45px", border: "none", background: "white", padding: "12px 12px", margin: "0px 0px 10px 0px", boxShadow: "0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)" }}>
   ROI {sortOrder.roi === 'asc' ? '↑' : '↓'}
  </button>
</StyledTableCell>


                    </TableRow>
                  </TableHead>
                  {loader === false && (
                    <TableBody>
                      {rows.map((idx) => (
                        <StyledTableRow key={idx} >
                          <StyledTableCell>
                            <Stack>
                              <Skeleton
                              sx={{marginTop:'10px'}}
                                variant="rounded"
                                // width={mediaQueryVar === false ? "100%" : 200}
                                height={40}
                              />
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell >
                            <Stack>
                              <Skeleton
                                 sx={{marginTop:'10px'}}
                                variant="rounded"
                                // width={mediaQueryVar === false ? "100%" : 200}
                                height={40}
                              />
                            </Stack>
                          </StyledTableCell>
                          <StyledTableCell>
                            <Stack>
                              <Skeleton
                                 sx={{marginTop:'10px'}}
                                variant="rounded"
                                // width={mediaQueryVar === false ? "100%" : 200}
                                height={40}
                              />
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell>
                            <Stack>
                              <Skeleton
                                 sx={{marginTop:'10px'}}
                                variant="rounded"
                                // width={mediaQueryVar === false ? "100%" : 200}
                                height={40}
                              />
                            </Stack>
                          </StyledTableCell>
                          <StyledTableCell>
                            <Stack>
                              <Skeleton
                                 sx={{marginTop:'10px'}}
                                variant="rounded"
                                // width={mediaQueryVar === false ? "100%" : 200}
                                height={40}
                              />
                            </Stack>
                          </StyledTableCell>
                          
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  )}
 {sortedData.slice(0, rowsToShow).map((obj, idx) => (
  <React.Fragment key={idx}>
    <StyledTableRow
      style={{
        minHeight: "50px",
        height: "50px",
        backgroundColor: idx % 2 === 0 ? "white" : "#EEE",
        filter: !userData && idx >= 2 ? "blur(4px)" : "none",
        position: "relative",
      }}
    >
      <StyledTableCell
        component="th"
        scope="row"
        style={{
          borderBottom: "none",
          color: "#000",
          fontSize: "16px",
          fontWeight: "500",
          lineHeight: "24px",
          paddingLeft: "16px",
          paddingTop: "8px",
          paddingBottom: "8px",
          borderRight: "1px solid #DADEDF",
          width: "239px",
        }}
      >
        <div
                              style={{ display: "flex", flexDirection: "row",justifyContent:"space-between" }}
                            >
                              <div>
                              <img width={38.77} height={37} style={{borderRadius: "19px", border:"2px solid black"}} alt="" src={obj.logo} />
                            </div>
                              <div className="btcParent">
                              {obj["article_link"] && (
                                <a href={obj.article_link}>
                                  <div>{obj.slug}</div>
                                </a>
                              )}
                              {!obj["article_link"] && 
     <div className="article-link" 
     style={{
      display: "flex",
      justifyContent: "space-between",
      gap: "10px", // Gap between the items
      alignItems: "center"// Centers items vertically (optional)
     }}>
      <div>
    <a href={`../../crypto/${obj.crypto_symbol}`} style={{ color: "black" }}>
      {obj.slug}
    </a></div>
</div>
}

 
     </div>
     <div>
  {obj.category && ( // Check if obj.category is not null or empty
    <div style={{ textDecoration: 'none', marginTop:"4px" }}>
     <Box
  sx={{
    marginTop:"2px",
    background: "var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
    borderRadius: "15px",
    padding: "2px 8px", // No extra padding on the sides
    fontSize: "12px",
    fontWeight: "500",
    color: "#000",
    margin: "0", // Remove any margin
    whiteSpace: "nowrap", // Prevents unexpected line breaks
    wordBreak: "normal", // Prevents words from breaking unnecessarily
    
  }}
>
  {obj.category}
</Box>


    </div>
  )}
</div>
                          </div>
      </StyledTableCell>
      <StyledTableCell
        className="one"
        style={{
          borderBottom: "none",
          color: "#000",
          fontSize: "16px",
          fontWeight: "500",
          lineHeight: "24px",
          paddingLeft: "16px",
          paddingTop: "8px",
          paddingBottom: "8px",
          borderRight: "1px solid #DADEDF",
          width: "239px",
        }}
      >
        {obj.price_today}
      </StyledTableCell>
      <StyledTableCell
        className="one"
        style={{
          borderBottom: "none",
          color: "#000",
          fontSize: "16px",
          fontWeight: "500",
          lineHeight: "24px",
          paddingLeft: "16px",
          paddingTop: "8px",
          paddingBottom: "8px",
          borderRight: "1px solid #DADEDF",
          width: "239px",
        }}
      >
        {obj.total_unit}
      </StyledTableCell>
      <StyledTableCell
        className="one"
        style={{
          borderBottom: "none",
          color: "#000",
          fontSize: "16px",
          fontWeight: "500",
          lineHeight: "24px",
          paddingLeft: "16px",
          paddingTop: "8px",
          paddingBottom: "8px",
          borderRight: "1px solid #DADEDF",
          width: "239px",
        }}
      >
        {obj.blended_cost}
      </StyledTableCell>
      <StyledTableCell
        style={{
          borderBottom: "none",
          color: "#000",
          fontSize: "16px",
          fontWeight: "500",
          lineHeight: "24px",
          paddingLeft: "16px",
          paddingTop: "8px",
          paddingBottom: "8px",
          borderRight: "1px solid #DADEDF",
          width: "239px",
        }}
      >
        {obj.total_roi_value}
      </StyledTableCell>
    </StyledTableRow>

    {idx === 1 && !userData && (
      <StyledTableRow
        style={{
          height: "auto",
          backgroundColor: "transparent",
          border: "none",
          position: "relative",
        }}
      >
        <StyledTableCell
          colSpan={5}
          style={{
            borderBottom: "none",
            padding: "0",
            position: "relative",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            onClick={() => {
              const originalUrl = window.location.pathname + window.location.search;
              sessionStorage.setItem('originalUrl', originalUrl);
              console.log("original url: " + originalUrl);
              handleLoginClick();
            }}
            style={{
              color: "white",
              backgroundColor: "#1877F2",
              textTransform: "none",
              width: mediaQueryVar ? "auto" : "200px",
              height: mediaQueryVar ? "auto" : "45px",
              fontWeight: "600",
              fontSize: "18px",
              borderRadius: "30px",
              padding: "8px 24px",
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
              border: "none",
              cursor: "pointer",
              left:"25vw",
              right:"0%"
            }}
          >
            Login/Signup
          </Button>
        </StyledTableCell>
      </StyledTableRow>
    )}
  </React.Fragment>
))}
                </Table>
                {slugdata.length === 0 && loader === true && (
                  <div style={{ textAlign: "center", marginTop: "20px" }}>
                    <h4>No Data Found</h4>
                  </div>
                )}
                
              </TableContainer>
              
              {rowsToShow < sortedData.length && (
                <div className="loadingButton">
                  <LoadingButton
                    loading={addMoreLoader}
                    loadingPosition="center"
                    variant="outlined"
                    style={{
                      maxWidth: "360px",
                      width: "80vw",
                      backgroundColor: "rgba(67, 97, 238, 0.15)",
                      borderRadius: "6px",
                      border: "none",
                      textTransform: "none",
                      borderStyle: "solid",
                      borderColor: "rgba(67, 97, 238, 0.15)",
                      borderWidth: "1px",
                      color: "#4361ee",
                      width: mediaQueryVar === false ? "80vw" : "180px",
                    }}
                    onClick={() => {
                      loadMore();
                    }}
                  >
                    <span>Load More</span>
                  </LoadingButton>
                </div>
              )}
            </div>
            </div>
            </>
           )}
            
            <Snackbar
              open={snackbaropen}
              autoHideDuration={5000}
              onClose={closesnackbar}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <Alert severity={severity}>{msgforUpdatePortfolio}</Alert>
            </Snackbar>
            {/* <Box
  component="main"
  sx={{
    flexGrow: 1,
    p: 3,
    margin: "20px 25px 10px 20px",
    padding: "15px",
    border: "1px solid #ddd",
    borderRadius: "24px",
    backgroundColor: "#fff",
    color: "#333",
    position: "relative",
    textTransform: "none",
  }}
>
  <h3 style={{ fontWeight: "600", color: "black", fontSize: "20px" }}>
    Thesis
  </h3>

  {retrievalLoading ? (
    <p>Loading Thesis...</p>
  ) : (
    <div
      style={{
        width: "100%",
        height: "auto",
        padding: "10px",
        marginTop: "10px",
        borderRadius: "8px",
        border: "1px solid #ccc",
        backgroundColor: "#f9f9f9",
        color: "#333",
        fontFamily: "inherit",
        whiteSpace: "pre-wrap",
      }}
    >
      {thesisContent ? (
    <div dangerouslySetInnerHTML={{ __html: thesisContent }} />
  ) : (
    'No Thesis available.'
  )}
    </div>
  )}

  
</Box> */}
         
        {loaderForSymbolLink===true &&
         <div >
              <CircularProgress style={{position:"fixed",top:"40vh",left:"45vw"}}/>
          </div> 
          }
      </Box>
      \
    </Box>
    

    ):(

    <Box
      sx={{
        display: "flex",
        height: '100vh' 
      }}
      className="maindiv"
    > 
    <Helmet>
    <title>{mPortfolioName}</title>
    <meta property="og:title" content={mPortfolioName} />
    <meta property="og:description" content="Track and compare shadow portfolios from financial influencers." />
    <meta property="og:type" content="website" />
    <meta name="description" content={mPortfolioName} />
    <meta name="twitter:card" content="Best Crypto Portfolios" />
    <meta name="twitter:title" content={mPortfolioName} />
    <meta name="twitter:description" content="Track and compare shadow portfolios from financial influencers." />
   
  </Helmet> 
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "white",
              color: "#727376",
             
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "white",
              color: "#727376",
             
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>

      <Box
        
        component="main"
        sx={{
      flexGrow: 1,
      p: 2,
      backgroundColor: "white",
      paddingBottom: "2vw",
      minHeight: "100vh",
     
      overflowY: "auto",
        }}
      >
     {isLoginModalOpen && (
        <LoginPopUp 
          isLoginModalOpen={isLoginModalOpen}
          setLoginModalOpen={setLoginModalOpen} // Pass the state setter down
          onClose={handleModalClose}
        />
      )}
           
  <div style={{ 
  display: "flex", 
  alignItems: "center", 
  justifyContent: "space-between", 
  marginTop: "0px", 
  marginBottom: "20px" 
}}>
  <Typography
    variant="h6"
    component="div"
    style={{
      fontFamily: "Satoshi, sans-serif",
      fontWeight: 700,
      fontSize: "24px",
      marginLeft:"5px",
      
    }}
  >
    {viewprofile === true ? (
    // <a
    //     href=""
    //     onClick={handleProfileClick}
    //     style={{
    //         color: "inherit",
    //     }}
    // >
      <span> {profilename}
        <Button
        variant="outlined"
        style={{
          backgroundColor: "white",
          color: "#1877F2",
          borderColor: "#1877F2",
          borderWidth: "2px",
          borderStyle: "solid",
          borderRadius: "27px",
          textTransform: "none",
          fontFamily: "Satoshi, sans-serif",
          fontSize: "16px",
          fontWeight: "700",
          padding: "3px 14px",
          maxHeight: "40px",
          whiteSpace: "nowrap",
          marginRight: "20px",
          marginLeft: "10px"
        }}
        onClick={handleProfileClick}
        onMouseDown={(e) => {
          e.currentTarget.style.backgroundColor = "#1877F2";
          e.currentTarget.style.color = "white";
        }}
        onMouseUp={(e) => {
          e.currentTarget.style.backgroundColor = "white";
          e.currentTarget.style.color = "#1877F2";
        }}
      >
        Profile
      </Button></span>
   
     
    
  
) : (
  <span>{profilename}</span>
)}

  </Typography>

  
  <IconButton
    color="inherit"
    aria-label="open drawer"
    edge="end"
    onClick={handleDrawerToggle}
    sx={{ display: { sm: "none" } }}
  >
   <img src={window.constants.asset_path  + '/images/menubar.svg'}
 alt="Menu Icon" style={{ width: "24px", height: "24px",marginRight:"7px" }} />
  </IconButton>
</div>

{mediaQueryVar === false && (
     
     <TextField
                 style={{
                   width: "97%",
                   borderRadius: "46px",
                   marginTop: mediaQueryVar ? "" : "-7px",
                   fontFamily: "Satoshi, sans-serif",
                   marginRight: "25px",
                   border: "1px #b6b6b8 solid",
                   backgroundColor: "white",
                   paddingTop: "0px",
                   paddingBottom: "2px",
                   paddingLeft: "12px",
                   paddingRight: "30px",
                   marginBottom: "10px",
                   fontSize:"14px",
                   fontWeight:"400"
                 }}
                 size="small"
                 placeholder="Search Crypto"
                 onChange={(e) => setFilter(e.target.value)}
                 value={filter}
                 InputProps={{
                   startAdornment: (
                     <InputAdornment position="start">
                       <img width={13} height={13} src={window.constants.asset_path + '/images/search.svg'} alt="search Icon" />
                     </InputAdornment>
                   ),
                   style: {
                     border: "none", // Remove the border from the input
                   },
                 }}
                 sx={{
                   '& .MuiOutlinedInput-root': {
                     '& fieldset': {
                       borderColor: 'transparent', // Remove the border of the fieldset
                     },
                     '&:hover fieldset': {
                       borderColor: 'transparent', // Remove the border on hover
                     },
                     '&.Mui-focused fieldset': {
                       borderColor: 'transparent', // Remove the border when focused
                     },
                   },
                 }}
               />
   
  )}
                
              
            
<div class="container" style={{
              display: "flex",
              justifyContent: "space-around",
              flexWrap: "wrap",
              gap:"15px",
              marginBottom: "25px",
            }}>
    <div class="box" style={{
      backgroundColor: "#FFB45C",
      paddingTop:"0px",
      paddingBottom:"0px",
      paddingLeft:"8px",
      paddingRight:"8px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flex: "1 1 calc(50% - 10px)", /* Adjust width and margin */
      minWwidth: "100px", /* Optional: to handle very small screens */
      color: "#000",
      marginLeft:"-10px",
      height:"70px",
      boxShadow: "0px 2.7972731590270996px 13.986366271972656px -6.993183135986328px rgba(0, 0, 0, 0.10)",
      borderRadius: "16.78px"      }}>
      <div>
        <div class="value" style={{
          fontFmaily : "Satoshi, sans-serif",
          fontSize: "17px",
          fontWeight: "700",
          color : "black"
        }}>{roivalue}
        </div>

        <div class="label" style={{
          fontFmaily : "Satoshi, sans-serif",
          fontSize: "13px",
          fontWeight: "400",
          color : "black",
          marginTop: "5px"
        }}>Total Profit</div>
      </div>

      <img width="23px" height="22px" style={{marginTop:"30px"}} src={`${window.constants.asset_path}/images/totalprofit.svg`}  alt="profit" class="icon"/>
    </div>
    <div class="box0" style={{
      backgroundColor: "#D2FD8B",
      paddingTop:"0px",
      paddingBottom:"0px",
      paddingLeft:"8px",
      paddingRight:"8px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flex: "1 1 calc(50% - 10px)", /* Adjust width and margin */
      minWidth: "80px", /* Optional: to handle very small screens */
      color: "#000",
      height:"70px",
      marginRight:"-10px",
      boxShadow: "0px 2.7972731590270996px 13.986366271972656px -6.993183135986328px rgba(0, 0, 0, 0.10)",
      borderRadius: "16.78px"      }}>
      <div>
        <div class="value" style={{
          fontFmaily : "Satoshi, sans-serif",
          fontSize: "17px",
          fontWeight: "700",
          color : "black"
        }}>{portfoliovalue}
        </div>

        <div class="label" style={{
          fontFmaily : "Satoshi, sans-serif",
          fontSize: "13px",
          fontWeight: "400",
          color : "black",
          marginTop: "5px",
          marginRight:"-5px"
        }}>Active Portfolio Value</div>
      </div>
      <img width="23px" height="22px" style={{

marginTop:"30px", 
}}src={`${window.constants.asset_path}/images/tpv.svg`}  alt="tpv" class="icon"/>
    </div>

    <div class="box1" style={{
      backgroundColor: "#FFE144",
      paddingTop:"0px",
      paddingBottom:"0px",
      paddingLeft:"8px",
      paddingRight:"8px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flex: "1 1 calc(50% - 10px)", /* Adjust width and margin */
      minWidth: "100px", /* Optional: to handle very small screens */
      color: "#000",
      height:"70px",
      marginLeft:"-10px",
      boxShadow: "0px 2.7972731590270996px 13.986366271972656px -6.993183135986328px rgba(0, 0, 0, 0.10)",
      borderRadius: "16.78px"       }}>
      <div>
        <div class="value" style={{
          fontFmaily : "Satoshi, sans-serif",
          fontSize: "17px",
          fontWeight: "700",
          color : "black"
        }}>{PortfolioRoivalue}
        </div>
        <div class="label" style={{
          fontFmaily : "Satoshi, sans-serif",
          fontSize: "13px",
          fontWeight: "400",
          color : "black",
          marginTop: "5px"
        }}>ROI ({roi_days} Days)</div>
      </div>
      <img width="23px" height="22px" style={{marginTop:"30px"}}src={`${window.constants.asset_path}/images/roi.svg`}  alt="roi" class="icon"/>
    </div>
    <div class="box2"  
    style={{
      backgroundColor: "#48CEFF",
      paddingTop:"0px",
      paddingBottom:"0px",
      paddingLeft:"8px",
      paddingRight:"8px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flex: "1 1 calc(50% - 10px)", /* Adjust width and margin */
      minWidth: "100px", /* Optional: to handle very small screens */
      color: "#000",
      height:"70px",
      marginRight:"-10px",
      boxShadow: "0px 2.7972731590270996px 13.986366271972656px -6.993183135986328px rgba(0, 0, 0, 0.10)",
      borderRadius: "16.78px"       }}>
      <div>
        <div class="value" style={{
          fontFmaily : "Satoshi, sans-serif",
          fontSize: "17px",
          fontWeight: "700",
          color : "black"
        }}>{currentwallet}</div>
        <div class="label" style={{
          fontFmaily : "Satoshi, sans-serif",
          fontSize: "13px",
          fontWeight: "400",
          color : "black",
          marginTop: "5px"
        }}>Wallet</div>
      </div>
      <img width="23px" height="22px" style={{marginTop:"30px"}}src={`${window.constants.asset_path}/images/wallet.svg`}  alt="wallet" class="icon"/>
    </div>
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        margin: '0 0px',
        marginLeft:"-35px",
        marginBottom: '2px',
        fontSize: '14px',
        gap: '7px',
        flexWrap: 'nowrap',
        overflow: 'hidden',
        width: '95%',
      }}
    >
      <Button
        variant="outlined"
        style={{
          backgroundColor: 'white',
          color: '#1877F2',
          borderColor: '#1877F2',
          borderWidth: '2px',
          borderStyle: 'solid',
          borderRadius: '27px',
          textTransform: 'none',
          fontFamily: 'Satoshi, sans-serif',
          fontSize: isSmallScreen ? '14px' : '16px',
          fontWeight: '700',
          padding: isSmallScreen ? '5px 10px' : '5px 20px',
          maxHeight: '40px',
          whiteSpace: 'nowrap',
          flexShrink: 1,
          minWidth: 0,
          display: 'flex',
          alignItems: 'center',
          overflow: 'hidden',
        }}
        onClick={() => navigate('detailed-portfolio-analysis')}
        onMouseDown={(e) => {
          e.currentTarget.style.backgroundColor = '#1877F2';
          e.currentTarget.style.color = 'white';
        }}
        onMouseUp={(e) => {
          e.currentTarget.style.backgroundColor = 'white';
          e.currentTarget.style.color = '#1877F2';
        }}
      >
        <img
          alt=""
          src={window.constants.asset_path + '/images/analysis.png'}
          width={isSmallScreen ? 24 : 36}
          height={isSmallScreen ? 24 : 36}
          style={{
            borderRadius: '19px',
            marginRight: isSmallScreen ? '5px' : '10px',
          }}
        />
        Analysis
      </Button>
      <Button
        variant="outlined"
        style={{
          backgroundColor: 'white',
          color: '#1877F2',
          borderColor: '#1877F2',
          borderWidth: '2px',
          borderStyle: 'solid',
          borderRadius: '27px',
          textTransform: 'none',
          fontFamily: 'Satoshi, sans-serif',
          fontSize: isSmallScreen ? '14px' : '16px',
          fontWeight: '700',
          padding: isSmallScreen ? '5px 10px' : '5px 20px',
          maxHeight: '40px',
          whiteSpace: 'nowrap',
          flexShrink: 1,
          minWidth: 0,
          display: 'flex',
          alignItems: 'center',
          overflow: 'hidden',
        }}
        onClick={() => navigate('recommendations')}
        onMouseDown={(e) => {
          e.currentTarget.style.backgroundColor = '#1877F2';
          e.currentTarget.style.color = 'white';
        }}
        onMouseUp={(e) => {
          e.currentTarget.style.backgroundColor = 'white';
          e.currentTarget.style.color = '#1877F2';
        }}
      >
        <img
          alt=""
          src={window.constants.asset_path + '/images/brain_blue_image.png'}
          width={isSmallScreen ? 24 : 36}
          height={isSmallScreen ? 24 : 36}
          style={{
            borderRadius: '19px',
            marginRight: isSmallScreen ? '5px' : '10px',
          }}
        />
        Recommendations
      </Button>
    </div>
  </div>
  
  <div>
        <Box
            sx={{
              borderRadius: "10px",
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "0px",
              color:"#1877F2",
            }}
          >
            <Button
              onClick={handlePopoverOpen}
            >
              
              <div style={{
              marginTop:"-190px",
              marginRight:"-27px",
              marginBottom:"-80px",
              padding:"6px 9px"
              }}>
              <img width="23px"
              src={window.constants.asset_path + '/images/filter2.svg'}/></div>
            </Button>

            <Popover
              open={openfilter}
              anchorEl={anchorEl}
              onClose={handleCloseeoption}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              style={{
                marginTop:"-25px"
              }}
            >
             <MenuItem
                selected={selectedFilter === "roi_HL"}
                onClick={handleClickROIHL}
              >
                ROI High to Low
              </MenuItem>
              <MenuItem
                selected={selectedFilter === "roi_LH"}
                onClick={handleClickROILH}
              >
                ROI Low to High
              </MenuItem>
              
              <Box sx={{ display: "flex", padding: "8px" }}>
                <Button
                  size="small"
                  sx={{ textTransform: "capitalize", marginLeft: "10px" }}
                  onClick={handleClearAll}
                  startIcon={<ClearIcon />}
                >
                  Clear All
                </Button>
              </Box>
            </Popover>
          </Box>

  </div>
  
  {/* <Box
  component="main"
  sx={{
    flexGrow: 1,
    p: 3,
    margin: "-10px 0px 10px 0px",
    padding: "15px",
    border: "1px solid #ddd",
    borderRadius: "24px",
    backgroundColor: "#fff",
    color: "#333",
    position: "relative",
    textTransform: "none",
  }}
>
  <h3 style={{ fontWeight: "600", color: "black", fontSize: "20px" }}>
    Thesis
  </h3>

  {retrievalLoading ? (
    <p>Loading description...</p>
  ) : (
    <div
      style={{
        width: "100%",
        height: "auto",
        padding: "10px",
        marginTop: "10px",
        borderRadius: "8px",
        border: "1px solid #ccc",
        backgroundColor: "#f9f9f9",
        color: "#333",
        fontFamily: "inherit",
      }}
    >
      {thesisContent ? thesisContent : 'No description available.'}
    </div>
  )}

  
</Box> */}
  <Dialog
      open={showModal}
      onClose={handleCloseModal}
      PaperProps={{
        sx: {
          position: mediaQueryVar ===false ? 'fixed' : 'static',
          bottom: mediaQueryVar === false? '0' : '',
          left: mediaQueryVar === false? '0' : '',
          right: mediaQueryVar === false? '0' : '',
          margin: '0',
          maxWidth: '100%',
          minHeight: '50%', // Adjust max height if needed
          borderRadius:mediaQueryVar === false? "24px 24px 0 0":"24px",
          padding:mediaQueryVar === false? '10px' : '10px',
          alignItems:"center",
          overflow:"hidden"
        }
      }}
    >
      {mediaQueryVar === false &&(
      <div style={{ display: 'flex', justifyContent: 'center' }}>
   <button style={{
    width: '48px',
    height: '4px',
    borderRadius: '100px',
    background: '#E2E2E2',
    border: 'none',
    margin: '10px',

  }}></button></div>)}
      <div style={{ padding: '20px', width: mediaQueryVar===false?'100%':'400px', margin: '0',maxHeight:"589px" }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px',marginTop:"-10px" }}>
          <Typography style={{ fontWeight: '500', fontSize: '20.767px', paddingLeft:"20%" }} gutterBottom>
            Add To Portfolio
          </Typography>
          <div className="primaryButton">
          <img
            className="frameIcon"
            alt=""
            onClick={handleCloseModal}
            src="/images/frame17.svg"
            style={{ background: "#F3F3F3", width: "25px", height: "25px", marginRight: mediaQueryVar === false? '20px' : '',
            }}
         />
          </div>
        </div>
        <div style={{ 
          display: 'flex', 
          marginLeft: mediaQueryVar === false? '0px' : '',
          marginRight: mediaQueryVar === false? '0px' : '',

          flexDirection: 'column', marginBottom: '10px', }}>
          <Typography style={{ fontSize:"13.845px",fontWeight: '500', marginBottom: '5px', 
            color:'black',
             }}>
            Select Portfolio
          </Typography>
          <div style={{ marginTop: '5px' }}>
            {slugpopupdata.slugname}
            <CreatableSelect
                  isClearable
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      borderRadius: '12px',
                      border: '1px solid #1877F2',
                      boxShadow: 'none',
                      minHeight: '40px',
                      
                    }),
                    menu: (provided) => ({
                      ...provided,
                      borderRadius: '8px',
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      color: '',
                    }),
                    dropdownIndicator: (provided) => ({
                      ...provided,
                      color: '#1877F2', // Add color to the dropdown indicator
                      border:"1px solid #1877F2",
                      padding:"5px",
                      margin:"8px",
                      borderRadius:"3.786px",
                      '&:hover': {
                        color: '#1877F2', // Add hover color to the dropdown indicator
                      },
                    }),
                    indicatorSeparator: (provided) => ({
                      display: 'none', // Hide the vertical mark
                    }),
                  }}
                  placeholder=""
                  onClick={(e) => handleOpenSelect()}
                  onChange={(options) => handleChange1(options)}

                  options={[
                      ...options // Existing options
                  ]}
                  value={selectedOption}
                  
                />
          </div>
          {showAlert && (
        <Alert severity="error" sx={{ marginBottom: '10px' }}>
          {errorMessage}
        </Alert>
        
      )}
        </div>
        <Typography style={{ fontSize: '13.845px', fontWeight: '500', color: '#000', marginTop: '16px', marginBottom: '10px',marginLeft:mediaQueryVar ===false?'0px':'' }}>
        Usable Wallet Value:
          <span style={{ fontSize: '12px', color: 'gray', marginTop: '-10px', marginBottom: '10px',marginLeft:"10px" }}>
          {Usablewallet}</span>
        </Typography>
            
        <div style={{maxHeight:"150px" ,overflowY :"scroll",
          marginLeft:mediaQueryVar ===false?'0px':'',
          marginRight:mediaQueryVar ===false?'0px':'',
          display: 'flex', flexDirection: 'column', 
          marginBottom: '10px',
          '&::-webkit-scrollbar': {
              display:"none"
              },
               '-ms-overflow-style': 'none',  
               'scrollbar-width': 'none'
          
          }}>
          
          {loadermodal ? (
            <div style={{ textAlign: 'center', marginTop: '10px' }}>
              <CircularProgress />
            </div>
          ) : (
            <>
              {symbolslugdata.map((obj, idx) => (
                <div key={idx} style={{ width:"100%",padding: "8px", background: '#F3F3F3', borderRadius: '8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px' }}>
                {/* Slug Details */}
                {/* Checkbox */}
                <Checkbox  style={{ display: 'flex', alignItems: 'center', flex: 0, minWidth: 0, marginRight:"10px" }} checked={isChecked[idx]} onChange={(event) => handleCheckboxChange(event, idx)} />
                <div style={{ display: 'flex', alignItems: 'center', flex: 0, minWidth: 0 }}>
                  <img style={{ marginRight: '10px', border: "2px solid #000", borderRadius: "19px", height: "32px", width: "33.493px" }} alt="" src={obj.photo} />
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography sx={{ fontWeight: '500', marginBottom: '1px', fontSize: "11.249px", color: "#7F7F80" }}>{obj.slug_name}</Typography>
                    <Typography sx={{ fontSize: '14px', fontWeight: '500', color: 'black', margin: '0' }}>{obj.slug_title}</Typography>
                  </div>
                </div>
            
                {/* Price Details */}
                <div style={{ display: 'flex', flexDirection: 'column', marginRight:"-100px",alignItems: 'center', flex: 1 }}>
                  <Typography sx={{ fontWeight: '500', marginBottom: '2px', fontSize: "11.249px", color: "#7F7F80" }}>PRICE</Typography>
                  <Typography sx={{ fontSize: '12.114px', fontWeight: '500', color: 'black', margin: '0' }}>{obj.current_price}</Typography>
                </div>
                <div style={{width:"30%"}}>
                <TextField
                  size="small"
                  InputProps={{
                    style: {
                    width: mediaQueryVar===false?"100%":"140px",
                    height: "40px",
                    borderRadius:"12px",
                    border:"1px solid #1877F2",
                    textAlign: "center",
                    fontSize: "11px", // Adjust padding to decrease height
                    lineHeight: "1", // Ensure single line height for the input
                    boxShadow: "none", // Remove shadow if any
                    background: "#FFF",
                    fontWeight:"600",
                    fontfamily:"arial"
                    },
                  }}
                  type="number"
                  placeholder="Enter number of Units"
                  className="placeholder-text"
                  value={unitValues[idx] || ""} // Set the value from state
                  onChange={(event) => handleUnitChange(event, idx)} // Pass index to handleUnitChange
                />
                </div>
              </div>
              ))}
            </>
          )}
        </div>
        <Button
          style={{color:"white",marginLeft:mediaQueryVar ===false?'10px':'',
            marginRight:mediaQueryVar ===false?'10px':'',background: '#1877F2', textTransform:"none",width: mediaQueryVar ===false?'95%':'100%', height:"45px", marginTop: '8px', fontWeight: '700', fontSize: '15.575px', borderRadius:"23.363px" }}
          variant="contained"
          color="primary"
          size="large"
          onClick={handleClickphotoGallery}
          disabled={!isButtonEnabled}
        >
          Buy Now
        </Button>
        <Button
          style={{
            marginLeft:mediaQueryVar ===false?'10px':'',
            marginRight:mediaQueryVar ===false?'10px':'',
            width: mediaQueryVar ===false?'95%':'100%',
            border:"1px solid #1877F2",borderRadius:"23.363px",marginTop:"10px",color:"#1877F2",textTransform:"none", padding: '10px', fontWeight: '700', fontSize: '15.575px' }}
          variant="outline"
          size="large"
          onClick={() => setIsDialogOpen(true)}
          disabled={isButtonEnabled}
        >
          Create new portfolio
        </Button>
      </div>
    </Dialog>

    <Dialog
      open={isDialogOpen}
      onClose={() => setIsDialogOpen(false)}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingRight: '40px' }}>
        Create New Portfolio
        <IconButton
          aria-label="close"
          onClick={() => {
            setIsDialogOpen(false);
            setNewOptionValue("");
          }}
          style={{ position: "absolute", right: '13px', top: '8px' }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
      <div>
      <TextField
        autoFocus
        label="Enter Name"
        value={newOptionValue}
        onChange={handleChange2}
        fullWidth
        margin="dense"
      />
      {showAlert && (
        <Alert severity="error" sx={{ marginTop: '8px' }}>
          Hyphens are not allowed in the name.
        </Alert>
      )}
    </div>
      </DialogContent>
      <DialogActions style={{ marginRight: '14px' }}>
        <Button
          onClick={() => {
            setIsDialogOpen(false);
            setNewOptionValue("");
          }}
          style={{ fontWeight: 'bold' }}
          variant="outlined"
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={handleDialogSubmit}
          variant="contained"
          color="primary"
          style={{ fontWeight: 'bold' }}
          disabled={!newOptionValue.trim()}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
      <Snackbar
        open={snackbaropen}
        autoHideDuration={5000}
        onClose={closesnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity={severity}>{msgforUpdatePortfolio}</Alert>
      </Snackbar>



            
{initialPage &&   <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
        }}
      >

        <Button size="small" color="primary"onClick={handleGoBack} edge="start"  aria-label="menu">
            <ArrowBackIcon /> Go Back
          </Button>
        <div style={{borderRadius:"10px",background:"white",padding:"10px"}}>
        <div>
          <h3>Portfolio</h3>
          
        </div>
        <div className="frameParent8">
          <div className="frameParent9" style={{ padding: "10px" }}>
            <img className="frameIcon6" alt="" src={window.constants.asset_path +'/images/frame20.svg'} />
            <div className="youDontHaveContainer">
            {nodatafoundmsgtype === 'zeroholding' ? (
      <strong>
        <h3 className="youDontHave" style={{ fontWeight: "400", color: "black" }}>
        You have  0 Holdings at the Moment
        </h3>
      
      </strong>
    ) : (
      <strong>
      <h3 className="youDontHave" style={{ fontWeight: "400", color: "black" }}>
      Create New 

      </h3>
      <h3 style={{ fontWeight: "400", color: "black" }}>Portfolio </h3>
    </strong>
    )}
              <button
                style={{
                  backgroundColor: "white",
                  width: "200px",
                  height: "50px",
                  borderRadius: "5px",
                  marginTop: "20px",
                  border: "2px solid #1877F2",
                  color: "#1877F2", fontSize: "12px"
                }}
                onClick={handleCreateNew}
              >
            Add Cryptos to this Portfolio
              </button>
            </div>
          </div>
        </div>
        </div>
      </Box>}


     

        {!initialPage && (
          <>      
{!initialPage && (
      <>
        {loader === false && (
          <Box sx={{ padding: "5px", backgroundColor: "#FFF", borderRadius: "8px", margin: "8px 0" }}>
            {rows.map((idx) => (
              <Box
                key={idx}
                sx={{
                  padding: "16px",
                  backgroundColor: "#F9F9F9",
                  borderRadius: "8px",
                  marginBottom: "20px",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
                  <Skeleton
                    sx={{ marginRight: "8px", borderRadius: "8px" }}
                    variant="rectangular"
                    width={40}
                    height={40}
                  />
                  <Skeleton variant="text" width={200} height={30} />
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Skeleton variant="text" width="50%" height={30} />
                  <Skeleton variant="text" width="50%" height={30} />
                  <Skeleton variant="text" width="50%" height={30} />
                </Box>
              </Box>
            ))}
          </Box>
        )}
        {loader && (
  <Box
    sx={{
      overflowY: "auto",
      marginBottom: "130px",
      "&::-webkit-scrollbar": {
        display: "none",
      },
      scrollbarWidth: "none", // Hides scrollbar for Firefox
    }}
  >
   {sortedData.slice(0, rowsToShow).map((obj, idx) => (
      <React.Fragment key={idx}>
        <Box
          style={{
            padding: "0",
            marginLeft: "5px",
            marginRight: "5px",
            backgroundColor: "white",
            borderRadius: "6px",
            marginTop: "15px",
            marginBottom: "10px", // Corrected typo from "marginBotton"
            border: "1px solid #DADEDF",
            filter: !userData && idx >= 2 ? "blur(4px)" : "none",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", marginBottom: "0" }}>
            <img
              src={obj.logo}
              alt={obj.slug}
              width={38}
              height={38}
              style={{
                borderRadius: "19px",
                marginRight: "16px",
                border: "2px solid #000",
                width: "40px",
                height: "40px",
                margin: "8px",
              }}
            />
            <Typography
              variant="h6"
              style={{ fontSize: "16px", fontWeight: "500" }}
            >
              <a
                href={`../../crypto/${obj.crypto_symbol}`}
                style={{ color: "black" }}
              >
                {obj.slug}
              </a>
            </Typography>
            {obj.category && ( // Check if obj.category is not null or empty
  <div
    style={{ textDecoration: 'none', marginLeft: "auto", marginRight: "5px" }} // Ensures the link stays at the rightmost
  >
    <Box
      sx={{
        background: "var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
        borderRadius: "15px",
        padding: "2px 11px",
        fontSize: "12px",
        fontWeight: "500",
        color: "#000",
        marginBottom: "0px",
      }}
    >
      {obj.category}
    </Box>
  </div>
)}
          </Box>
          <Box
            sx={{
              background: "#EEE",
              borderRadius: "6px",
              padding: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "4px",
                borderBottom: "1px solid #DFDFDF",
              }}
            >
              <Box sx={{ flex: 1, marginRight: "8px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "",
                    margin: "4px",
                  }}
                >
                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "13px",
                      color: "#76787A",
                      fontWeight: "500",
                      marginRight: "10px",
                    }}
                  >
                    Current:
                  </span>
                  <span
                    style={{
                      textAlign: "left",
                      fontWeight: "500",
                      fontSize: "14px",
                    }}
                  >
                    {obj.price_today}
                  </span>
                </div>
              </Box>

              <Box sx={{ flex: 1 }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "4px",
                  }}
                >
                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "13px",
                      color: "#76787A",
                      fontWeight: "500",
                      marginLeft: "30px",
                    }}
                  >
                    Units:
                  </span>
                  <span
                    style={{
                      textAlign: "left",
                      fontWeight: "500",
                      fontSize: "14px",
                    }}
                  >
                    {obj.total_unit}
                  </span>
                </div>
              </Box>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ flex: 1, marginRight: "8px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "",
                    margin: "4px",
                  }}
                >
                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "13px",
                      color: "#76787A",
                      fontWeight: "500",
                      marginRight: "28px",
                    }}
                  >
                    Cost:
                  </span>
                  <span
                    style={{
                      textAlign: "right",
                      fontWeight: "500",
                      fontSize: "14px",
                    }}
                  >
                    {obj.blended_cost}
                  </span>
                </div>
              </Box>
              <Box sx={{ flex: 1 }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "4px",
                  }}
                >
                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "13px",
                      color: "#76787A",
                      fontWeight: "500",
                      marginLeft: "30px",
                    }}
                  >
                    ROI:
                  </span>
                  <span
                    style={{
                      textAlign: "right",
                      fontWeight: "500",
                      fontSize: "14px",
                    }}
                  >
                    {obj.total_roi_value}
                  </span>
                </div>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* Conditionally render the Login/Signup button after the third box */}
        {!userData && idx === 2 && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "15px", // Add spacing between the button and the next box
            }}
          >
            <Button
              onClick={() => {
                const originalUrl = window.location.pathname + window.location.search;
                sessionStorage.setItem("originalUrl", originalUrl);
                console.log("original url: " + originalUrl);
                handleLoginClick();
              }}
              style={{
                color: "white",
                backgroundColor: "#1877F2",
                textTransform: "none",
                width: mediaQueryVar ? "auto" : "200px",
                height: mediaQueryVar ? "auto" : "45px",
                fontWeight: "600",
                fontSize: "18px",
                borderRadius: "30px",
                padding: "8px 24px",
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
                border: "none",
                cursor: "pointer",
                marginTop: "-100px",
              }}
            >
              Login/Signup
            </Button>
          </div>
        )}
      </React.Fragment>
    ))}
 

            
            <div
  style={{
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "space-between",
    border: "none",
    margin: "0 8px 0 8px",
    fontSize: "14px",
  }}
>
  
    

  
</div>

{rowsToShow < sortedData.length && (
                <div className="loadingButton">
                  <LoadingButton
                    loading={addMoreLoader}
                    loadingPosition="center"
                    variant="outlined"
                    style={{
                      marginBottom:"60px",
                      maxWidth: "360px",
                      width: "80vw",
                      backgroundColor: "rgba(67, 97, 238, 0.15)",
                      borderRadius: "6px",
                      border: "none",
                      textTransform: "none",
                      borderStyle: "solid",
                      borderColor: "rgba(67, 97, 238, 0.15)",
                      borderWidth: "1px",
                      color: "#4361ee",
                      width: mediaQueryVar === false ? "80vw" : "180px",
                    }}
                    onClick={() => {
                      loadMore();
                    }}
                  >
                    <span>Load More</span>
                  </LoadingButton>
                </div>
              )}
            {/* <Box
  component="main"
  sx={{
    flexGrow: 1,
    p: 3,
    margin: "20px 5px 10px 5px",
    padding: "15px",
    border: "1px solid #ddd",
    borderRadius: "24px",
    backgroundColor: "#fff",
    color: "#333",
    position: "relative",
    textTransform: "none",
  }}
>
  <h3 style={{ fontWeight: "600", color: "black", fontSize: "20px" }}>
    Thesis
  </h3>

  {retrievalLoading ? (
    <p>Loading Thesis...</p>
  ) : (
    <div
      style={{
        width: "100%",
        height: "auto",
        padding: "10px",
        marginTop: "10px",
        borderRadius: "8px",
        border: "1px solid #ccc",
        backgroundColor: "#f9f9f9",
        color: "#333",
        fontFamily: "inherit",
        whiteSpace: "pre-wrap",
      }}
    >
    {thesisContent ? (
    <div dangerouslySetInnerHTML={{ __html: thesisContent }} />
  ) : (
    'No Thesis available.'
  )}
    </div>
  )}

  
</Box> */}

          </Box>
          
          
        )}
      </>
    )}
              
              

             
            
            
            </>
           )}
            
            <Snackbar
              open={snackbaropen}
              autoHideDuration={5000}
              onClose={closesnackbar}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <Alert severity={severity}>{msgforUpdatePortfolio}</Alert>
            </Snackbar>
            
         
        {loaderForSymbolLink===true &&
         <div >
              <CircularProgress style={{position:"fixed",top:"40vh",left:"45vw"}}/>
          </div> 
          }
      </Box>
     
      
    </Box>
    
  )}
   
   {mediaQueryVar === false && (
         <div
         style={{
           position: "fixed",
           bottom: "0",
           backgroundColor: "white",
           width: "100%",
           zIndex: "100",
           marginTop: "5px",
         }}
       >
         <div
           style={{
             display: "flex",
             justifyContent: "center",
             gap: "10px",
             marginLeft: "0px",
             marginBottom: "20px",
             alignItems: "center",
             marginTop: "10px",
             marginLeft : "-5px"
           }}
         >
           <Button
             variant="outlined"
             style={{
               backgroundColor: "white",
               color: "#1877F2",
               borderColor: "#1877F2",
               borderWidth: "2px",
               borderStyle: "solid",
               borderRadius: "27px",
               textTransform: "none",
               fontFamily: "Satoshi, sans-serif",
               fontSize: "14px",
               fontWeight: "700",
               padding: "5px 10px",
               width: "40%",
               marginTop: "10px",
             }}
             onClick={(e) => {
               e.stopPropagation();
               handleCopy(portId);
             }}
             onMouseDown={(e) => {
               // Change button styles on click
               e.currentTarget.style.backgroundColor = "#1877F2";
               e.currentTarget.style.color = "white";
             }}
             onMouseUp={(e) => {
               // Revert button styles when click is released
               e.currentTarget.style.backgroundColor = "white";
               e.currentTarget.style.color = "#1877F2";
             }}
           >
             Copy Portfolio
           </Button>
   
           <Button
             variant="outlined"
             style={{
               backgroundColor: "white",
               color: "#1877F2",
               borderColor: "#1877F2",
               borderWidth: "2px",
               borderStyle: "solid",
               borderRadius: "27px",
               textTransform: "none",
               fontFamily: "Satoshi, sans-serif",
               fontSize: "14px",
               fontWeight: "700",
               padding: "5px 10px",
               width: "40%",
               marginTop: "10px",
             }}
             onClick={() => navigate("order-book")}
             onMouseDown={(e) => {
               // Change button styles on click
               e.currentTarget.style.backgroundColor = "#1877F2";
               e.currentTarget.style.color = "white";
             }}
             onMouseUp={(e) => {
               // Revert button styles when click is released
               e.currentTarget.style.backgroundColor = "white";
               e.currentTarget.style.color = "#1877F2";
             }}
           >
             Order Book
           </Button>
         </div>
   
         <BottomNavigation showLabels>
  <BottomNavigationAction
        label="Gallery"
        onClick={() =>
          navigate("/portfolio-gallery")
        }
        icon={<img src={loc === '/portfolio-gallery' ? "/images/dashboardS.svg" : "/images/dashboardU.svg"} />}
        
      />
      <BottomNavigationAction
        label="My Portfolios"
        onClick={() => 
          navigate("/my-portfolio")
        }
        icon={<img src={loc === '/my-portfolio' ? "/images/portfolioS.svg" : "/images/portfolioU.svg"}  />}
      />
    <BottomNavigationAction
              label="$1/Month Subscription"
              onClick={() => {
                if (userData) {
                  navigate("/subscriptions/screener360");
                } else {
                  const originalUrl = '/subscriptions/screener360';
                  sessionStorage.setItem("originalUrl", originalUrl);
                  console.log("original url: " + originalUrl);
                  handleLoginClick();
                }
              }}
              icon={
                <img
                  src={
                    
                       loc === "/subscriptions/screener360"
                        ? "/images/dollarS.svg"
                        : "/images/dollarU.svg"
                     
                  }
                  width={24}
                  height={24}
                  alt="Icon"
                  style={{ marginBottom: "-3px" }}
                />
              }
            />
</BottomNavigation>
       </div>
      )}
</>


  );
 
}


export default PortfolioShare;