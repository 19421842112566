import React from 'react';
import './welcome.css';
import json2mq from "json2mq";
import Box from "@mui/material/Box";
import { Tabs, Tab } from '@mui/material';
import { useState,useRef  } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import LoginModal from "../login/newlogin.js";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Drawer from "@mui/material/Drawer";
import HomeSidebarMenuList from "../../includes/homesidebar.jsx";
import IconButton from "@mui/material/IconButton";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles
import { useEffect } from "react";
import LoginPopUp from '../loginpopup.js';
import { useNavigate, useLocation} from 'react-router-dom';
import LoadingScreen from '../loadingscreen.js';
import { storeReferrerData } from '../../apihelper/profile.js';
import {
  getPortfolioForGallery1,
  getSymbolbyPortfolioid,
  getTopPerformingCryptos,
} from "../../apihelper/homepage";
import { completeGoogleLogin } from "../../apihelper/login";
import App from '../../App.js';

function Welcome(props) {

  const [isLoginModalOpen, setLoginModalOpen] = useState(false);

  const handleLoginClick = () => {
    // Mask the URL by adding /login at the end without navigating
    window.history.pushState({}, "", "/login");
  
    // Mark that the popup was opened manually
    localStorage.setItem("isPopupManuallyOpened", "true");
    const isPopupManuallyOpened = localStorage.getItem("isPopupManuallyOpened");

   // console.log("handle login lclick in welcome page:",isPopupManuallyOpened);
  
    // Open the login modal
    setLoginModalOpen(true);
  };
  
  const handleModalClose = () => {
    setLoginModalOpen(false);
    navigate("/", { replace: true });
  };
    const [selectedButtonP, setSelectedButtonP] = useState("ThisWeekP");
    const [selectedButtonC, setSelectedButtonC] = useState("ThisWeekC");
    const [gallarydata, setGallarydata] = useState([]);
    const [cryptodata, setCryptoData] = useState([]);
    const [roidata, setroidata] = useState("7days");
    const [btn_name, setBtnName] = useState("7days");
    const [loading, setLoading] = useState(true);
    const [loading1, setLoading1] = useState(true);
    const [showloginpage, setShowloginpage] = useState(false);
    const [loadingForLogin, setLoadingForLogin] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false); 
    const navigate = useNavigate();
    const [isDragging, setIsDragging] = useState(false);
    const [startPosition, setStartPosition] = useState(0);
    const [currentTranslate, setCurrentTranslate] = useState(0);
    const [prevTranslate, setPrevTranslate] = useState(0);
    const carouselRef = useRef(null);
    const [currentSlide, setCurrentSlide] = useState(1);

    useEffect(() => {
      const checkLoginStatus = async () => {
        // Retrieve portfolio user from localStorage once
        const portfolioUser = localStorage.getItem("cw_portfolio_user");
  
        if (portfolioUser) {
          setIsLoggedIn(true);
          
          // Retrieve and manage the original URL only once
          let originalUrl = sessionStorage.getItem('originalUrl') || '/';
          //console.log("Original URL after intilaization: ",originalUrl);
          if (originalUrl === '/login') {
            originalUrl = '/portfolio-gallery';
          }
          navigate(originalUrl, { replace: true });
          sessionStorage.removeItem('originalUrl'); // Clear after use
        } else {
          // Handle URL search only once
          const search = window.location.search;
          const params = new URLSearchParams(search);
          const googleLoginCode = params.get("code");
  
          if (googleLoginCode) {
            setLoadingForLogin(true); // Show loading screen during login
  
            // Attempt to complete Google login only once
            try {

              const data = await completeGoogleLogin(googleLoginCode);
              if (data.code === "200") {
                //console.log("User Data: ",data.data);

                localStorage.setItem(
                  "cw_portfolio_user",
                  JSON.stringify(data.data)
                );

                const referrerId = localStorage.getItem("referrerId");
               // console.log("In welocme page refId : ",referrerId);
               // console.log("USer id in welocme page : ",data.state);

            if (referrerId) {
              try {
                // Make an API call to store referrer-referee relationship
                await storeReferrerData(referrerId, data.data.user_id,data.state); // Use logged-in user's ID
               

                // Clear referrerId from localStorage after storing
                localStorage.removeItem("referrerId");
                window.location.replace("/account"); 
              } catch (error) {
                console.error("Error storing referrer data:", error);
                // Handle error if storing referrer data fails
              }
            }
                setIsLoggedIn(true);
                //console.log("Original ID from cache: ", sessionStorage.getItem('originalUrl'));
  
                let originalUrl = sessionStorage.getItem('originalUrl') || '/portfolio-gallery';
                //console.log("original url : welcome: ",originalUrl);
                if (originalUrl === '/login' || originalUrl === '/') {
                  originalUrl = '/portfolio-gallery';
                }
                if (originalUrl.includes('/signUp?ref')) {
                  originalUrl = '/account';
                }
                window.location.replace(originalUrl); // Direct replacement
                sessionStorage.removeItem('originalUrl'); // Clear after use
              } else {
                console.log("Google login failed");
                // Handle login failure logic here
              }
            } catch (error) {
              console.error("Error completing Google login:", error);
              // Handle error during login process
            }
          }
        }
      };
  
      checkLoginStatus();
    }, [navigate, completeGoogleLogin, setLoadingForLogin]);



    const handleItemClick = (portfolioName) => {
      //const formattedName = portfolioName.replace(/\s+/g, '-');
      navigate(`/Portfolio-Gallery`);
    };
    
    const mediaQueryVar = useMediaQuery(
        json2mq({
          minWidth: 900,
        })
      );
      const { window1 } = props;
      const [mobileOpen, setMobileOpen] = React.useState(false);
      const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
      };
      const drawer = (
        <div className="d-flex flex-column h-100" style={{ overflowY: "hidden" }}>
          <HomeSidebarMenuList />
        </div>
      );
     
    const container =
    window1 !== undefined ? () => window1().document.body : undefined;
    const handleButtonClickP = (buttonName) => {
      
      //console.log("Button Name:", buttonName);
      if(buttonName === "LastMonthP"){
        setroidata("30days");
      }
      else{
        setroidata("7days");
      
      }
      
        // If the same button is clicked again, don't toggle the view
        if (selectedButtonP === buttonName) {
          
          return;
        }
        // Toggle the view based on the clicked button
    setSelectedButtonP(buttonName);
  };

  const handleButtonClickC = (buttonName) => {

    if(buttonName === "LastMonthC"){
      setBtnName("30days");
    }
    else{
      setBtnName("7days");
    
    }
    // If the same button is clicked again, don't toggle the view
    if (selectedButtonC === buttonName) {
      return;
    }
    // Toggle the view based on the clicked button
setSelectedButtonC(buttonName);
};
  //first section 
 

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev === 2 ? 0 : prev + 1));
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev === 0 ? 2 : prev - 1));
  };

//for second section
const [currentSlide2, setCurrentSlide2] = useState(1);
  const nextSlide2 = () => {
    setCurrentSlide2((prev2) => (prev2 === 2 ? 0 : prev2 + 1));
  };

  const prevSlide2 = () => {
    setCurrentSlide2((prev2) => (prev2 === 0 ? 2 : prev2 - 1));
  };

  const [localUserData,setLocalUserData]=useState(undefined);
  const [localUserData1,setLocalUserData1]=useState(undefined);
  const getPositionX = (event) => {
    return event.type.includes('mouse') ? event.pageX : event.touches[0].clientX;
  };

  const touchStart = (index) => (event) => {
    setIsDragging(true);
    setStartPosition(getPositionX(event));
    setCurrentTranslate(prevTranslate);
  };

  const touchMove = (event) => {
    if (isDragging) {
      const currentPosition = getPositionX(event);
      const diff = currentPosition - startPosition;
      setCurrentTranslate(prevTranslate + diff);
    }
  };

  const touchEnd = () => {
    setIsDragging(false);
    const movedBy = currentTranslate - prevTranslate;

    if (movedBy < -50 && currentSlide < gallarydata.length - 1) {
      setCurrentSlide((prev) => prev + 1);
    }

    if (movedBy > 50 && currentSlide > 0) {
      setCurrentSlide((prev) => prev - 1);
    }

    setPrevTranslate(currentSlide * -carouselRef.current.offsetWidth);
    setCurrentTranslate(currentSlide * -carouselRef.current.offsetWidth);
  };


//for top cryptos
  const startX = useRef(0);
  const startY = useRef(0);
  const currentX = useRef(0);
  const threshold = 50; // Minimum swipe distance to change slides

  const handleTouchStart = (e) => {
    startX.current = e.touches[0].clientX;
    startY.current = e.touches[0].clientY;
  };

  const handleTouchMove = (e) => {
    currentX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    const diffX = startX.current - currentX.current;

    if (Math.abs(diffX) > threshold) {
      if (diffX > 0 && currentSlide2 < 2) {
        setCurrentSlide2(currentSlide2 + 1);
      } else if (diffX < 0 && currentSlide2 > 0) {
        setCurrentSlide2(currentSlide2 - 1);
      }
    }
  };


  //for top portfolios
  const start1X = useRef(0);
  const current1X = useRef(0);
  const threshold1 = 50; // Minimum swipe distance to change slides

  const handleTouchStart1 = (e) => {
    start1X.current = e.touches[0].clientX;
  };

  const handleTouchMove1 = (e) => {
    current1X.current = e.touches[0].clientX;
  };

  const handleTouchEnd1 = () => {
    const diff1X = start1X.current - current1X.current;

    if (Math.abs(diff1X) > threshold1) {
      if (diff1X > 0 && currentSlide < 2) {
        setCurrentSlide(currentSlide + 1);
      } else if (diff1X < 0 && currentSlide > 0) {
        setCurrentSlide(currentSlide - 1);
      }
    }
  };
  const cacheValidDuration = 30 * 60 * 1000; // 30 minutes

  const fetchphotogallery = () => {
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    const user_id = userData ? userData.user_id : 0;
    setLocalUserData(userData);
  
    const cacheKey = `portfolio_gallery_${user_id}_${JSON.stringify(roidata)}`;
    const cachedData = JSON.parse(localStorage.getItem(cacheKey));
    const cacheTimestampKey = `${cacheKey}_timestamp`;
    const cachedTimestamp = localStorage.getItem(cacheTimestampKey);
  
    const isCacheValid = cachedTimestamp && (Date.now() - cachedTimestamp < cacheValidDuration);
  
    if (cachedData && isCacheValid) {
      setGallarydata(cachedData);
      setLoading(false);
      console.log("Data loaded from cache");
      return;
    }
  
    setLoading(true);
  
    getPortfolioForGallery1({
      user_id: user_id,
      value: roidata,
      sort_by_roi: "desc",
      start_index: 0,
      limit: "3",
    }).then((res) => {
      setLoading(false);
      if (res.code === "200") {
        console.log("Data fetched successfully");
        setGallarydata(res.data);
       // console.log("Top 3 res Data1: ", res.data);
  
        localStorage.setItem(cacheKey, JSON.stringify(res.data));
        localStorage.setItem(cacheTimestampKey, Date.now().toString());
      } else {
        console.log("Data fetching error");
      }
    }).catch((error) => {
      setLoading(false);
      console.error("API call error: ", error);
    });
  };
  
  useEffect(() => {
    fetchphotogallery();
  }, [roidata]);


  useEffect(() => {
    fetchtopcryptos();
  }, [btn_name]);
    
  const fetchtopcryptos = () => {
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    const user_id = userData ? userData.user_id : 0;
    setLocalUserData1(userData);
    setLoading1(true);
    getTopPerformingCryptos({
      user_id: user_id,
      value: btn_name,
      
    }).then((res) => {
      setLoading1(false);
      if (res.code === "200") {
        //console.log("data got successfully");
        setCryptoData(res.data);
       // console.log("Top 3 res Data1 cryptos: ", res.data);
      } else {
        console.log("Data fetching error");
      }
    }).catch((error) => {
      setLoading1(false);
      console.error("API call error: ", error);
    });
  };
   
  
  const handleLogout = () => {
    localStorage.removeItem("cw_portfolio_user");
    setIsLoggedIn(false); // Update login status
    navigate("/", { replace: true }); // Redirect to home page
  };
  return (

    
    <>
    {loadingForLogin && <LoadingScreen />}
    {mediaQueryVar === true ? (  
      
    <div className="welcome" style={{fontFamily: "Satoshi, Arial, sans-serif", width:"100%", overflow:"hidden"}}>
      {isLoginModalOpen && (
        <LoginPopUp 
          isLoginModalOpen={isLoginModalOpen}
          setLoginModalOpen={setLoginModalOpen} // Pass the state setter down
          onClose={handleModalClose}
        />
      )}
     
      <div className="header">
        <img src="images/logo_with_bg.png" width={180} height={40} alt="Crowd Wisdom 360" className="logo" />
        <div className="nav-links">
        <Link to="/portfolio-gallery">Portfolio Gallery</Link>
        <Link to="/portfoliofaqs">FAQs</Link>
        <Link to="/contactus">Contact us</Link>
        <Link to="/whitepaper">Whitepaper</Link>

        </div>
        {isLoggedIn ? (
          <button className="connect-wallet" onClick={handleLogout}>
            <span style={{ color: "white", textDecoration: "none" }}>Logout</span>
          </button>
        ) : (
          <button 
          className="connect-wallet" 
          onClick={() => {
            const originalUrl = window.location.pathname + window.location.search;
            sessionStorage.setItem('originalUrl', originalUrl);
          //  console.log("original url: " + originalUrl);
            handleLoginClick();
          }}
        >
          <span style={{ color: "white", textDecoration: "none" }}>Login/Signup</span>
        </button>
        
        )}
      </div>
      

        {/* second Grid container */}
        <div className="top-portfolios" 
        style={{
            background:"#48CEFF",
            paddingTop:"50px",
            paddingBottom:"50px",
            fontFamily:"Satoshi,sans-serif"
            }}>
        <h1 style={{fontSize:"62px",fontFamily: "Satoshi, Arial, sans-serif",marginBottom:"17px"}}>
          <span style={{ padding: "10px", borderRadius: "10px", background: "#FFF",fontSize:"62px"}}>Build a Winning</span> Crypto Portfolio</h1>
        <p style={{fontSize:"24px",fontFamily: "Satoshi, Arial, sans-serif"}}>See What 
          <span style={{ padding: "10px", borderRadius: "10px", background: "#FFF",fontSize:"24px",marginLeft:"6px"}}>Top Influencers</span> Are Recommending Now</p>
        
        
        
        <div className="portfolio-grid">
              {[1,0,2].map((index) => (
                <div className="portfolio-item" key={index} 
                onClick={() => handleItemClick(gallarydata[index]?.portfolio_name || 'N/A')} 
                style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor:'pointer' }}>
                  <div style={{ marginLeft: "0px", marginBottom: "-10px", position: "relative", zIndex: 99 }}>
                  <img 
          src={loading ? '/images/loading.gif' : (gallarydata[index]?.photo || '/images/avtar.jpeg')}
          alt={`Rank ${index + 1}`} 
          width={80} height={80}
          className="portfolio-image"
          style={{ 
            width: '80px !important', 
            height: '80px !important', 
            borderRadius: '50%', 
            objectFit: 'cover',
            background: '#FFF !important', // Background color for the placeholder
          }} 
          onError={(e) => e.target.style.backgroundColor = '#CFCFCF'} // Background color if image fails to load
        />
                    <span className="portfolio-name" style={{ background: "#FFF", color: "black" , width:"200px", whiteSpace:"wrap",}}>
                      {loading ? 'Loading...' : (gallarydata[index]?.portfolio_name || 'N/A')}
                    </span>
                  </div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="270" height="35" viewBox="0 0 270 35" fill="none">
                    <path d="M34.854 0.777344H236.841L269.678 34.7773H0.677734L34.854 0.777344Z" fill="url(#paint0_linear_370_91)" />
                    <defs>
                      <linearGradient id="paint0_linear_370_91" x1="156.124" y1="34.7773" x2="156.38" y2="-12.5085" gradientUnits="userSpaceOnUse">
                        <stop offset="0.517756" stopColor="#37B9E9" />
                        <stop offset="1" stopColor="white" stopOpacity="0.1" />
                      </linearGradient>
                    </defs>
                  </svg>
                  <div className={`base${index + 1}`} style={{ background: "linear-gradient(180deg, #1FADE1 0%, #C1EEFF 100%)" }}>
                  <span style={{ fontSize: '60px' }}>
          {index === 0 && '🥇'}
          {index === 1 && '🥈'}
          {index === 2 && '🥉'}
        </span>
        <br />
                    <span className="portfolio-roi" style={{ background: "#1987B0" }}>
                      {loading ? 'Loading...' : (gallarydata[index] ? `ROI: ${gallarydata[index].roi_value}` : 'ROI: N/A')}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          
      
<Box
      sx={{
        backgroundColor: "#1987B0", // Background color for the container
        paddingTop: {xs:'10px', sm:'4px'},// Padding inside the container
        paddingLeft: {xs:'0px', sm:'0px'},
        paddingRight:{xs:'0px', sm:'0px'},
        paddingBottom: {xs:'0px', sm:'4px'},
        borderRadius: "50px", // Border radius for the container
        display: 'inline-flex', // Make sure the box is flexible
        justifyContent: 'flex-start', // Center the content
        alignItems: 'center', // Center the content
        width: { xs: '270px', // Full width on extra small screens
          sm: '266px' // 300px width on small screens and up
        },
        height:'48px',
        border:'none',
        marginLeft:"10px",
      }}
    >
                  
<Tabs
value={selectedButtonP}
onChange={(event, newValue) => handleButtonClickP(newValue)}
  sx={{
    alignSelf: mediaQueryVar === true ? "flex-start" : "",
    marginLeft: mediaQueryVar === true ? "2px" : "2px",
    borderRadius: "50px", // Adjust border radius for more rounded corners
    border: "none",
    "& .MuiTabs-indicator": {
      display: "none",
    },
    display: 'flex', // Make sure the tabs are displayed in a row
    overflow: 'hidden', // Prevent the tabs from spilling out of the border radius
  }}
>
  <Tab
    label="This Week"
    value="ThisWeekP"
    sx={{
      whiteSpace: "nowrap",
      bottom: "0px",
      marginTop:"1.1px",
      marginBottom:"0px",
      marginLeft:"3px",
      marginRight:"3px",
      paddingBottom:"2px",
      paddingTop: "0px",
      paddingLeft:"20px",
      paddingRight: "20px",
     minHeight:"20px",
     lineHeight:{xs:'28px', sm:'36px'},
      borderRadius: selectedButtonP === "ThisWeekP" ? "20px" : "20px", // Rounded corners for selected tab
      textTransform: "none",
      fontSize: {xs:'13px', sm:'16px'},
      fontStyle: "normal",
      fontWeight: selectedButtonP === "ThisWeekP" ? "700" : "500",
      
      backgroundColor: selectedButtonP === "ThisWeekP" ? "#FFFFFF" : "transparent", // White background for selected tab
      color: selectedButtonP === "ThisWeekP" ? "#1987B0 !important" : "#FFFFFF", // Black text for selected tab
      border: 'none', // Remove border for tabs
      background: selectedButtonP === "ThisWeekP" ? "#FFFFFF" : "transparent", // Blue background for unselected tab
    }}
  />
  <Tab
    label="Last Month"
    value="LastMonthP"
    sx={{
      whiteSpace: "nowrap",
      bottom: "0px",
      marginTop:"0px",
      marginBottom:"0px",
      marginLeft:"3px",
      marginRight:"3px",
      paddingBottom:"0px",
      paddingTop: "0px",
      paddingLeft:"20px",
      paddingRight: "20px",
     minHeight:"20px",
     lineHeight:{xs:'36px', sm:'36px'},
      borderRadius: selectedButtonP === "LastMonthP" ? "50px" : "50px", // Rounded corners for selected tab
      textTransform: "none",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: selectedButtonP === "LastMonthP" ? "700" : "500", 
      
      backgroundColor: selectedButtonP === "LastMonthP" ? "#FFFFFF" : "transparent", // White background for selected tab
      color: selectedButtonP === "LastMonthP" ? "#1987B0 !important" : "#FFFFFF", // Black text for selected tab
      border: 'none', // Remove border for tabs
      background: selectedButtonP === "LastMonthP" ? "#FFFFFF" : "transparent", // Blue background for unselected tab
    }}
  />
</Tabs>
</Box>
</div>


{/*second container for top 3 best performing cryptos*/}
<div className="top-portfolios" style={{ paddingTop: "100px", paddingBottom: "50px", paddingLeft:"20px",paddingRight:"20px" }}>
<img src="/images/spark1.svg" className="spark1"></img>
<img src="/images/spark2.svg" className="spark2"></img>
<img src="/images/spark3.svg" className="spark3"></img>
<img src="/images/spark4.svg" className="spark4"></img>
<img src="/images/spark5.svg" className="spark5"></img>
<img src="/images/spark6.svg" className="spark6"></img>

  <h1 style={{ marginBottom: "20px", fontSize:"62px" }}>
    <span style={{ padding: "10px", borderRadius: "10px", background: "#1877F2",fontSize:"62px" ,color:"white"}}>Trending</span> Cryptos
  </h1>
  <p style={{fontSize:"24px",color:"#7F7F80"}}>Stay ahead with latest investment trends</p>

  <div className="portfolio-container1">
  {[2,0,4,3,1,5].map((index) => (
    <div key={index}
      onClick={() => handleItemClick(cryptodata[index]?.max_roi_portfolio_type.replace(/-/g, ' ') || 'N/A')} 
      className="portfolio-item1" style={{ boxShadow: 'none' }}>
      <div className="portfolio-content1" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
        <div className="portfolio-header1" style={{ display: "flex", alignItems: "center", justifyContent: "center", width:"100%" }}>
          {loading ? (
            <div className="loading-placeholder" style={{ width: 40, height: 40 }} />
          ) : (
            <img 
              src={loading ? '/images/avtar.jpeg' : `https://s2.coinmarketcap.com/static/img/coins/64x64/${cryptodata[index]?.cmc_id}.png`} 
              alt="Rank" 
              width={38} 
              height={38}
              style={{ borderRadius: "50%", marginRight: "10px" ,border:"1px solid black"}} 
            />
          )}
          <span style={{ fontSize: "24px" }}>{loading ? 'Loading...' : cryptodata[index]?.slug.charAt(0).toUpperCase() + cryptodata[index]?.slug.slice(1)}</span>
        </div>
        {/* <div className="portfolio-divider1" style={{ width: "100%", height: "1px", backgroundColor: "#ccc", margin: "10px 0" }}></div> */}
        {/* <div className="portfolio-actions1" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <button style={{ padding: "10px 20px", fontSize: "16px", cursor: "pointer" }}>
            {loading ? 'Loading...' :  `ROI: ${cryptodata[index]?.holdings}%`}
          </button>
        </div> */}
      </div>
    </div>
  ))}
</div>



        <Box
      sx={{
        marginTop:"40px",
        backgroundColor: "#1877F2", // Background color for the container
        paddingTop: {xs:'10px', sm:'4px'},// Padding inside the container
        paddingLeft: {xs:'0px', sm:'0px'},
        paddingRight:{xs:'0px', sm:'0px'},
        paddingBottom: {xs:'0px', sm:'4px'},
        borderRadius: "50px", // Border radius for the container
        display: 'inline-flex', // Make sure the box is flexible
        justifyContent: 'flex-start', // Center the content
        alignItems: 'center', // Center the content
        width:'215px',
        height:'48px',
        border:'none',
        marginLeft:"10px",
      }}
    >
                  
<Tabs
value={selectedButtonC}
onChange={(event, newValue) => handleButtonClickC(newValue)}
  sx={{
    alignSelf: mediaQueryVar === true ? "flex-start" : "",
    marginLeft: mediaQueryVar === true ? "2px" : "2px",
    borderRadius: "50px", // Adjust border radius for more rounded corners
    border: "none",
    "& .MuiTabs-indicator": {
      display: "none",
    },
    display: 'flex', // Make sure the tabs are displayed in a row
    overflow: 'hidden', // Prevent the tabs from spilling out of the border radius
  }}
>
  <Tab
    label="7 Days"
    value="ThisWeekC"
    sx={{
      whiteSpace: "nowrap",
      bottom: "0px",
      marginTop:"1.1px",
      marginBottom:"0px",
      marginLeft:"3px",
      marginRight:"3px",
      paddingBottom:"2px",
      paddingTop: "0px",
      paddingLeft:"20px",
      paddingRight: "20px",
     minHeight:"20px",
     lineHeight:{xs:'28px', sm:'36px'},
      borderRadius: selectedButtonC === "ThisWeekC" ? "20px" : "20px", // Rounded corners for selected tab
      textTransform: "none",
      fontSize: {xs:'13px', sm:'16px'},
      fontStyle: "normal",
      fontWeight: selectedButtonC === "ThisWeekC" ? "700" : "500",
      
      backgroundColor: selectedButtonC === "ThisWeekC" ? "#FFFFFF" : "transparent", // White background for selected tab
      color: selectedButtonC === "ThisWeekC" ? "#1877F2 !important" : "#FFFFFF", // Black text for selected tab
      border: 'none', // Remove border for tabs
      background: selectedButtonC === "ThisWeekC" ? "#FFFFFF" : "transparent", // Blue background for unselected tab
    }}
  />
  <Tab
    label="30 Days"
    value="LastMonthC"
    sx={{
      whiteSpace: "nowrap",
      bottom: "0px",
      marginTop:"0px",
      marginBottom:"0px",
      marginLeft:"3px",
      marginRight:"3px",
      paddingBottom:"0px",
      paddingTop: "0px",
      paddingLeft:"20px",
      paddingRight: "20px",
     minHeight:"20px",
     lineHeight:{xs:'36px', sm:'36px'},
      borderRadius: selectedButtonC === "LastMonthC" ? "50px" : "50px", // Rounded corners for selected tab
      textTransform: "none",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: selectedButtonC === "LastMonthC" ? "700" : "500", 
      
      backgroundColor: selectedButtonC === "LastMonthC" ? "#FFFFFF" : "transparent", // White background for selected tab
      color: selectedButtonC === "LastMonthC" ? "#1877F2 !important" : "#FFFFFF", // Black text for selected tab
      border: 'none', // Remove border for tabs
      background: selectedButtonC === "LastMonthC" ? "#FFFFFF" : "transparent", // Blue background for unselected tab
    }}
  />
</Tabs>




</Box>
    </div>

    {/*fourth container*/}
    <div className="farming-page">
      <h1 style={{fontSize:"62px"}}>Participate in Farming</h1>
      <p style={{fontSize:"24px"}}>Maximize your profits through yield farming programs</p>
      <div style={{display:"flex"}}>

      <div className="cards-container">
      <img src="images/side1.png"  alt="wise" className="wise-image wise1" />

        <div className="card" style={{marginRight:"20px"}}>
          <img src="/images/burningAI.svg" alt="Icon 1" className="card-icon" />
          <p>Become a Portfolio Creator and <strong>Earn 3000 Wise Coins</strong></p>
          <div style={{width:"100%", border:"1px solid #D6D6D6",marginBottom:"20px"}}></div>
          <Link to="/cryptocurrencylist"><button className="card-button">Add your Portfolio</button></Link>
        </div>
        <div className="card">
          <img src="/images/share.svg" alt="Icon 2" className="card-icon" />
          <p>Share your Favorite Portfolios on Social Media and <strong>win 1000 Wise Coins</strong></p>
          <div style={{width:"100%", border:"1px solid #D6D6D6",marginBottom:"20px"}}></div>
          <Link to="/my-portfolio"><button className="card-button">Share Portfolio</button></Link>
        </div>
        <img src="images/side2.png"  alt="wise" className="wise-image wise1" />

      </div>
      </div>
    </div>
    </div>

   
     ):(
    
      <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh", // Ensures it takes at least the full viewport height
        flexGrow: 1, // Allows the Box to grow and fill the available space
        overflow: "hidden", 
        margin:0,
        width:"100%",
        padding:0,
        
        
      }}
    >
    <Helmet>
            <title>Best Crypto Portfolios</title>
    </Helmet>

    <Box
    component="nav"
    sx={{
      width: "100%", // Take full width on mobile
    }}
    aria-label="mailbox folders"
  >
    <Drawer
      container={container}
      variant="temporary"
      open={mobileOpen}
      onClose={handleDrawerToggle}
      ModalProps={{
        keepMounted: true,
      }}
      sx={{
        "& .MuiDrawer-paper": {
          width: "80%", // Adjust width for mobile
          backgroundColor: "white",
          color: "#727376",
        },
      }}
    >
      {drawer}
    </Drawer>
  </Box>
  <Box
    component="main"
    sx={{
      minHeight:"100vh",
      flexGrow: 1,
      padding:0,
      margin:0,
      backgroundColor: "white",
   // Allow scrolling
    }}
    className="maindiv"
  >
      
      {/* Include the LoginModal and pass the necessary props */}
     
<div className="welcome" style={{ position: 'relative' }}>
      <div className="header" style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '10px',
          background: '#fff',
          boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
          paddingBottom:"30px",
          paddingTop:"20px",
        }}>
        <img src="images/logo_with_bg.png" width={180} height={40} alt="Crowd Wisdom 360" />
        <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerToggle}
            sx={{ display: { sm: "none" } }}
          >
            <img src={window.constants.asset_path  + '/images/menubar.svg'}
            alt="Menu Icon" style={{ width: "24px", height: "24px", marginRight:"17px" }} />
          </IconButton>
       
      </div>
      

        {/* First Grid container */}
        <div className="top-portf" 
      style={{
        background: "#48CEFF",
        paddingTop: "25px",
        paddingBottom: "30px",
        fontFamily: "Satoshi, sans-serif",
        overflow: "hidden",
        textAlign: "center"
      }}>
      <h1 style={{ fontSize: "35px", fontFamily: "Satoshi, Arial, sans-serif", margin: "10px", lineHeight: "56px" }}>
        <span style={{ padding: "10px", borderRadius: "10px", backgroundColor: "#FFF", fontSize: "35px", color: "black" }}>Build a Winning</span> 
        <br></br>Crypto Portfolio
      </h1>
      <p style={{ fontSize: "18px", fontFamily: "Satoshi, Arial, sans-serif" }}>See What 
        <span style={{ padding: "8px", borderRadius: "10px", backgroundColor: "#FFF", fontSize: "18px", color: "black", marginLeft:"3px" }}>Top Influencers</span> Are Recommending Now</p>

      <div className="portfolio-carousel1" 
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          transform: `translateX(-${(currentSlide) * 33}%)`, // Adjusted currentSlide directly
          transition: "transform 0.3s ease-in-out",
          position: "relative",
          width: "300%" // Set width to show all three slides side by side
        }}
        onTouchStart={handleTouchStart1}
        onTouchMove={handleTouchMove1}
        onTouchEnd={handleTouchEnd1}
      >
        {[1, 0, 2].map((index) => (
          <div className="portfolio-item" key={index}
            onClick={() => handleItemClick(gallarydata[index]?.portfolio_name || 'N/A')}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              cursor: 'pointer',
              width: "100%",
              maxWidth: "300px",
              margin: "0 0px"
            }}>
            <div style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "-10px",
              position: "relative",
              zIndex: 99
            }}>
              <img
                src={loading ? '/images/loading.gif' : (gallarydata[index]?.photo || '/images/avtar.jpeg')}
                alt={`Rank ${index + 1}`}
                className="portfolio-image"
                style={{
                  width: '80px',
                  height: '80px',
                  borderRadius: '50%',
                  objectFit: 'cover',
                  backgroundColor: '#FFF',
                }}
                onError={(e) => e.target.style.backgroundColor = '#CFCFCF'}
              />
              <span className="portfolio-name" style={{ background: "#FFF", color: "black", width: "200px", textAlign: "center", whiteSpace: "wrap" }}>
                {loading ? 'Loading...' : (gallarydata[index]?.portfolio_name || 'N/A')}
              </span>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="270" height="35" viewBox="0 0 270 35" fill="none">
              <path d="M34.854 0.777344H236.841L269.678 34.7773H0.677734L34.854 0.777344Z" fill="url(#paint0_linear_370_91)" />
              <defs>
                <linearGradient id="paint0_linear_370_91" x1="156.124" y1="34.7773" x2="156.38" y2="-12.5085" gradientUnits="userSpaceOnUse">
                  <stop offset="0.517756" stopColor="#37B9E9" />
                  <stop offset="1" stopColor="white" stopOpacity="0.1" />
                </linearGradient>
              </defs>
            </svg>
            <div className={`base${index + 1}`} style={{ background: "linear-gradient(180deg, #1FADE1 0%, #C1EEFF 100%)" }}>
              <span style={{ fontSize: '60px' }}>
                {index === 0 && '🥇'}
                {index === 1 && '🥈'}
                {index === 2 && '🥉'}
              </span>
              <br />
              <span className="portfolio-roi" style={{ background: "#1987B0" }}>
                {loading ? 'Loading...' : (gallarydata[index] ? `ROI: ${gallarydata[index].roi_value}` : 'ROI: N/A')}
              </span>
            </div>
          </div>
        ))}
      </div>

      {/* Carousel Dots */}
      <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "10px",
        marginBottom: "20px"
      }}>
        {[0, 1, 2].map((index) => (
          <div key={index} onClick={() => setCurrentSlide(index)} style={{
            width: currentSlide === index ? "15px" : "10px",
            height: currentSlide === index ? "15px" : "10px",
            borderRadius: "50%",
            background: "white",
            margin: "0 4px",
            cursor: "pointer"
          }}></div>
        ))}
      </div>
          {/* Tab Section */}
          <Box
            sx={{
              backgroundColor: "#1987B0", // Background color for the container
              paddingTop: "3.5px",// Padding inside the container
              paddingLeft: {xs:'0px', sm:'0px'},
              paddingRight:{xs:'0px', sm:'0px'},
              paddingBottom: {xs:'0px', sm:'4px'},
              borderRadius: "50px", // Border radius for the container
              display: 'inline-flex', // Make sure the box is flexible
              justifyContent: 'flex-start', // Center the content
              alignItems: 'center', // Center the content
              width: "250px",
              height:{xs:'44px', sm:'48px'},
              border:'none',
              marginLeft:"10px",
            }}
          >
            <Tabs
              value={selectedButtonP}
              onChange={(event, newValue) => handleButtonClickP(newValue)}
              sx={{
                alignSelf: "flex-start",
                marginLeft: "2px",
                borderRadius: "50px", // Adjust border radius for more rounded corners
                border: "none",
                "& .MuiTabs-indicator": {
                  display: "none",
                },
                display: 'flex', // Make sure the tabs are displayed in a row
                overflow: 'hidden', // Prevent the tabs from spilling out of the border radius
              }}
            >
              <Tab
                label="This Week"
                value="ThisWeekP"
                sx={{
                  whiteSpace: "nowrap",
                  bottom: "0px",
                  marginTop:"1.1px",
                  marginBottom:"0px",
                  marginLeft:"3px",
                  marginRight:"3px",
                  paddingBottom:"2px",
                  paddingTop: "0px",
                  paddingLeft:"20px",
                  paddingRight: "20px",
                  minHeight:"20px",
                  lineHeight:{xs:'28px', sm:'36px'},
                  borderRadius: selectedButtonP === "ThisWeekP" ? "20px" : "20px", // Rounded corners for selected tab
                  textTransform: "none",
                  fontSize: {xs:'13px', sm:'16px'},
                  fontStyle: "normal",
                  fontWeight: selectedButtonP === "ThisWeekP" ? "700" : "500",
                  backgroundColor: selectedButtonP === "ThisWeekP" ? "#FFFFFF" : "transparent", // White background for selected tab
                  color: selectedButtonP === "ThisWeekP" ? "#1987B0 !important" : "#FFFFFF", // Black text for selected tab
                  border: 'none', // Remove border for tabs
                  background: selectedButtonP === "ThisWeekP" ? "#FFFFFF" : "transparent", // Blue background for unselected tab
                }}
              />
              <Tab
                label="Last Month"
                value="LastMonthP"
                sx={{
                  whiteSpace: "nowrap",
                  bottom: "0px",
                  marginTop:"0px",
                  marginBottom:"0px",
                  marginLeft:"3px",
                  marginRight:"3px",
                  paddingBottom:"0px",
                  paddingTop: "0px",
                  paddingLeft:"20px",
                  paddingRight: "20px",
                  minHeight:"20px",
                  lineHeight:{xs:'36px', sm:'36px'},
                  borderRadius: selectedButtonP === "LastMonthP" ? "50px" : "50px", // Rounded corners for selected tab
                  textTransform: "none",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: selectedButtonP === "LastMonthP" ? "700" : "500", 
                  backgroundColor: selectedButtonP === "LastMonthP" ? "#FFFFFF" : "transparent", // White background for selected tab
                  color: selectedButtonP === "LastMonthP" ? "#1987B0 !important" : "#FFFFFF", // Black text for selected tab
                  border: 'none', // Remove border for tabs
                  background: selectedButtonP === "LastMonthP" ? "#FFFFFF" : "transparent", // Blue background for unselected tab
                }}
              />
            </Tabs>
          </Box>
        </div>


{/*second container for top 3 best performing cryptos*/}
<div style={{ paddingTop: "50px", paddingBottom: "50px", background: "white" }}>
      <h1 style={{ marginBottom: "20px", fontSize: "40px" }}>
        <span style={{ padding: "10px", borderRadius: "10px", background: "#1877F2", fontSize: "40px", color: "white" }}>Trending</span> Cryptos
      </h1>
      <p style={{ fontSize: "18px", color: "#7F7F80" }}>Stay ahead with latest investment trends</p>

      <div className="portfolio-carousel" 
        style={{
          display: "flex",
          overflow:"hidden",
          position: "relative",
          width: "100%",
          justifyContent: "center",
          paddingBottom:"20px"
        }}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <div style={{
          display: "flex",
          transform: `translateX(-${currentSlide2 * 100}%)`,
          transition: "transform 0.3s ease-in-out",
          width: "100%",
        }}>
          {[1,0,2].map((index) => (
            <div key={index} style={{ flex: "0 0 100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
              {[index * 2, index * 2 + 1].map((subIndex) => (
                <div key={subIndex} onClick={() => handleItemClick(cryptodata[subIndex]?.max_roi_portfolio_type.replace(/-/g, ' ') || 'N/A')}
                  style={{
                    flex: "1 0 45%",
                    padding: "15px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    borderRadius: "10px",
                    boxSizing: "border-box",
                    border: "2px solid #EDEDED",
                    margin: "10px",
                    width: "90%",
                  }}>
                  <div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                      {loading ? (
                        <div style={{ width: 40, height: 40 }} />
                      ) : (
                        <img
                          src={loading ? '/images/avtar.jpeg' : `https://s2.coinmarketcap.com/static/img/coins/64x64/${cryptodata[subIndex]?.cmc_id}.png`}
                          alt="Rank"
                          width={38}
                          height={38}
                          style={{ borderRadius: "50%", border:"1px solid black" }}
                        />
                      )}
                      <span style={{ marginLeft: '10px', fontSize: "22px", fontWeight: "600", fontFamily: "Satoshi, arial, sans-serif" }}>
                        {loading ? 'Loading...' : cryptodata[subIndex]?.slug.charAt(0).toUpperCase() + cryptodata[subIndex]?.slug.slice(1)}
                      </span>
                    </div>

                    {/* <div style={{ marginTop: "10px", marginLeft: "5px", width: "95%", border: "1px solid #EDEDED" }}></div> */}
                    {/* <div style={{ marginTop: '5px', textAlign: 'center' }}> */}
                      {/* <button style={{
                        padding: "10px 20px",
                        borderRadius: "50px",
                        backgroundColor: "white",
                        color: "#1877F2",
                        fontSize: "14px",
                        fontWeight: "500",
                        border: "2px solid #1877F2"
                      }}>
                        {loading ? 'Loading...' : `ROI:  ${cryptodata[subIndex]?.holdings}%`}
                      </button> */}
                    {/* </div>  */}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>

      {/* Carousel Dots */}
      <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "18px",
        marginBottom: "20px"
      }}>
        {[0, 1, 2].map((index2) => (
          <div key={index2} onClick={() => setCurrentSlide2(index2)} style={{
            width: currentSlide2 === index2 ? "15px" : "10px",
            height: currentSlide2 === index2 ? "15px" : "10px",
            borderRadius: "50%",
            background: currentSlide2 === index2 ? "#1877F2" : "#CFCFCF",
            margin: "0 4px",
            cursor: "pointer"
          }}></div>
        ))}
      </div>
    
      {/* Tabs */}
      <Box
        sx={{
          marginTop: "20px",
          backgroundColor: "#1877F2",
          paddingTop: { xs: '10px', sm: '4px' },
          paddingLeft: { xs: '0px', sm: '0px' },
          paddingRight: { xs: '0px', sm: '0px' },
          paddingBottom: { xs: '0px', sm: '4px' },
          borderRadius: "50px",
          display: 'inline-flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          width: '210px',
          height: { xs: '44px', sm: '48px' },
          border: 'none',
          marginLeft: "10px",
        }}
      >
        <Tabs
          value={selectedButtonC}
          onChange={(event, newValue) => handleButtonClickC(newValue)}
          sx={{
            alignSelf: mediaQueryVar === true ? "flex-start" : "",
            marginLeft: mediaQueryVar === true ? "2px" : "2px",
            borderRadius: "50px",
            border: "none",
            "& .MuiTabs-indicator": {
              display: "none",
            },
            display: 'flex',
            overflow: 'hidden',
          }}
        >
          <Tab
            label="7 Days"
            value="ThisWeekC"
            sx={{
              whiteSpace: "nowrap",
              bottom: "0px",
              marginTop: "1.1px",
              marginBottom: "0px",
              marginLeft: "3px",
              marginRight: "3px",
              paddingBottom: "2px",
              paddingTop: "0px",
              paddingLeft: "20px",
              paddingRight: "20px",
              minHeight: "20px",
              lineHeight: { xs: '28px', sm: '36px' },
              borderRadius: selectedButtonC === "ThisWeekC" ? "20px" : "20px",
              textTransform: "none",
              fontSize: { xs: '13px', sm: '16px' },
              fontStyle: "normal",
              fontWeight: selectedButtonC === "ThisWeekC" ? "700" : "500",
              backgroundColor: selectedButtonC === "ThisWeekC" ? "#FFFFFF" : "transparent",
              color: selectedButtonC === "ThisWeekC" ? "#1877F2 !important" : "#FFFFFF",
              border: 'none',
              background: selectedButtonC === "ThisWeekC" ? "#FFFFFF" : "transparent",
            }}
          />
          <Tab
            label="30 Days"
            value="LastMonthC"
            sx={{
              whiteSpace: "nowrap",
              bottom: "0px",
              marginTop: "0px",
              marginBottom: "0px",
              marginLeft: "3px",
              marginRight: "3px",
              paddingBottom: "0px",
              paddingTop: "0px",
              paddingLeft: "20px",
              paddingRight: "20px",
              minHeight: "20px",
              lineHeight: { xs: '36px', sm: '36px' },
              borderRadius: selectedButtonC === "LastMonthC" ? "50px" : "50px",
              textTransform: "none",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: selectedButtonC === "LastMonthC" ? "700" : "500",
              backgroundColor: selectedButtonC === "LastMonthC" ? "#FFFFFF" : "transparent",
              color: selectedButtonC === "LastMonthC" ? "#1877F2 !important" : "#FFFFFF",
              border: 'none',
              background: selectedButtonC === "LastMonthC" ? "#FFFFFF" : "transparent",
            }}
          />
        </Tabs>
      </Box>
    </div>

    {/*fourth container*/}
    <div style={{padding:"20px", backgroundColor:"#D2FD8B"}}>
      <h1 style={{fontSize:"40px"}}>Participate in Farming</h1>
      <p style={{fontSize:"18px"}}>Maximize your profits through yield farming programs</p>
      <div style={{display:"inline-flex"}}>

      <div style={{
        fontFamily: "'Satoshi', Arial, sans-serif",
        display: "flex",
        flexDirection:"column",
        justifyContent: "center",
        gap: "20px"
      }}>
        <div style={{
          borderRadius: "25px",
          background: "#FFF",
          boxShadow: "0px 24px 50px -12px rgba(45, 54, 67, 0.12)",
            fontFamily: "'Satoshi', Arial, sans-serif",
            padding: "24px",
           margin:"20px",
           marginBottom:"-20px",
            textAlign: "left"
            
        }}>
          <img src="/images/burningAI.svg" alt="Icon 1" className="card-icon" />
          <p>Become a Portfolio Creator and <strong>Earn 3000 Wise Coins</strong></p>
          <div style={{width:"100%", border:"1px solid #D6D6D6",marginBottom:"20px"}}></div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
  <Link to="/cryptocurrencylist">
    <button className="card-button">
      Add Your Portfolio
    </button>
  </Link>
</div>

        </div>
        <div style={{
          borderRadius: "25px",
          background: "#FFF",
          boxShadow: "0px 24px 50px -12px rgba(45, 54, 67, 0.12)",
            fontFamily: "'Satoshi', Arial, sans-serif",
            padding: "24px",
            margin:"20px",
            textAlign: "left"
            
        }}>
          <img src="/images/share.svg" alt="Icon 2" className="card-icon" />
          <p>Share your Favorite Portfolios on Social Media and <strong>win 1000 Wise Coins</strong></p>
          <div style={{width:"100%", border:"1px solid #D6D6D6",marginBottom:"20px"}}></div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>

          <Link to="/my-portfolio"><button className="card-button">Share Portfolio</button></Link>
        </div></div>
      </div>
      </div>
    </div>

    </div>
</Box>
</Box>
     )}
</>
  );
}

export default Welcome;
