export const getContent=(content,token)=>{
    // console.log(token)
    return fetch(`${process.env.REACT_APP_API_URL}/content`,{ method:"POST",
    headers:{
        Accept:"application/json",
        "Content-Type":"application/json",
        "Authorization":token
    },
    body : JSON.stringify({page:content})}
    ).then(response=>{return response.json()})
    .catch(err=>console.log(err))
}