import "./portfolioadd.css";
import * as React from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { Helmet } from 'react-helmet';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
const drawerWidth = 292;

function Portfolioadd(props) {
  const navigate = useNavigate();
  const handleCreateNew = () => {
    // router.push(`/${path}`);
    navigate("/cryptocurrencylist", { replace: true });
  };
  const handleGoBack = () => {
    navigate(-1); // Go back to the previous page
  };
  return (
    <Box
      sx={{
        display: "flex",
        padding: "10px",
        width: "100%",
        height: "100vh",
      }}
    >
      <Helmet>
        <title>Add Portfolio</title>
</Helmet>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        <Button size="small" color="primary"onClick={handleGoBack} edge="start"  aria-label="menu">
            <ArrowBackIcon /> Go Back
          </Button>
        <div style={{borderRadius:"10px",background:"white",padding:"10px"}}>
        <div>
          <h3>Portfolio</h3>
          
        </div>
        <div className="frameParent8">
          <div className="frameParent9" style={{ padding: "10px" }}>
            <img className="frameIcon6" alt="" src={window.constants.asset_path +'/images/frame20.svg'} />
            <div className="youDontHaveContainer">
              <strong>
                <h3
                  className="youDontHave"
                  style={{ fontWeight: "400", color: "black" }}
                >
                Create New 
                </h3>
                <h3 style={{ fontWeight: "400", color: "black" }}> Portfolio</h3>
              </strong>
              <button
                style={{
                  backgroundColor: "#4460EF",
                  width: "100px",
                  height: "50px",
                  borderRadius: "5px",
                  marginTop: "20px",
                  border: "2px solid #4460EF",
                  color: "white",
                }}
                onClick={handleCreateNew}
              >
                Create New
              </button>
            </div>
          </div>
        </div>
        </div>
      </Box>
    </Box>
  );
}

export default Portfolioadd;
