import { Typography, useMediaQuery } from "@mui/material";
import React from "react";
import "./login.css";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { completeGoogleLogin } from "../../apihelper/login";
import LoadingButton from "@mui/lab/LoadingButton";
import { useState } from "react";
import { Helmet } from 'react-helmet';
import { useParams } from "react-router-dom";
import json2mq from "json2mq";

const Login = () => {
 const { userId } = useParams();
 const [searchParams] = useSearchParams();
 const navigate = useNavigate();
 const [showloginpage, setShowloginpage] = useState(false);
 
 useEffect(() => {
  const userId = searchParams.get("userId");
  //console.log("Login userDi in login.js: ", userId);
  
  if (userId) {
    localStorage.setItem("portfolio_userId", userId);
  } else {
    localStorage.removeItem("portfolio_userId");
  }
  return () => {
    localStorage.removeItem("portfolio_userId");
  };
}, [searchParams]);

const handleLoginClick = () => {
  // Capture the original URL
  sessionStorage.setItem('originalUrl', window.location.pathname + window.location.search);
  fetch(process.env.REACT_APP_API_URL + "/initiategooglelogin")
    .then((res) => res.json())
    .then((res) => {
      //console.log(res);
      window.location.replace(res.google_url);
    });


};




  const mediaQueryVar = useMediaQuery(
    json2mq({
      minWidth: 900,
    })
  );
  

  
  // useEffect(() => {
  //   setShowloginpage(true);
  //   let googleLoginCode = searchParams.get("code");
  //   if (googleLoginCode) {
  //     completeGoogleLogin(googleLoginCode).then((data) => {
  //       if (data.code === "200") {
  //         console.log(data.message);
  //         localStorage.setItem("cw_portfolio_user", JSON.stringify(data.data));
  //         const portfolio_userId = localStorage.getItem("portfolio_userId");
  //         const originalUrl = sessionStorage.getItem('originalUrl') || '/';
  //               if (originalUrl) {
  //                 console.log("Original url in login.js after login: ",originalUrl);
  //                 sessionStorage.removeItem('originalUrl'); // Clear the original URL after use
  //                 navigate(originalUrl, { replace: true });

  //               } 
  //        else{
  //         if (data.data.portfolio_form_redirect === "yes") {
  //           navigate("profile", { replace: true });
  //         } else {
  //           navigate("cryptocurrencylist", { replace: true });
  //         }
  //       }
  //       } else {
  //         console.log("GOOGLE LOGIN FAILED");
  //         // TODO
  //         // pop up the error
  //       }
      
  //     });
    
  //   } else {
  //     setShowloginpage(false);
  //   }
  // }, []);

  return (
    <div className="mainContainer">
      <Helmet>
        <title>Login</title>
</Helmet>
      {
        <div className="mainContainer_div">
          <div className="imageContainer_login">
            <div style={{ width: "80%",fontFamily: "'Satoshi', Arial, sans-serif" }}>
             
               
                  <p className="paraone"
                  style={{ 
                    margin: "0",
                    marginBottom:"15px",
                   fontFamily: "'Satoshi', Arial, sans-serif",
                    fontSize:mediaQueryVar==true?"28px": "18px",
                    fontWeight:"600"
                    
                    }}>
                  Did you know that 10% of Influencer Portfolio Recommendations delivered 5x higher returns than the market?                  </p>
               
               
                  <p className="paratwo" style={{ 
                    margin: "0",
                   fontFamily: "'Satoshi', Arial, sans-serif",
                    fontSize:mediaQueryVar==true?"18px": "14px",
                     }}>
                  CrowdWisdom360 Portfolios enables you to track top influencer portfolio recommendations. You could also add your portfolio securely and take decisions based on influencer-driven Insights. Earn Crypto Rewards for Using the Platform!</p>                 
             
              
              
            </div>
          </div>

          <div className="vCenter">
            <div className="loginContainer">
              <img src={window.constants.asset_path+"/images/logo.png"} alt="homepage Logo" className="img" />

              <Typography className="mb-3 login_text" style={{fontFamily: "'Satoshi', Arial, sans-serif",fontSize:mediaQueryVar==true?"": "14px",}}>
              Sign in using Google to securely access the best influencer crypto portfolios and insights.Terms of Use, Privacy Policy
                <a href="/termofuse">Terms of Use</a>,{" "}
                <a href="/privacyPolicy">Privacy Policy</a>
              </Typography>

              <div className="login_button_div">
                {/* <Button className={styl
                
                es.username}> */}
                <LoadingButton
                  loading={showloginpage}
                  loadingPosition="start"
                  variant="outlined"
                  style={{ width: "100%",fontFamily: "'Satoshi', Arial, sans-serif" }}
                  onClick={handleLoginClick}
                >
                  <img
                    className="googleGLogo51"
                    alt=""
                    src={window.constants.asset_path+"/images/google--g--logo-5-1.svg"}
                  />
                  <span style={{fontFamily: "'Satoshi', Arial, sans-serif"}}>Continue with Gmail</span>
                </LoadingButton>
              </div>

              {/* <div className={styles.formContainer}></div> */}
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default Login;